import { useMutation } from '@apollo/client';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { User } from '../../generated/user_graphql';
import { setUser, userSelector } from '../../store/UserSlice';
import { PlaceLike } from '../../theme-components/Lables';
import { Loader } from '../../theme-components/Loader';
import { useAuth } from '../auth/firebase';
import SignUp from '../auth/SignUp';
import { MUTATION_LIKE_PLACE } from '../gql-user/user';
import UnauthorizedApolloProvider from '../../UnAuthorizedApolloProvider';

export function LikePlace(props: { placeId: string }) {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [likedPlaces, setLikedPlaces] = useState<string[]>([]);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const handleLike = async () => {
    await likePlace();
  };

  const [likePlace, { loading, data }] = useMutation<{ likePlace: User }>(
    MUTATION_LIKE_PLACE,
    {
      variables: {
        place_id: props.placeId,
        is_liking: !likedPlaces.includes(props.placeId),
      },
    },
  );

  const { myUser } = useSelector(userSelector);

  useEffect(() => {
    if (myUser?.likedPlaces) {
      const uniqueLikedPlaces = new Set<string>();
      myUser.likedPlaces.forEach(place => {
        if (place?.placeId) uniqueLikedPlaces.add(place.placeId);
      });
      setLikedPlaces(Array.from(uniqueLikedPlaces));
    }
  }, [myUser]);

  useEffect(() => {
    if (data && data.likePlace) {
      dispatch(setUser(data.likePlace));
    }
  }, [data]);

  return (
    <Box key={props.placeId}>
      <Box
        onClick={event => {
          event.stopPropagation();
          if (isAuthenticated) {
            handleLike();
          } else {
            handleOpenSignUp();
          }
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <PlaceLike liked={likedPlaces.includes(props.placeId)} />
          </>
        )}
      </Box>
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </Box>
  );
}
