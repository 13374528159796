import { useMutation } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { PrimaryButton } from '../../../theme-components/Buttons';
import { FullScreenBusAnimation } from '../../animations/BusAnimation';
import { MUTATION_ADD_PLACE } from '../../gql-user/addPlacesMutation';
import {
  ModalDescription,
  ModalTitle,
  MediumModalPaper,
  CenteredModal,
  ModalPaper,
} from '../../styling/modal';

import { ShareInstructionModal } from './add-step/AddStep';
import { CustomFormControl } from './Styles';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';

export const LinkPlaceToStepModal: React.FC<{
  stepId: string;
  tripType: string;
  handleCloseModal: () => void;
}> = ({ stepId, tripType, handleCloseModal }) => {
  const closeModal = () => {
    gtag('event', 'link-place-to-step-button');
    handleCloseModal();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        <ModalHeader
          title="Link place from Google Maps"
          description={
            <>
              Easily copy <strong>Google Maps</strong> place sharable link and
              paste bellow.
            </>
          }
        />
        <Space size="md" />
        <LinkPlace
          stepId={stepId}
          tripType={tripType}
          handleCloseModal={closeModal}
        />
      </ModalPaper>
    </CenteredModal>
  );
};

const LinkPlace: React.FC<{
  stepId: string;
  tripType: string;
  handleCloseModal: () => void;
}> = ({ stepId, tripType, handleCloseModal }) => {
  const [placeUrl, setPlaceUrl] = useState('');
  const dispatch = useDispatch();
  const [isValidUrl, setIsValidUrl] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  // Open the keyboard automatically
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus(); // Focus the TextField to open the keyboard
    }
  }, []);

  const validateUrl = url => {
    const validUrlPatterns = [
      'https://maps.app.goo.gl/',
      'https://www.google.com/maps',
      'https://www.booking.com',
    ];

    // Check if the entered URL starts with any of the valid patterns
    return validUrlPatterns.some(pattern => url.startsWith(pattern));
  };

  const handleChange = e => {
    const newUrl = e.target.value;
    setPlaceUrl(newUrl);

    // Validate the URL and update the validation state
    setIsValidUrl(validateUrl(newUrl));
  };

  const [linkPlace, { loading }] = useMutation(MUTATION_ADD_PLACE, {
    variables: {
      url: placeUrl,
      step_id: stepId,
      trip_type: tripType,
    },
  });

  return (
    <Stack direction="column" width="100%">
      <CustomFormControl>
        <TextField
          inputRef={textFieldRef}
          label="Google maps place shared URL"
          fullWidth={true}
          variant="outlined"
          onChange={handleChange}
          helperText={
            !isValidUrl &&
            'URL must start with https://maps.app.goo.gl/ or https://www.google.com/maps'
          }
          sx={{ backgroundColor: '#FAFAFA' }}
        />
      </CustomFormControl>
      <Space size="xs" />
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          flexWrap: 'wrap',
          display: 'inline',
        }}
      >
        <Typography
          onClick={handleOpen}
          sx={theme => ({
            fontWeight: 500,
            display: 'inline',
            color: theme.palette.primary.main,
          })}
        >
          Need help?
        </Typography>
        <Typography sx={{ display: 'inline', fontWeight: 400 }}>
          Learn how to add places form Google Maps
        </Typography>
      </Stack>
      <Space size="md" />
      <PrimaryButton
        variant="contained"
        color="primary"
        disabled={placeUrl === '' || loading}
        onClick={async () => {
          gtag('event', 'link-place-to-step');
          try {
            const res = await linkPlace();
            if (
              res &&
              res.data &&
              res.data.createUserPlace &&
              res.data.createUserPlace.itinerary &&
              res.data.createUserPlace.itinerary.days
            ) {
              gtag('event', 'place-linked');
              dispatch(setDays(res.data.createUserPlace.itinerary.days));
              handleCloseModal();
            }
          } catch (e) {
            gtag('event', 'error-link-place');
            dispatch(setErrorTitle('Error linking place'));
            dispatch(setErrorModalOpen(true));
          }
        }}
      >
        Link place
      </PrimaryButton>
      {loading && (
        <FullScreenBusAnimation copy="This may take about 10 seconds. Feel free to keep researching other places, and it will be ready when you return." />
      )}
      <ShareInstructionModal
        open={open}
        handleClose={handleClose}
        showBooking={true}
        showGetYourGuide={false}
      />
      ;
    </Stack>
  );
};
