import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  useLazyQuery,
} from '@apollo/client';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import {
  Box,
  Typography,
  Stack,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addTransport } from '../../store/TransportSlice';
import { LoadingDots } from '../../theme-components/Loader';
import {
  formatSecondsToTime,
  formatSecondsToTimeExtraShort,
  formatSecondsToTimeShort,
} from '../../utils/helpers';
import { QUERY_TRANSPORT_STEP_DETAILS } from '../gql-public/getTransportStepDetailsMutation';
import { unauthorisedClient } from '../../utils/auth';
import { daysSelector } from '../../store/DaySlice';

export const TransportStepDetails = ({
  currentStep,
  nextStep,
  tripType,
  dayId,
  tripOwner,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null); // Make sure ref is typed
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isFetched, setIsFetched] = useState(false); // Track if data has been fetched

  const { days } = useSelector(daysSelector);
  const currentDay = days.find(day => day?.id === dayId);

  const [getTransportStep, { data, loading: loadingTransport, error }] =
    useLazyQuery(QUERY_TRANSPORT_STEP_DETAILS, {
      client: unauthorisedClient,
    });

  // Intersection Observer Effect
  useEffect(() => {
    if (currentStep && nextStep) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            setIsVisible(true); // Set visibility state to true
            observer.unobserve(entry.target); // Stop observing once visible
          }
        },
        {
          root: null, // Viewport is root
          rootMargin: '2000px', // Trigger when 300px below the viewport
          threshold: 0.1, // Trigger when 10% of the element is visible
        },
      );

      if (componentRef.current) {
        observer.observe(componentRef.current);
      } else {
      }

      return () => {
        if (componentRef.current) {
          observer.disconnect();
        }
      };
    }
  }, [currentStep, nextStep, currentDay?.steps]); // Dependencies include currentStep and nextStep

  // API call when component becomes visible
  useEffect(() => {
    if (isVisible && currentStep && nextStep && !isFetched) {
      if (
        currentStep.latitude &&
        currentStep.latitude !== 0 &&
        currentStep.longitude &&
        currentStep.longitude !== 0 &&
        nextStep.latitude &&
        nextStep.latitude !== 0 &&
        nextStep.longitude &&
        nextStep.longitude !== 0
      ) {
        getTransportStep({
          variables: {
            start_step_id: currentStep.id,
            end_step_id: nextStep.id,
          },
        }).then(() => {
          setIsFetched(true); // Mark as fetched after successful API call
        });
      }
    }
  }, [
    isVisible,
    currentStep,
    nextStep,
    isFetched,
    getTransportStep,
    currentDay?.steps,
  ]);

  // Early returns for loading, error, or invalid data
  if (loadingTransport) {
    return (
      <Box
        display="flex"
        alignItems="center"
        mt={1}
        mb={1}
        height={tripOwner === 'user' ? 45 : 35}
        justifyContent="center"
        ref={componentRef}
        key={currentStep + nextStep}
        sx={theme => ({
          width: '100%',
          [theme.breakpoints.down('sm')]: {},
        })}
      >
        <LoadingDots />
      </Box>
    );
  }

  if (!currentStep || !nextStep) {
    return (
      <Box mt={2} ref={componentRef}>
        {/* <Divider
          orientation="vertical"
          sx={theme => ({
            padding: 0,
            // width: '100%',
            borderColor: theme.palette.divider,
            // marginLeft: 2.5,
            height: '45px',
            '& .MuiDivider-wrapper': {
              padding: 0, // Remove padding from the wrapper
              margin: 0, // Adjust margin as needed
            },
            [theme.breakpoints.down('sm')]: {
              // marginLeft: 0,
            },
          })}
        /> */}
      </Box>
    );
  }

  if (tripType && tripType === 'guide') {
    return <Box mt={2} ref={componentRef} />;
  }

  if (
    (error && !loadingTransport) ||
    (data && data.getDirections === null) ||
    !data
  ) {
    return (
      <Box
        alignItems="center"
        ref={componentRef}
        key={currentStep + nextStep}
        sx={theme => ({
          width: '100%',
          [theme.breakpoints.down('sm')]: {},
        })}
      >
        <Divider
          orientation="vertical"
          sx={theme => ({
            padding: 0,
            // '&.MuiDivider-root': {
            //   width: '4px', // Set the thickness of the vertical divider
            //   backgroundColor: 'blue', // Set the color of the divider
            //   margin: '0 auto', // Center the divider
            // },
            // width: '3px',
            // '&.MuiDivider-root': {
            width: '2px', // Set the thickness of the vertical divider
            backgroundColor: '#f1eaff',

            color: '#f1eaff', // Set the color of the divider
            margin: '0 auto', // Center the divider itself
            position: 'relative', // Ensure the children and divider are properly aligned
            // },
            display: 'flex',
            justifyContent: 'center', // Center the content vertically with the divider
            alignItems: 'center',
            height: tripOwner === 'user' ? 55 : 20,
            border: 'none',
            // borderColor: theme.palette.divider,
            // marginLeft: 2.5,
            '& .MuiDivider-wrapper': {
              padding: 0, // Remove padding from the wrapper
              margin: 0, // Adjust margin as needed
            },
            [theme.breakpoints.down('sm')]: {
              // marginLeft: 0,
            },
          })}
        />
      </Box>
    );
  }

  let drivingTime = 0;
  let walkingTime = 0;

  if (data?.getDirections?.[0]?.durationMinutes) {
    if (data.getDirections[0].transportType === 'driving') {
      drivingTime = data.getDirections[0].durationMinutes;
    }
    if (data.getDirections[0].transportType === 'walking') {
      walkingTime = data.getDirections[0].durationMinutes;
    }
  }

  if (data?.getDirections?.[1]?.durationMinutes) {
    if (data.getDirections[1].transportType === 'driving') {
      drivingTime = data.getDirections[1].durationMinutes;
    }
    if (data.getDirections[1].transportType === 'walking') {
      walkingTime = data.getDirections[1].durationMinutes;
    }
  }

  // Handle case where both driving and walking times are 0
  if (drivingTime === 0 && walkingTime === 0) {
    return (
      <Box
        alignItems="center"
        ref={componentRef}
        key={currentStep + nextStep}
        sx={theme => ({
          width: '100%',
          [theme.breakpoints.down('sm')]: {},
        })}
      >
        <Divider
          orientation="vertical"
          sx={theme => ({
            padding: 0,
            width: '2px',
            backgroundColor: '#f1eaff',
            color: '#f1eaff',
            margin: '0 auto',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: tripOwner === 'user' ? 55 : 20,
            border: 'none',
            '& .MuiDivider-wrapper': {
              padding: 0,
              margin: 0,
            },
            [theme.breakpoints.down('sm')]: {},
          })}
        />
      </Box>
    );
  } else {
    const stepId = currentStep.id;
    if (walkingTime > 0 && walkingTime < 1800) {
      const transportTime = walkingTime;
      dispatch(addTransport({ dayId, stepId, transportTime }));
    }
    if (drivingTime > 0 && (walkingTime > 1800 || walkingTime === 0)) {
      const transportTime = drivingTime;
      dispatch(addTransport({ dayId, stepId, transportTime }));
    }
  }

  return (
    <Box
      // display="flex"
      alignItems="center"
      // height="100px"
      ref={componentRef}
      key={currentStep + nextStep}
      sx={theme => ({
        width: '100%',
        // paddingLeft: 2,
        // paddingRight: 2,
        [theme.breakpoints.down('sm')]: {
          // paddingLeft: 3,
          // paddingRight: 0,
        },
      })}
    >
      <Divider
        orientation="vertical"
        sx={theme => ({
          padding: 0,
          // '&.MuiDivider-root': {
          //   width: '4px', // Set the thickness of the vertical divider
          //   backgroundColor: 'blue', // Set the color of the divider
          //   margin: '0 auto', // Center the divider
          // },
          // width: '3px',
          // '&.MuiDivider-root': {
          width: '2px', // Set the thickness of the vertical divider
          backgroundColor: '#f1eaff',

          color: '#f1eaff', // Set the color of the divider
          margin: '0 auto', // Center the divider itself
          position: 'relative', // Ensure the children and divider are properly aligned
          // },
          display: 'flex',
          justifyContent: 'center', // Center the content vertically with the divider
          alignItems: 'center',
          height: '70px',
          border: 'none',
          // borderColor: theme.palette.divider,
          // marginLeft: 2.5,
          '& .MuiDivider-wrapper': {
            padding: 0, // Remove padding from the wrapper
            margin: 0, // Adjust margin as needed
          },
          [theme.breakpoints.down('sm')]: {
            // marginLeft: 0,
          },
        })}
        textAlign="center"
      >
        {!loadingTransport && (walkingTime > 0 || drivingTime > 0) && (
          <Box
            sx={theme => ({
              fontWeight: 500,
              fontSize: 14,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

              color: '#6E7191',
              padding: theme.spacing(0.75, 2),
              backgroundColor: '#faf5ff',
              // backgroundColor: '#e4d9ff ',
              borderRadius: theme.shape.borderRadius,
              border: `2px solid #f1eaff`,
              display: 'inline-block',
            })}
          >
            {walkingTime > 0 && walkingTime < 1800 && (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <DirectionsWalkIcon sx={{ width: 22, height: 22 }} />
                <Typography sx={{ fontWeight: 500 }}>
                  {!isSmallScreen || tripOwner !== 'user'
                    ? formatSecondsToTime(walkingTime)
                    : formatSecondsToTimeExtraShort(walkingTime)}
                </Typography>
              </Stack>
            )}
            {drivingTime > 0 && (walkingTime > 1800 || walkingTime === 0) && (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <DirectionsCarIcon sx={{ width: 22, height: 22 }} />
                <Typography sx={{ fontWeight: 500 }}>
                  {!isSmallScreen || tripOwner !== 'user'
                    ? formatSecondsToTime(drivingTime)
                    : formatSecondsToTimeExtraShort(drivingTime)}
                </Typography>
              </Stack>
            )}
          </Box>
        )}
      </Divider>
    </Box>
  );
};
