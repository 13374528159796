import { Box, Divider } from '@mui/material';
import { ReactNode } from 'react';

import Space from './Spacing';
import TextDescription, { TitleH3 } from './Typography';

interface ModalHeaderProps {
  title?: string;
  description?: string | ReactNode;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, description }) => {
  return (
    <Box
      sx={theme => ({
        width: '80%',
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      })}
    >
      {title && (
        <>
          <TitleH3>{title}</TitleH3>
          <Space size="sm" />
          <Divider sx={{ width: '100%', mx: 'auto' }} />
        </>
      )}
      {description && (
        <>
          <Space size="sm" />
          <TextDescription
            size="md"
            sx={{
              textAlign: 'center',
            }}
          >
            {description}
          </TextDescription>
        </>
      )}
      <Space size="sm" />
    </Box>
  );
};

export default ModalHeader;
