import { Box, Stack } from '@mui/material';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { VerticalFlex } from '../../helpers/flex';

import { Clone, Container, Handle, Item } from './Styles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { PublicPlaceCard } from '../../trip-details/PublicPlaceCard';
import UnauthorizedApolloProvider from '../../../UnAuthorizedApolloProvider';
import { TransportStepDetails } from '../../trip-details/TransportStepDetails';
import { PublicActivityCard } from '../../trip-details/PublicActivityCard';

export function DragabbleDaySteps(props: {
  day;
  collapsedSteps: boolean;
  tripType: string;
}) {
  return (
    <Droppable
      droppableId={'existingStepDayId_' + props.day.id}
      type="dayStep"
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          $isDraggingOver={snapshot.isDraggingOver}
        >
          {props.day.steps &&
            props.day.steps.map((step, index) => (
              <Box key={index}>
                {step && (
                  <Draggable
                    key={step.id.replace('_temp', '')}
                    draggableId={step.id.replace('_temp', '')}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          $isDragging={snapshot.isDragging}
                          style={provided.draggableProps.style}
                        >
                          <Stack
                            display="flex"
                            width="100%"
                            direction="row"
                            alignItems="flex-start"
                          >
                            <Box
                              sx={{ paddingTop: 1.5, width: 25, height: 25 }}
                            >
                              <Handle {...provided.dragHandleProps}>
                                <DragIndicatorIcon
                                  sx={theme => ({
                                    width: 25,
                                    height: 25,
                                  })}
                                />
                              </Handle>
                            </Box>

                            {step.activity ? (
                              <Box
                                sx={{
                                  flexGrow: 1, // Allows the PublicPlaceCard to take up the remaining space
                                  minWidth: 0, // Ensures the content can shrink and not overflow
                                }}
                              >
                                <PublicActivityCard
                                  step={step}
                                  showEdit={false}
                                  collapsedDetails={props.collapsedSteps}
                                  tripType={props.tripType}
                                  tripUser="user"
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  flexGrow: 1, // Allows the PublicPlaceCard to take up the remaining space
                                  minWidth: 0, // Ensures the content can shrink and not overflow
                                }}
                              >
                                <PublicPlaceCard
                                  step={step}
                                  showEdit={false}
                                  collapsedDetails={props.collapsedSteps}
                                  tripType={props.tripType}
                                  tripUser="public"
                                />
                              </Box>
                            )}
                          </Stack>
                        </Item>
                        {snapshot.isDragging && (
                          <>
                            <Clone>
                              <Item>
                                <Stack
                                  direction="row"
                                  alignItems="flex-start"
                                  display="flex"
                                >
                                  <Handle>
                                    <DragIndicatorIcon
                                      sx={theme => ({
                                        width: 25,
                                        height: 25,
                                      })}
                                    />
                                  </Handle>
                                  <Box
                                    sx={{
                                      flexGrow: 1, // Allows the PublicPlaceCard to take up the remaining space
                                      minWidth: 0, // Ensures the content can shrink and not overflow
                                    }}
                                  >
                                    <PublicPlaceCard
                                      step={step}
                                      // tripId={props.tripId}
                                      showEdit={false}
                                      collapsedDetails={props.collapsedSteps}
                                      tripType={props.tripType}
                                      tripUser="public"
                                    />
                                  </Box>
                                </Stack>
                              </Item>
                            </Clone>
                          </>
                        )}
                      </>
                    )}
                  </Draggable>
                )}
                {props.day.steps && props.day.steps[index + 1] && (
                  <TransportStepDetails
                    currentStep={step}
                    nextStep={props.day.steps[index + 1]}
                    tripType={props.tripType}
                    dayId={props.day.id}
                    tripOwner="public"
                  />
                )}
              </Box>
            ))}
        </Container>
      )}
    </Droppable>
  );
}
