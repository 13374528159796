import { gql } from 'graphql-tag';
import { ITINERARY_FIELDS } from './schema';

export const MUTATION_UPDATE_STEP = gql`
  mutation updateUserStep(
    $step_id: String!
    $type: String
    $description: String
    $archived: Boolean
    $latitude: Float
    $longitude: Float
    $locationUrl: String
    $title: String
    $duration: Int
    $url: String
  ) {
    updateUserStep(
      step_id: $step_id
      type: $type
      description: $description
      archived: $archived
      latitude: $latitude
      longitude: $longitude
      locationUrl: $locationUrl
      title: $title
      duration: $duration
      url: $url
    ) {
      ...ItineraryFields
    }
  }
  ${ITINERARY_FIELDS}
`;
