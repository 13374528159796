import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_COPY_ACTIVITY_OR_PLACE_TO_DAY = gql`
  mutation copyActivityOrPlaceToDay(
    $day_id: String!
    $place_id: String
    $activity_id: String
  ) {
    copyActivityOrPlaceToDay(
      day_id: $day_id
      place_id: $place_id
      activity_id: $activity_id
    ) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
