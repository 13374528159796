import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import { Box, Stack, styled } from '@mui/material';

import { Step } from '../../../generated/user_graphql';
import { TitleH4 } from '../../../theme-components/Typography';
import { ActionsStep } from '../edit-trip/ActionsStep';

// export const Item = styled(Box)<{ $isDragging?: boolean }>(
// ({ $isDragging }) => ({
export const Item = styled(Box, {
  shouldForwardProp: prop => prop !== '$isDragging', // Prevent forwarding $isDragging to the DOM
})<{ $isDragging?: boolean }>(({ $isDragging }) => ({
  display: 'flex',
  userSelect: 'none',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  width: '100%',
  borderRadius: '20px',
  // background: '#fafafa',
  // border: $isDragging ? '1px dashed #000' : '1px solid transparent',
}));

// // Item component styled using MUI's Box
// export const Item = styled(Box)(({ theme, isDragging }) => ({
//   display: 'flex',
//   userSelect: 'none',
//   alignItems: 'flex-start',
//   alignContent: 'flex-start',
//   borderRadius: '10px',
//   background: '#fff',
//   border: isDragging ? '1px dashed #000' : '1px solid transparent',
// }));

// Clone component that extends Item
export const Clone = styled(Item)(({ theme }) => ({
  '& ~ div': {
    transform: 'none !important',
  },
}));

export const Handle = styled(props => {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        userSelect: 'none',
        // width: '100%',
        // Use theme in your styling
        // [theme.breakpoints.down('sm')]: {
        //   marginLeft: '-5px',
        // },
      }}
    />
  );
})({});

export const ContainedHandle = styled(props => {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        userSelect: 'none',
      }}
    />
  );
})({});

// Container component styled using MUI's Box
// export const Container = styled(Box)({
//   width: '100%',
// });

export const Container = styled(Box, {
  shouldForwardProp: prop => prop !== '$isDraggingOver', // Prevent forwarding $isDraggingOver to the DOM
})<{ $isDraggingOver?: boolean }>(({ $isDraggingOver }) => ({
  width: '100%',
  // backgroundColor: isDraggingOver ? 'lightblue' : 'white', // Example of using the prop for styling
}));

// ContainerDay component styled using MUI's Box
export const ContainerDay = styled(Box, {
  shouldForwardProp: prop => prop !== '$isDraggingOver', // Prevent forwarding $isDraggingOver to the DOM
})<{ $isDraggingOver?: boolean }>(({ $isDraggingOver }) => ({
  width: '100%',
}));
