import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_ADD_TAG = gql`
  mutation upsertTag(
    $name: String!
    $trip_id: String
    $day_id: String
    $step_id: String
    $place_id: String
  ) {
    upsertTag(
      name: $name
      trip_id: $trip_id
      day_id: $day_id
      step_id: $step_id
      place_id: $place_id
    ) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;

export const MUTATION_DELETE_TAG = gql`
  mutation deleteTag(
    $id: String!
    $trip_id: String
    $day_id: String
    $step_id: String
  ) {
    deleteTag(id: $id, trip_id: $trip_id, day_id: $day_id, step_id: $step_id) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
