import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import { useAuth } from '../components/auth/firebase';
import SignUp from '../components/auth/SignUp';
import { OuterContainer } from '../components/home/HomeHeader';
import LoginIllustration from '../components/illustrations/Login';
import { RoutePage } from '../components/route';
import { UserTrips } from '../components/user-account/UserTrips';
import { LoaderFullScreen } from '../theme-components/Loader';
import { isRunningStandalone } from '../utils/helpers';
import UnauthorizedApolloProvider from '../UnAuthorizedApolloProvider';

export default function MyTripsRoute() {
  gtag('event', 'user-account-page');
  const { isAuthenticated, isLoading } = useAuth();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <RoutePage>
      <OuterContainer>
        {!isAuthenticated ? (
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: '100%',
              justifyContent: 'space-around',
              alignSelf: 'center',
              alignItems: 'center',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
            })}
          >
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                height="100vh"
              >
                <Stack
                  direction="column"
                  height="80vh"
                  display="flex"
                  justifyContent="center"
                  width="100%"
                >
                  <Box
                    sx={{
                      width: 250,
                      height: 200,
                      display: 'flex',
                      paddingRight: '30px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 2,
                      flexDirection: 'row',
                      alignSelf: 'center',
                    }}
                  >
                    <LoginIllustration
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Box>
                  <UnauthorizedApolloProvider>
                    <SignUp showInModal={false} loginDefault={true} />
                  </UnauthorizedApolloProvider>
                </Stack>
              </Box>
            </Box>
          </Box>
        ) : (
          <AuthorizedApolloProvider>
            <UserTrips />
          </AuthorizedApolloProvider>
        )}
        {isStandalone && (
          <Box
            sx={{ paddingBottom: 'calc(80px + env(safe-area-inset-bottom))' }}
          />
        )}
        <UnauthorizedApolloProvider>
          <SignUp
            showInModal={true}
            loginDefault={true}
            open={isSignUpModalOpen}
            onClose={handleCloseSignUp}
          />
        </UnauthorizedApolloProvider>
      </OuterContainer>
    </RoutePage>
  );
}
