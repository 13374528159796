import UnauthorizedApolloProvider from '../../UnAuthorizedApolloProvider';

import { useAuth } from './firebase';
import SignUp from './SignUp';

function LoginButton() {
  const { isAuthenticated } = useAuth();

  return !isAuthenticated ? (
    <UnauthorizedApolloProvider>
      <SignUp showInModal={false} loginDefault={true} />
    </UnauthorizedApolloProvider>
  ) : null;
}

export default LoginButton;
