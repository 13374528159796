import CloseIcon from '@mui/icons-material/Close';
import DirectionsIcon from '@mui/icons-material/Directions';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import PhoneIcon from '@mui/icons-material/Phone';
import StarIcon from '@mui/icons-material/Star';
import {
  CardContent,
  Typography,
  Box,
  Grid,
  Button,
  IconButton,
  Stack,
  Link,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Map, MapRef, Marker, Source } from 'react-map-gl';
import { useSelector } from 'react-redux';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { Activity, Step } from '../../generated/public_graphql';
import {
  Day,
  Itinerary,
  ItineraryCoordinates,
  Maybe,
  Place,
  Trip,
} from '../../generated/user_graphql';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { ResponsiveChip } from '../../theme-components/Buttons';
import { Body1, TitleH4 } from '../../theme-components/Typography';
import {
  formatNumber,
  formatReviewString,
  getMapUrls,
  getSafeAreaInsetTop,
  openLinkInBrowserNewTab,
} from '../../utils/helpers';
import GoogleIcon from '../icons/GoogleIcon';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';

import { AddToTripButton } from './AddToTrip';
import { ImageCard } from './StepImages';
import { useLazyQuery } from '@apollo/client';
import { unauthorisedClient } from '../../utils/auth';
import { QUERY_GET_BOUNDING_BOX_PLACES_ACTIVITIES } from '../gql-public/boundingBoxPlacesAndActivities';
import { PlaceOrActivityMoreInfo } from './PlaceMoreInfoModal';

type TripType = 'public' | 'user' | 'preview';

interface DirectionsResponse {
  routes: {
    geometry: {
      coordinates: [number, number][];
      type: string;
    };
    // ...other properties
  }[];
  // ...other properties
}

interface RouteFeature {
  type: 'Feature';
  properties: Record<string, never>;
  geometry: {
    type: 'LineString';
    coordinates: [number, number][];
  };
}

export type TripMapProps = {
  tripOwner: TripType;
  onMarkerClick?: (stepId: string) => void;
  onCloseClick?: () => void;
  openOnMobile?: boolean;
};

export function TripMap({
  tripOwner,
  onMarkerClick,
  onCloseClick,
  openOnMobile,
}: TripMapProps) {
  const { publicTrip: pt } = useSelector(publicTripsSelector);
  const { trip: ut } = useSelector(tripsSelector);
  const trip = tripOwner === 'user' ? ut : pt;
  const [mapInstance, setMapInstance] = useState(null);
  const [selectedDay, setSelectedDate] = useState('all');

  let userTrip: Trip | null = null;
  if (trip && tripOwner === 'user') {
    userTrip = trip;
  }
  const [
    getBoundingBoxData,
    { data: boundingBoxData, loading: loadingBoundingBoxData },
  ] = useLazyQuery(QUERY_GET_BOUNDING_BOX_PLACES_ACTIVITIES, {
    client: unauthorisedClient,
  });

  const boundingBox = useMemo(() => {
    if (
      trip &&
      trip.itinerary &&
      trip.itinerary.coordinates &&
      trip.itinerary.coordinates.length > 0
    ) {
      if (selectedDay === 'all') {
        return calculateBoundingBox(trip.itinerary.coordinates);
      } else {
        const coordinates = getCoordinatesForDay(
          trip.itinerary.days!,
          trip.itinerary.coordinates,
          selectedDay,
        );
        return calculateBoundingBox(coordinates);
      }
    }
    return [
      [0, 0],
      [0, 0],
    ];
  }, [trip, selectedDay]);

  const [viewport, setViewport] = useState({
    latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
    longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
    zoom: 10,
    width: '100%',
    height: '100%',
  });

  const fitBounds = useCallback(
    map => {
      if (boundingBox) {
        map.fitBounds(boundingBox, {
          padding: { top: 100, bottom: 20, left: 20, right: 20 },
          duration: 2000,
        });
      }
    },
    [boundingBox],
  );

  useEffect(() => {
    if (mapInstance && boundingBox) {
      fitBounds(mapInstance);
    }
  }, [mapInstance, boundingBox, fitBounds]);

  const [selectedPlace, setSelectedPlace] = useState<{ place: Place } | null>(
    null,
  );
  const [selectedActivity, setSelectedActivity] = useState<{
    activity: Activity;
  } | null>(null);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null,
  );
  const [selectedStepId, setSelectedStepId] = useState<string | null>(null);

  const [mapWidth, setMapWidth] = useState(0);
  const [mapPosition, setMapPosition] = useState(0);
  const [mapVisiblePercentage, setMapVisiblePercentage] = useState(0);
  const [distanceFromBottom, setDistanceFromBottom] = useState(0);
  const mapBoxRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef>(null);

  const [routeData, setRouteData] = useState<RouteFeature | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Helper function to fetch route from Directions API
  const fetchRoute = async (coordinates: [number, number][]) => {
    const accessToken = process.env.REACT_APP_MAP_BOX_KEY;
    const profile = 'driving'; // Change as needed (e.g., walking, cycling)
    const waypoints = coordinates.map(coord => coord.join(',')).join(';');
    const steps = false; // Set to true if you want route steps

    const url = `https://api.mapbox.com/directions/v5/mapbox/${profile}/${waypoints}?geometries=geojson&steps=${steps}&access_token=${accessToken}`;

    try {
      const response = await fetch(url);
      const data: DirectionsResponse = await response.json();
      if (data.routes.length > 0) {
        const route = data.routes[0];
        const routeFeature: RouteFeature = {
          type: 'Feature',
          properties: {},
          geometry: {
            ...route.geometry,
            type: 'LineString',
          },
        };
        setRouteData(routeFeature);
        setError(null);
      } else {
        setError('No routes found.');
        setRouteData(null);
      }
    } catch (err) {
      console.error('Error fetching route:', err);
      setError('Failed to fetch route.');
      setRouteData(null);
    }
  };

  // Effect to fetch route when markers change
  useEffect(() => {
    if (
      trip &&
      trip.itinerary &&
      trip.itinerary.coordinates &&
      trip.itinerary.coordinates.length < 2
    ) {
      setRouteData(null);
      return;
    }

    if (
      trip &&
      trip.itinerary &&
      trip.itinerary.coordinates &&
      trip.itinerary.coordinates.length > 1
    ) {
      const coordinates: [number, number][] = trip.itinerary.coordinates
        .filter(
          marker =>
            marker!.longitude !== undefined && marker!.latitude !== undefined,
        )
        .map(marker => [marker!.longitude!, marker!.latitude!]);

      // if (process.env.NODE_ENV !== 'development') {
      console.log('Fetching route...');
      fetchRoute(coordinates);
      // }
    }
    // const coordinates: [number, number][] = trip!
    //   .itinerary!.coordinates!.filter(
    //     marker =>
    //       marker!.longitude !== undefined && marker!.latitude !== undefined,
    //   )
    //   .map(marker => [marker!.longitude!, marker!.latitude!]);

    // if (process.env.NODE_ENV !== 'development') {
    // fetchRoute(coordinates);
    // }

    // Collect coordinates from markers
  }, [trip]);

  const lineData = useMemo(() => {
    if (
      trip &&
      trip.itinerary &&
      trip.itinerary.coordinates &&
      trip.itinerary.coordinates.length < 2
    ) {
      return null;
    }

    // Sort markers if necessary, e.g., by some order
    // const sortedMarkers = [...trip.markers]; // Replace with actual sorting logic if needed

    if (
      trip &&
      trip.itinerary &&
      trip.itinerary.coordinates &&
      trip.itinerary.coordinates.length > 1
    ) {
      const coordinates = trip.itinerary.coordinates.map(marker => [
        marker!.longitude,
        marker!.latitude,
      ]);

      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
      };
    }
    // const coordinates = trip!.itinerary!.coordinates!.map(marker => [
    //   marker!.longitude,
    //   marker!.latitude,
    // ]);

    // return {
    //   type: 'Feature',
    //   properties: {},
    //   geometry: {
    //     type: 'LineString',
    //     coordinates: coordinates,
    //   },
    // };
  }, [trip]);

  const calculateMapPosition = () => {
    if (mapBoxRef.current) {
      const rect = mapBoxRef.current.getBoundingClientRect();

      const leftPosition = rect.left;
      const mapWidth = rect.width;

      setMapPosition(leftPosition);
      setMapWidth(mapWidth);

      const visibleHeight =
        Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
      const visibleHeightClamped = Math.max(0, visibleHeight);
      const visibilityPercentage = (visibleHeightClamped / rect.height) * 100;

      setMapVisiblePercentage(visibilityPercentage);
      const distanceFromBottom = window.innerHeight - rect.bottom;
      setDistanceFromBottom(distanceFromBottom);
    }
  };

  useEffect(() => {
    // Call on mount
    calculateMapPosition();
    // Listen for resize events
    window.addEventListener('resize', calculateMapPosition);
    window.addEventListener('scroll', calculateMapPosition);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', calculateMapPosition);
      window.addEventListener('scroll', calculateMapPosition);
    };
  }, []); // Empty dependency array to run only on mount and when resized

  // Load the map only once on mount
  const handleMapLoad = event => {
    setMapInstance(event.target);
    fitBounds(event.target);
  };

  useEffect(() => {
    // Calculate viewport and bounding box when trip changes
    if (trip && boundingBox) {
      setViewport({
        latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
        longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
        zoom: 10,
        width: '100%',
        height: '100%',
      });
    }
  }, [trip, boundingBox]);

  const handleClose = () => {
    setSelectedPlace(null);
    setSelectedActivity(null);
    setSelectedStepId(null);
    setSelectedMarkerIndex(null);
    if (onCloseClick) {
      onCloseClick(); // Call the parent-provided function if it exists.
    }
  };

  const handleStepMarkerClick = (step, latitude, longitude) => {
    if (step.place) {
      setSelectedPlace({ place: step.place });
      setSelectedStepId(step.id);
    }
    if (step.activity) {
      setSelectedActivity({ activity: step.activity });
      setSelectedStepId(step.id);
    }
    if (onMarkerClick) {
      onMarkerClick(step.id); // Call the parent-provided function if it exists.
    }

    const newViewport = {
      ...viewport,
      latitude,
      longitude,
      zoom: 5,
    };

    // Update the viewport
    setViewport(newViewport);
  };

  const handlePlaceOrActivityMarkerClick = (
    place,
    activity,
    latitude,
    longitude,
  ) => {
    setSelectedStepId(null);
    if (onCloseClick) {
      onCloseClick(); // Call the parent-provided function if it exists.
    }
    if (place) {
      setSelectedActivity(null);
      setSelectedPlace({ place: place });
    }
    if (activity) {
      setSelectedPlace(null);
      setSelectedActivity({ activity: activity });
    }

    const newViewport = {
      ...viewport,
      latitude,
      longitude,
      zoom: 5,
    };

    // Update the viewport
    setViewport(newViewport);
  };

  if (!trip) {
    return <div>Loading map...</div>; // Display a loading message or spinner
  }

  const handleDaySelect = (selectedDayId: string | 'all') => {
    setSelectedDate(selectedDayId);
  };

  // const handleMapMove = async event => {
  //   const { latitude, longitude, zoom, mapWidthPx, mapHeightPx } =
  //     event.viewState;
  //   const newBoundingBox = getBoundingBox(
  //     latitude,
  //     longitude,
  //     zoom,
  //     mapWidthPx,
  //     mapHeightPx,
  //   ); // Calculate new bounding box
  //   await getBoundingBoxData({
  //     variables: {
  //       min_lat: newBoundingBox.minLat,
  //       min_lng: newBoundingBox.minLng,
  //       max_lat: newBoundingBox.maxLat,
  //       max_lng: newBoundingBox.maxLng,
  //     },
  //   });
  // };

  const handleMapMove = () => {
    if (mapRef.current) {
      const map = mapRef.current; // Directly access the map instance
      if (map.getBounds) {
        const bounds = map.getBounds();

        if (bounds) {
          const minLat = bounds.getSouth();
          const minLng = bounds.getWest();
          const maxLat = bounds.getNorth();
          const maxLng = bounds.getEast();
          // Calculate midpoint latitude
          const midLat = (minLat + maxLat) / 2;

          // Convert 10km to degrees
          const deltaLat = 10 / 110.574; // Degrees latitude per kilometer
          const deltaLng = 10 / (111.32 * Math.cos(midLat * (Math.PI / 180))); // Degrees longitude per kilometer

          // Extend the bounds by 10km each way, ensuring they stay within valid ranges
          const extendedMinLat = Math.max(-90, minLat - deltaLat);
          const extendedMaxLat = Math.min(90, maxLat + deltaLat);
          const extendedMinLng = Math.max(-180, minLng - deltaLng);
          const extendedMaxLng = Math.min(180, maxLng + deltaLng);

          getBoundingBoxData({
            variables: {
              min_lat: extendedMinLat,
              min_lng: extendedMinLng,
              max_lat: extendedMaxLat,
              max_lng: extendedMaxLng,
            },
          });
        }
      }
    }
  };

  const handleStepAdded = (stepId: string) => {
    if (onMarkerClick) {
      setTimeout(() => {
        onMarkerClick(stepId); // Executes after 500ms
      }, 500); // Call the parent-provided function if it exists.
    }
  };

  const itineraryDays = getDaySelectorData(trip!.itinerary!);
  const safeArea = getSafeAreaInsetTop();
  return (
    <Box
      key={trip.id}
      id={trip.id}
      // id="map"
      ref={mapBoxRef}
      sx={theme => ({
        flex: '1 0 0',
        position: 'sticky',
        borderRadius: openOnMobile ? 0 : '20px',
        backgroundColor: '#FFFEFD',
        top: '76px',
        height: 'calc(100vh - 92px)',
        marginY: openOnMobile ? 0 : 2,
        marginLeft: openOnMobile ? 0 : 2,
        // Adjusted height calculation
        overflow: 'hidden',
        // width: 'calc(100% - 16px)',
        // width: '100%',

        zIndex: 1000,
        // boxShadow: '4px 0 8px rgba(0, 0, 0, 0.1)', // Added right shadow
        // borderTopLeftRadius: mapVisiblePercentage > 99.9 ? 0 : 10,
        // borderBottomLeftRadius: mapVisiblePercentage > 99.9 ? 0 : 10,

        display: 'block',
        // was 500
        [theme.breakpoints.down('sm')]: {
          height: '100vh',
          // paddingTop: '50px',
          paddingTop: `${safeArea + 50}px`,
          display: openOnMobile ? 'block' : 'none',
        },
        // Padding top because of the sticky nav bar on mobile and pwa
        // [theme.breakpoints.down('sm')]: {},
      })}
    >
      {trip.itinerary &&
        trip.itinerary.days &&
        trip.itinerary.days.length > 1 && (
          <DaySelector days={itineraryDays} onDaySelect={handleDaySelect} />
        )}

      <Map
        id={trip.id}
        ref={mapRef}
        key={trip.id}
        initialViewState={{
          latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
          longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
          zoom: 10,
        }}
        style={{ width: viewport.width, height: viewport.height }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onLoad={handleMapLoad}
        onMoveEnd={handleMapMove}
        minZoom={1}
        mapboxAccessToken={process.env.REACT_APP_MAP_BOX_KEY}
      >
        {routeData && (
          <Source id="route" type="geojson" data={routeData}>
            <Layer
              id="route-layer"
              type="line"
              paint={{
                'line-color': '#F17A29', // Red color
                'line-width': 3, // Thicker line
                'line-opacity': 0.7, // Slight transparency
                'line-dasharray': [1, 1], // Dashed line
              }}
            />
          </Source>
        )}
        {/* {lineData && (
          <Source id="lines" type="geojson" data={lineData}>
            <Layer
              id="route"
              type="line"
              paint={{
                'line-color': '#F17A29', // Red color
                'line-width': 3, // Thicker line
                'line-opacity': 0.7, // Slight transparency
                'line-dasharray': [1, 1], // Dashed line
              }}
            />
          </Source>
        )} */}
        <>
          {boundingBoxData &&
            boundingBoxData.placesAndActivitiesForBoundingBox &&
            boundingBoxData.placesAndActivitiesForBoundingBox.activities &&
            boundingBoxData.placesAndActivitiesForBoundingBox.activities
              .length > 0 &&
            boundingBoxData.placesAndActivitiesForBoundingBox.activities.map(
              (activity, i) => {
                // const step = activity!.step;
                // const dayWithStep = trip.itinerary!.days!.find(day =>
                //   day!.steps!.some(step => step!.id === co!.step!.id),
                // );
                // const onlyOneDay = trip.itinerary!.days!.length === 1;
                // const isSelected = selectedMarkerIndex === i; // Check if this marker is selected

                if (activity && trip.itinerary) {
                  const doesActivityExistInItinerary = (
                    activityId: string,
                    itinerary: Maybe<Itinerary>,
                  ): boolean => {
                    return (
                      itinerary!.days?.some(day =>
                        day!.steps?.some(
                          step => step?.activity?.id === activityId,
                        ),
                      ) ?? false
                    );
                  };
                  if (
                    doesActivityExistInItinerary(activity.id, trip.itinerary)
                  ) {
                    return null;
                  }
                }

                return (
                  activity && (
                    <Marker
                      key={i}
                      longitude={activity.lng!}
                      latitude={activity.lat!}
                      onClick={() => {
                        handlePlaceOrActivityMarkerClick(
                          null,
                          activity,
                          activity.lat,
                          activity.lng,
                        );
                        setSelectedMarkerIndex(i);
                      }}
                      anchor="bottom"
                    >
                      <RelatedActivityPin />
                    </Marker>
                  )
                );
              },
            )}
        </>
        <>
          {boundingBoxData &&
            boundingBoxData.placesAndActivitiesForBoundingBox &&
            boundingBoxData.placesAndActivitiesForBoundingBox.places &&
            boundingBoxData.placesAndActivitiesForBoundingBox.places.length >
              0 &&
            boundingBoxData.placesAndActivitiesForBoundingBox.places.map(
              (place, i) => {
                // const step = place!.step;
                // const dayWithStep = trip.itinerary!.days!.find(day =>
                //   day!.steps!.some(step => step!.id === co!.step!.id),
                // );
                // const onlyOneDay = trip.itinerary!.days!.length === 1;
                // const isSelected = selectedMarkerIndex === i; // Check if this marker is selected

                if (place && trip.itinerary) {
                  const doesPlaceExistInItinerary = (
                    placeId: string,
                    itinerary: Maybe<Itinerary>,
                  ): boolean => {
                    return (
                      itinerary!.days?.some(day =>
                        day!.steps?.some(step => step?.place?.id === placeId),
                      ) ?? false
                    );
                  };

                  if (doesPlaceExistInItinerary(place.id, trip.itinerary)) {
                    return null;
                  }
                }

                return (
                  place && (
                    <Marker
                      key={i}
                      longitude={place.lng!}
                      latitude={place.lat!}
                      onClick={() => {
                        handlePlaceOrActivityMarkerClick(
                          place,
                          null,
                          place.lat,
                          place.lng,
                        );
                        setSelectedMarkerIndex(i);
                      }}
                      anchor="bottom"
                    >
                      <RelatedPlacePin />
                    </Marker>
                  )
                );
              },
            )}
        </>
        {/* )} */}

        {selectedDay === 'all' ? (
          <>
            {trip.itinerary &&
              trip.itinerary.coordinates &&
              trip.itinerary.coordinates.length > 0 &&
              trip.itinerary.coordinates.map((co, i) => {
                const step = co!.step;
                const dayWithStep = trip.itinerary!.days!.find(day =>
                  day!.steps!.some(step => step!.id === co!.step!.id),
                );
                const onlyOneDay = trip.itinerary!.days!.length === 1;
                const isSelected = selectedMarkerIndex === i; // Check if this marker is selected

                return (
                  co && (
                    <Marker
                      key={i}
                      longitude={co.longitude!}
                      latitude={co.latitude!}
                      onClick={() => {
                        handleStepMarkerClick(step, co.latitude, co.longitude);
                        setSelectedMarkerIndex(i);
                      }}
                      anchor="bottom"
                      style={{ zIndex: 10 }}
                    >
                      <PinWithText
                        text={
                          !onlyOneDay && dayWithStep
                            ? `D${dayWithStep.dayNumber}`
                            : ''
                        }
                      />
                    </Marker>
                  )
                );
              })}
          </>
        ) : (
          <>
            {trip.itinerary &&
              trip.itinerary.coordinates &&
              trip.itinerary.coordinates.length > 0 &&
              trip.itinerary.coordinates.map((co, i) => {
                const step = co!.step;
                const dayWithStep = trip.itinerary!.days!.find(day =>
                  day!.steps!.some(step => step!.id === co!.step!.id),
                );
                const onlyOneDay = trip.itinerary!.days!.length === 1;
                const isSelected = selectedMarkerIndex === i; // Check if this marker is selected

                if (dayWithStep?.id === selectedDay) {
                  return (
                    co && (
                      <Marker
                        key={i}
                        longitude={co.longitude!}
                        latitude={co.latitude!}
                        onClick={() => {
                          handleStepMarkerClick(
                            step,
                            co.latitude,
                            co.longitude,
                          );
                          setSelectedMarkerIndex(i);
                        }}
                        style={{ zIndex: 10 }}
                        anchor="bottom"
                      >
                        <PinWithText
                          text={
                            !onlyOneDay && dayWithStep
                              ? `D${dayWithStep.dayNumber}`
                              : ''
                          }
                        />
                      </Marker>
                    )
                  );
                }
              })}
          </>
        )}

        {/* Render Popup when marker is clicked */}
        {(selectedPlace || selectedActivity) && (
          <PlaceCard
            userTrip={userTrip}
            place={selectedPlace?.place ? selectedPlace.place : null}
            activity={
              selectedActivity?.activity ? selectedActivity.activity : null
            }
            mapWidth={mapWidth} // Adjust as needed
            mapPosition={mapPosition} // Adjust as needed
            onClose={handleClose}
            onStepAdded={handleStepAdded}
            distanceFromBottom={distanceFromBottom} // Adjust as needed
            openOnMobile={openOnMobile}
          />
        )}
      </Map>
    </Box>
  );
}

const getCoordinatesForDay = (
  itineraryDays: Maybe<Day>[],
  coordinates: Maybe<ItineraryCoordinates>[],
  dayId: string,
): Maybe<ItineraryCoordinates>[] => {
  // Find the day by ID
  const day = itineraryDays.find(d => d!.id === dayId);

  // If day not found or has no steps, return an empty array
  if (!day || !day.steps || day.steps.length === 0) {
    // empty day
    return coordinates;
  }

  // Extract all step IDs from the day
  const stepIds = day.steps
    .filter(step => step !== null) // Ensure steps are not null
    .map(step => step!.id); // Map to step IDs

  // Filter the ItineraryCoordinates array to include only those with matching step IDs
  const filteredCoordinates = coordinates.filter(
    coord => coord!.step && stepIds.includes(coord!.step.id),
  );

  if (filteredCoordinates.length === 0) {
    // No coordinates in a day return whole trip
    return coordinates;
  }

  return filteredCoordinates;
};

const getDaySelectorData = (itinerary: Itinerary) => {
  return itinerary.days!.map(day => ({
    dayId: day!.id,
    dayNumber: day!.dayNumber,
  }));
};

interface PinWithTextProps {
  color?: string;
  size?: number;
  text?: string;
  textColor?: string;
  textMarginTop?: number;
}

const PinWithText: React.FC<PinWithTextProps> = ({
  color = '#F17A29',
  // color = '#D35C3B',
  size = 40,
  text,
  textColor = 'white',
  textMarginTop = -6,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 90 90"
      role="img"
    >
      <g
        transform="translate(1.4066 1.4066) scale(1,1)"
        style={{
          stroke: 'none',
          strokeWidth: 0,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1,
          cursor: 'pointer',
        }}
      >
        <path
          d="M 45 0 C 25.463 0 9.625 15.838 9.625 35.375 c 0 8.722 3.171 16.693 8.404 22.861 L 45 90 l 26.97 -31.765 c 5.233 -6.167 8.404 -14.139 8.404 -22.861 C 80.375 15.838 64.537 0 45 0 z"
          fill={color}
        />

        {text && (
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="30"
            fontWeight="bold"
            fill="#36454F" // Change this if you need a different text color
            transform={`translate(0, ${textMarginTop})`}
          >
            {text}
          </text>
        )}
      </g>
    </svg>
  );
};

const RelatedPlacePin: React.FC<PinWithTextProps> = ({
  color = '#9E7FEF',
  size = 30,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 90 90"
      role="img"
    >
      <g
        transform="translate(1.4066 1.4066) scale(1,1)"
        style={{
          stroke: 'none',
          strokeWidth: 0,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1,
          cursor: 'pointer',
        }}
      >
        <path
          d="M 45 0 C 25.463 0 9.625 15.838 9.625 35.375 c 0 8.722 3.171 16.693 8.404 22.861 L 45 90 l 26.97 -31.765 c 5.233 -6.167 8.404 -14.139 8.404 -22.861 C 80.375 15.838 64.537 0 45 0 z"
          fill={color}
        />
      </g>
    </svg>
  );
};

const RelatedActivityPin: React.FC<PinWithTextProps> = ({
  color = '#FF5533',
  size = 30,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 90 90"
      role="img"
    >
      <g
        transform="translate(1.4066 1.4066) scale(1,1)"
        style={{
          stroke: 'none',
          strokeWidth: 0,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1,
          cursor: 'pointer',
        }}
      >
        <path
          d="M 45 0 C 25.463 0 9.625 15.838 9.625 35.375 c 0 8.722 3.171 16.693 8.404 22.861 L 45 90 l 26.97 -31.765 c 5.233 -6.167 8.404 -14.139 8.404 -22.861 C 80.375 15.838 64.537 0 45 0 z"
          fill={color}
        />
      </g>
    </svg>
  );
};

interface DaySelectorProps {
  days: { dayId: string; dayNumber: number }[];
  onDaySelect: (selectedDayId: string) => void;
}

const DaySelector: React.FC<DaySelectorProps> = ({ days, onDaySelect }) => {
  const [selectedDayId, setSelectedDayId] = useState('all');

  const handleDayChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    setSelectedDayId(selectedId);
    onDaySelect(selectedId); // Notify parent component of the selection
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius={20}
      bgcolor="#f5f5f5"
      sx={theme => ({
        paddingLeft: 2,
        paddingY: 1,
        position: 'absolute',
        top: 10,
        display: 'inline-flex',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1,
        backgroundColor: 'rgba(240, 240, 240, 0.95)',
        borderRadius: '20px',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.50)',
        [theme.breakpoints.down('sm')]: {
          position: 'fixed',
          top: '55px',
          left: '0px',
          padding: 0.5,
          zIndex: 1,
          justifyContent: 'center',
          transform: 'none',
          display: { xs: 'flex', sm: 'none' }, // Show only on small screens
          backgroundColor: '#f0f4f8', // Use a light color that blends with the app (adjust to your theme)
          color: '#1c3a57', // Match the text color with your theme
          fontWeight: 'bold', // Make the text more prominent
          borderTopRightRadius: '12px', // Larger radius for a smoother corner
          borderBottomRightRadius: '12px', // Same as above
          borderTopLeftRadius: 0, // Larger radius for a smoother corner
          borderBottomLeftRadius: 0,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow to stand out more
          border: '1px solid #dfe3e8', // Optional: subtle border to match theme
          '&:hover': {
            backgroundColor: '#e0ebf5', // Slightly darker on hover
          },
        },
      })}
    >
      <Typography
        sx={theme => ({
          marginRight: 1,
          whiteSpace: 'nowrap',
          color: '#36454F',
          fontWeight: 700,
          [theme.breakpoints.down('sm')]: {
            marginLeft: 1,
            fontWeight: 600,
            color: '#1C3A57',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          },
        })}
      >
        Zoom to:
      </Typography>

      <FormControl fullWidth>
        <Select
          value={selectedDayId}
          onChange={handleDayChange}
          displayEmpty
          variant="outlined"
          sx={theme => ({
            padding: 0,
            margin: 0,
            color: '#36454F',
            fontWeight: 500,
            // minWidth: 120,
            '& .MuiOutlinedInput-root': {
              padding: 0,
            },
            '& .MuiSelect-select': {
              // padding: '4px 8px',
              paddingLeft: 0,
              paddingRight: 0,
              paddingY: 0,
              minHeight: 'auto',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            [theme.breakpoints.down('sm')]: {
              color: '#1C3A57',
              height: '40px',
            },
          })}
        >
          <MenuItem key="all" value="all">
            All Days
          </MenuItem>
          {days.map(day => (
            <MenuItem key={day.dayId} value={day.dayId}>
              Day {day.dayNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

interface PlaceCardProps {
  activity?: Maybe<Activity>;
  place?: Maybe<Place>;
  onClose: () => void;
  mapWidth: number;
  mapPosition: number;
  distanceFromBottom: number;
  openOnMobile: boolean | undefined;
  userTrip?: Trip | null;
  onStepAdded?: (stepId: string) => void;
}

const PlaceCard: React.FC<PlaceCardProps> = ({
  activity,
  place,
  onClose,
  mapWidth,
  mapPosition,
  distanceFromBottom,
  openOnMobile,
  userTrip,
  onStepAdded,
}) => {
  let bottomValue;
  if (openOnMobile) {
    bottomValue = 0;
  } else if (distanceFromBottom > 0) {
    bottomValue = `${distanceFromBottom + 10}px`;
  } else {
    bottomValue = '10px';
  }

  let bottomValueActionButtons;
  if (openOnMobile) {
    bottomValueActionButtons = '400px';
  } else if (distanceFromBottom > 0) {
    bottomValueActionButtons = `${distanceFromBottom + 330}px`;
  } else {
    bottomValueActionButtons = '330px';
  }

  function gtag(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
  }

  const handleStepAdded = (stepId: string) => {
    if (onStepAdded) {
      onStepAdded(stepId);
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: 'fixed',
          bottom: bottomValueActionButtons,
          zIndex: 10,
          left: openOnMobile ? 0 : `${mapPosition + 15}px`,
          width: openOnMobile
            ? '100vw'
            : `${(mapWidth - 30).toFixed(1).toString()}px`,
          backgroundColor: 'rgba(240, 240, 240, 0.9)',
          padding: 1,
          paddingRight: 2,
          paddingLeft: 2,
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px',
          ...(openOnMobile ? { width: '100%' } : {}),
        }}
      >
        <Box mr={1} sx={{}}>
          <AddToTripButton
            onStepAdded={handleStepAdded}
            currentUserTrip={userTrip}
            stepId={undefined}
            dayId={undefined}
            activityId={activity ? activity.id : undefined}
            placeId={place ? place.id : undefined}
            buttonType="insideTitle"
            buttonCopy="Save"
            showDropDownIcon={true}
          />
        </Box>
        <IconButton
          onClick={onClose}
          style={{
            backgroundColor: 'rgba(54, 69, 79, 0.9)',
            borderRadius: '50%',
            color: '#ffffff',
            width: 30,
            height: 30,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box
        style={{
          height: openOnMobile ? '400px' : '320px',
          minHeight: openOnMobile ? '395px' : '315px',
          paddingBottom: '5px',
          position: 'fixed',
          backgroundColor: 'white',
          overflowY: 'auto',
          zIndex: 10,
          left: openOnMobile ? 0 : `${mapPosition + 15}px`,
          width: openOnMobile
            ? '100vw'
            : `${(mapWidth - 30).toFixed(1).toString()}px`,
          bottom: bottomValue,
          borderBottomLeftRadius: openOnMobile ? 0 : '10px',
          borderBottomRightRadius: openOnMobile ? 0 : '10px',
          ...(openOnMobile ? { width: '100%' } : {}),
        }}
      >
        <Box
          style={{
            position: 'relative',
          }}
        >
          {place && <PlaceOrActivityMoreInfo place={place} />}
          {activity && <PlaceOrActivityMoreInfo activity={activity} />}
        </Box>
      </Box>
    </>
    // </Card>
  );
};

function calculateBoundingBox(coordinates: Maybe<ItineraryCoordinates>[]) {
  // Initialize variables with extreme values
  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  // Iterate over all coordinates
  coordinates
    .filter(
      (item): item is ItineraryCoordinates =>
        item !== null && item !== undefined,
    )
    .forEach(({ latitude, longitude }) => {
      if (isValidCoordinates(latitude, longitude)) {
      } else {
      }
      if (
        latitude !== null &&
        latitude !== undefined &&
        longitude !== null &&
        longitude !== undefined
      ) {
        if (latitude < minLat) minLat = latitude;
        if (latitude > maxLat) maxLat = latitude;
        if (longitude < minLng) minLng = longitude;
        if (longitude > maxLng) maxLng = longitude;
      }
    });

  // Return the bounding box
  return adjustBoundingBox(minLng, minLat, maxLng, maxLat);
  // return [
  //   [minLng, minLat],
  //   [maxLng, maxLat],
  // ];
}

function isValidLatitude(lat) {
  return lat >= -90 && lat <= 90;
}

function isValidLongitude(lng) {
  return lng >= -180 && lng <= 180;
}

function isValidCoordinates(lat, lng) {
  return isValidLatitude(lat) && isValidLongitude(lng);
}

const adjustBoundingBox = (
  minLng: number,
  minLat: number,
  maxLng: number,
  maxLat: number,
) => {
  const centerLat = (minLat + maxLat) / 2;
  const centerLng = (minLng + maxLng) / 2;

  const deltaLat = maxLat - minLat;
  const deltaLng = maxLng - minLng;

  const avgLatRad = (centerLat * Math.PI) / 180; // Convert to radians

  // Calculate the current dimensions in km
  const widthInKm = deltaLng * 111.32 * Math.cos(avgLatRad);
  const heightInKm = deltaLat * 110.574;

  const minSizeKm = 10; // Minimum size of 10 km

  let newDeltaLat = deltaLat;
  let newDeltaLng = deltaLng;

  // Adjust height if less than 10 km
  if (heightInKm < minSizeKm) {
    const requiredDeltaLat = minSizeKm / 110.574;
    newDeltaLat = requiredDeltaLat;
  }

  // Adjust width if less than 10 km
  if (widthInKm < minSizeKm) {
    const requiredDeltaLng = minSizeKm / (111.32 * Math.cos(avgLatRad));
    newDeltaLng = requiredDeltaLng;
  }

  // Compute new min and max coordinates
  let adjustedMinLat = centerLat - newDeltaLat / 2;
  let adjustedMaxLat = centerLat + newDeltaLat / 2;
  let adjustedMinLng = centerLng - newDeltaLng / 2;
  let adjustedMaxLng = centerLng + newDeltaLng / 2;

  // Ensure that the adjusted coordinates do not exceed the valid range
  adjustedMinLat = Math.max(-90, Math.min(90, adjustedMinLat));
  adjustedMaxLat = Math.max(-90, Math.min(90, adjustedMaxLat));
  adjustedMinLng = Math.max(-180, Math.min(180, adjustedMinLng));
  adjustedMaxLng = Math.max(-180, Math.min(180, adjustedMaxLng));

  return [
    [adjustedMinLng, adjustedMinLat],
    [adjustedMaxLng, adjustedMaxLat],
  ];
};

const getBoundingBox = (
  centerLat,
  centerLng,
  zoom,
  mapWidthPx,
  mapHeightPx,
) => {
  // Earth's circumference at the equator in meters
  const earthCircumference = 40075016.686;

  // Calculate meters per pixel at the current zoom level
  const metersPerPixel = earthCircumference / Math.pow(2, zoom + 8);

  // Calculate the dimensions of the viewport in meters
  const halfMapWidthMeters = (mapWidthPx / 2) * metersPerPixel;
  const halfMapHeightMeters = (mapHeightPx / 2) * metersPerPixel;

  // Calculate offsets in latitude and longitude
  const latOffset = halfMapHeightMeters / 111320; // Approximate meters per degree latitude
  const lngOffset =
    halfMapWidthMeters / (111320 * Math.cos(degreesToRadians(centerLat)));

  const minLat = centerLat - latOffset;
  const maxLat = centerLat + latOffset;
  const minLng = centerLng - lngOffset;
  const maxLng = centerLng + lngOffset;

  return {
    minLat,
    maxLat,
    minLng,
    maxLng,
  };
};

const degreesToRadians = degrees => {
  return (degrees * Math.PI) / 180;
};
