import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_COPY_TRIP = gql`
  mutation copyTrip($original_trip_id: String!) {
    copyTrip(original_trip_id: $original_trip_id) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
