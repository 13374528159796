// DaysSidebar.tsx

import {
  ExpandLess,
  ExpandMore,
  Menu as MenuIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Collapse,
  IconButton,
  useMediaQuery,
  useTheme,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone';

import { Day, Maybe } from '../../generated/user_graphql';
import { useSelector } from 'react-redux';
import { tripsSelector } from '../../store/TripSlice';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { returnFormattedDate } from '../../utils/helpers';

interface DaysSidebarProps {
  days: Day[] | Maybe<Day>[]; // Array of days passed as a prop
  onDayClick?: (dayId: string) => void;
  onStepClick?: (stepId: string) => void; // Optional function to handle day clicks
  tripType: string;
  tripOwner?: string;
}

const DaysSidebar: React.FC<DaysSidebarProps> = ({
  days,
  onDayClick,
  onStepClick,
  tripType,
  tripOwner = 'public',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md')); // Detect if the screen size is mobile
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isItineraryOpen, setIsItineraryOpen] = useState(true);
  const [isOverviewOpen, setIsOverviewOpen] = useState(true);
  const [isBudgetOpen, setIsBudgetOpen] = useState(true);

  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);
  const trip = tripOwner === 'user' ? userTrip : publicTrip;

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      // Prevent toggle on tab or shift key presses
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsDrawerOpen(open);
    };

  const handleDayClick = (dayId: string) => {
    if (onDayClick) {
      onDayClick(dayId);
    }
    if (isMobile) {
      setIsDrawerOpen(false);
    }
  };

  const handleStepClick = (stepId: string) => {
    if (onStepClick) {
      onStepClick(stepId);
    }
    if (isMobile) {
      setIsDrawerOpen(false);
    }
  };

  const [expandedDay, setExpandedDay] = useState<string | null>(null);

  const handleDayExpand = (dayId: string) => {
    setExpandedDay(prevDayId => (prevDayId === dayId ? null : dayId));
  };

  const renderSidebarContent = () => (
    <Box
      sx={{
        width: isMobile ? 200 : 200,
        paddingX: 1,
        paddingTop: isDrawerOpen ? 8 : 0,
      }}
      // role="presentation"
    >
      {/* Mobile-specific Header */}
      {/* {isMobile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6">Menu</Typography>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      )} */}

      {/* Itinerary Section */}
      <List>
        <ListItem
          onClick={() => setIsItineraryOpen(!isItineraryOpen)}
          sx={{
            paddingLeft: 1,
            borderRadius: 1,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
          role="button"
          tabIndex={0}
        >
          <Typography
            sx={theme => ({
              fontWeight: 700,
              fontSize: 18,
              color: '#001B30',
              whiteSpace: 'nowrap',
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
            })}
          >
            Jump to{' '}
          </Typography>
          {isItineraryOpen ? (
            <ExpandLess sx={{ paddingLeft: 0.5 }} />
          ) : (
            <ExpandMore sx={{ paddingLeft: 0.5 }} />
          )}
        </ListItem>
        <Collapse in={isItineraryOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Box
              sx={{
                borderRadius: 1,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#f6f0ff',
                },
                paddingLeft: 1,
              }}
              role="button"
              tabIndex={0}
            >
              <ListItem
                onClick={() => {
                  window.scrollTo({
                    top: 400,
                    behavior: 'smooth',
                  });
                }}
                sx={{
                  paddingLeft: 0,
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f6f0ff',
                  },
                }}
                role="button"
                tabIndex={0}
              >
                <Typography
                  sx={theme => ({
                    fontWeight: 600,
                    fontSize: 16,
                    color: '#36454F',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: 16,
                    },
                  })}
                >
                  Overview
                </Typography>
              </ListItem>
            </Box>
            {days.map((day, index) => (
              <Box
                key={index}
                sx={{
                  mb: 0.5,
                  // backgroundColor: '#fff',
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f6f0ff',
                  },
                  paddingLeft: 1,
                }}
                role="button"
                tabIndex={0}
              >
                {/* <Typography
                  sx={theme => ({
                    fontWeight: 600,
                    fontSize: 16,
                    color: '#36454F',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: 16,
                    },
                  })}
                >
                  {tripType === 'itinerary' ? `Day ${day.dayNumber}` : `Guide`}
                </Typography> */}

                <ListItem
                  onClick={() => {
                    handleDayClick(day.id);
                    handleDayExpand(day.id);
                  }}
                  sx={{
                    paddingLeft: 0,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f6f0ff',
                    },
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <Typography
                    sx={theme => ({
                      fontWeight: 600,
                      fontSize: 16,
                      color: '#36454F',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: 16,
                      },
                    })}
                  >
                    {tripType === 'itinerary'
                      ? `Day ${day.dayNumber}`
                      : `Guide`}
                  </Typography>
                  {trip && trip.startDate && tripOwner === 'user' && (
                    <Box pl={0.5}>
                      <Typography
                        sx={theme => ({
                          fontWeight: 600,
                          fontSize: 12,
                          // marginTop: theme.spacing(0.5),
                          color: '#6E7191',
                          [theme.breakpoints.down('sm')]: {
                            fontSize: 12,
                          },
                        })}
                      >
                        {returnFormattedDate(trip.startDate, index, false)}
                      </Typography>
                    </Box>
                  )}
                  {expandedDay === day.id ? (
                    <ExpandLess sx={{ paddingLeft: 0.5 }} />
                  ) : (
                    <ExpandMore sx={{ paddingLeft: 0.5 }} />
                  )}
                </ListItem>
                <Collapse
                  in={expandedDay === day.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ pl: 0.75 }}>
                    {day.steps &&
                      day.steps.length > 0 &&
                      day.steps.map((step, idx) => (
                        <Typography
                          onClick={() => {
                            handleStepClick(step.id);
                          }}
                          key={idx}
                          sx={theme => ({
                            fontWeight: 500,
                            fontSize: 14,
                            color: '#6E7191',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            '&:hover': {
                              color: theme.palette.primary.light,
                            },
                          })}
                        >
                          {step?.title}
                        </Typography>
                      ))}
                  </Box>
                </Collapse>

                {/* <Box sx={{ pl: 0.75 }}>
                  {day.steps &&
                    day.steps.length > 0 &&
                    day.steps.map((step, idx) => (
                      <Typography
                        key={idx}
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: '#6E7191',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {step?.title}
                      </Typography>
                    ))}
                </Box> */}
              </Box>
            ))}
          </List>
        </Collapse>
      </List>
      <Divider sx={{ my: 1 }} />
    </Box>
  );

  let fromTop = '0px';
  if (isTablet) {
    fromTop = '85px';
  }
  if (isMobile) {
    if (isDrawerOpen) {
      fromTop = '55px';
    } else {
      fromTop = '110px';
    }
  }

  return (
    <>
      {isTablet ? (
        <>
          <Box
            sx={theme => ({
              position: 'fixed',
              top: fromTop,
              right: '0px', // Touch the right edge
              padding: 0.5, // Larger padding to increase size
              zIndex: 5000,
              // display: { xs: 'flex', sm: 'none' }, // Show only on small screens
              flexDirection: 'column', // Align icons and titles vertically
              backgroundColor: '#f0f4f8', // Use a light color that blends with the app (adjust to your theme)
              // backgroundColor: 'black',
              color: '#1c3a57', // Match the text color with your theme
              fontWeight: 'bold', // Make the text more prominent
              borderTopLeftRadius: '12px', // Larger radius for a smoother corner
              borderBottomLeftRadius: '12px', // Same as above
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow to stand out more
              border: '1px solid #dfe3e8', // Optional: subtle border to match theme
              '&:hover': {
                backgroundColor: '#e0ebf5', // Slightly darker on hover
              },
            })}
          >
            <IconButton
              onClick={toggleDrawer(!isDrawerOpen)}
              sx={theme => ({
                // backgroundColor: theme.palette.background.paper, // Using theme color for background
                // display: { sm: 'flex', md: 'none' }, // Show only on small screens
                backgroundColor: '#f0f4f8',
              })}
            >
              {isDrawerOpen ? (
                <Stack direction="row" spacing={1}>
                  {/* <ListIcon sx={{ color: '#37474F' }} /> */}
                  {/* <Typography
                    sx={theme => ({
                      fontWeight: 600,
                      color: '#1C3A57',
                    })}
                  >
                    Close
                  </Typography> */}
                  <MenuOpenTwoToneIcon sx={{ color: '#37474F' }} />
                </Stack>
              ) : (
                <Stack direction="row" spacing={1}>
                  {/* <MapIcon sx={{ color: '#37474F' }} /> */}
                  {/* <Typography
                    sx={theme => ({
                      fontWeight: 600,
                      color: '#1C3A57',
                    })}
                  >
                    Open
                  </Typography> */}
                  <MenuOpenTwoToneIcon sx={{ color: '#37474F' }} />
                </Stack>
              )}
            </IconButton>
          </Box>
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              zIndex: 4500,
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: 200,
              },
            }}
          >
            {renderSidebarContent()}
          </Drawer>
        </>
      ) : (
        <Box sx={{ paddingX: 2, width: 232 }}>
          <Box
            sx={{
              width: 200,
              // flex: '1 0 0',
              flex: '0 0 auto',
              // marginLeft: 2,
              // marginRight: '-200px',
              position: 'sticky',
              borderRadius: '20px',
              top: '76px',
              height: 'calc(100vh - 92px)',
              marginY: 2,
              // marginLeft: 2,
              backgroundColor: '#FFFEFD',
              // top: '0vh',
              // height: '100vh',
              zIndex: 1000,
              display: 'block',
              // backgroundColor: '#fafafa',
              boxShadow: theme.shadows[2],
              overflowY: 'auto',
              // '&::-webkit-scrollbar': {
              //   width: '8px',
              // },
              // '&::-webkit-scrollbar-thumb': {
              //   backgroundColor: '#ccc',
              //   borderRadius: '4px',
              // },
              // '&::-webkit-scrollbar-track': {
              //   backgroundColor: '#f1f1f1',
              // },
            }}
          >
            {renderSidebarContent()}
          </Box>
        </Box>
      )}
    </>
  );
};

export default DaysSidebar;
