import { gql } from 'graphql-tag';

export const QUERY_TRANSPORT_STEP_DETAILS = gql`
  query getDirections(
    $start_step_id: String!
    $end_step_id: String
    $end_place_id: String
  ) {
    getDirections(
      start_step_id: $start_step_id
      end_step_id: $end_step_id
      end_place_id: $end_place_id
    ) {
      startStepId
      endStepId
      transportType
      durationMinutes
      distanceMetres
    }
  }
`;
