import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  useLazyQuery,
} from '@apollo/client';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { Box, Typography, Stack, Divider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addTransport } from '../../store/TransportSlice';
import { LoadingDots } from '../../theme-components/Loader';
import {
  formatSecondsToTime,
  formatSecondsToTimeShort,
} from '../../utils/helpers';
import { QUERY_TRANSPORT_STEP_DETAILS } from '../gql-public/getTransportStepDetailsMutation';
import { unauthorisedClient } from '../../utils/auth';
import { daysSelector } from '../../store/DaySlice';

export const TransportStepToPlaceDetails = ({
  currentStep,
  endPlace,
  // dayId,
}) => {
  // const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null); // Make sure ref is typed
  const [isFetched, setIsFetched] = useState(false); // Track if data has been fetched

  // const { days } = useSelector(daysSelector);
  // const currentDay = days.find(day => day?.id === dayId);

  const [getTransportStep, { data, loading: loadingTransport, error }] =
    useLazyQuery(QUERY_TRANSPORT_STEP_DETAILS, {
      client: unauthorisedClient,
    });

  // Intersection Observer Effect
  useEffect(() => {
    if (currentStep && endPlace) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            setIsVisible(true); // Set visibility state to true
            observer.unobserve(entry.target); // Stop observing once visible
          }
        },
        {
          root: null, // Viewport is root
          rootMargin: '2000px', // Trigger when 300px below the viewport
          threshold: 0.1, // Trigger when 10% of the element is visible
        },
      );

      if (componentRef.current) {
        observer.observe(componentRef.current);
      } else {
      }

      return () => {
        if (componentRef.current) {
          observer.disconnect();
        }
      };
    }
  }, [currentStep, endPlace]);
  // }, [currentStep, endPlace, currentDay?.steps]); // Dependencies include currentStep and endPlace

  // API call when component becomes visible
  useEffect(() => {
    if (isVisible && currentStep && endPlace && !isFetched) {
      if (
        currentStep.latitude &&
        currentStep.latitude !== 0 &&
        currentStep.longitude &&
        currentStep.longitude !== 0 &&
        endPlace.lat &&
        endPlace.lat !== 0 &&
        endPlace.lng &&
        endPlace.lng !== 0
      ) {
        getTransportStep({
          variables: {
            start_step_id: currentStep.id,
            end_place_id: endPlace.id,
          },
        }).then(() => {
          setIsFetched(true); // Mark as fetched after successful API call
        });
      }
    }
  }, [isVisible, currentStep, endPlace, isFetched, getTransportStep]);
  // }, [
  //   isVisible,
  //   currentStep,
  //   endPlace,
  //   isFetched,
  //   getTransportStep,
  //   currentDay?.steps,
  // ]);

  // Early returns for loading, error, or invalid data
  if (loadingTransport) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        ref={componentRef}
        key={currentStep + endPlace}
      >
        <LoadingDots />
      </Box>
    );
  }

  if (!currentStep || !endPlace) {
    return <Box ref={componentRef} />;
  }

  if ((error && !loadingTransport) || (data && data.getDirections === null)) {
    return <Box ref={componentRef} />;
  }

  let drivingTime = 0;
  let walkingTime = 0;

  if (data?.getDirections?.[0]?.durationMinutes) {
    if (data.getDirections[0].transportType === 'driving') {
      drivingTime = data.getDirections[0].durationMinutes;
    }
    if (data.getDirections[0].transportType === 'walking') {
      walkingTime = data.getDirections[0].durationMinutes;
    }
  }

  if (data?.getDirections?.[1]?.durationMinutes) {
    if (data.getDirections[1].transportType === 'driving') {
      drivingTime = data.getDirections[1].durationMinutes;
    }
    if (data.getDirections[1].transportType === 'walking') {
      walkingTime = data.getDirections[1].durationMinutes;
    }
  }

  // Handle case where both driving and walking times are 0
  if (drivingTime === 0 && walkingTime === 0) {
    return <Box ref={componentRef} />;
  }
  // else {
  //   const stepId = currentStep.id;
  //   if (walkingTime > 0 && walkingTime < 1800) {
  //     const transportTime = walkingTime;
  //     dispatch(addTransport({ dayId, stepId, transportTime }));
  //   }
  //   if (drivingTime > 0 && (walkingTime > 1800 || walkingTime === 0)) {
  //     const transportTime = drivingTime;
  //     dispatch(addTransport({ dayId, stepId, transportTime }));
  //   }
  // }

  return (
    <Stack direction="row" alignItems="center">
      {walkingTime > 0 && walkingTime < 1800 && (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <DirectionsWalkIcon
            sx={theme => ({
              width: 20,
              height: 20,
              marginRight: 1,
              color: theme.palette.icon?.main || '#6E7191',
              [theme.breakpoints.down('sm')]: {
                marginRight: 0.5,
                width: 20,
                height: 20,
              },
            })}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              color: '#6E7191',
              marginRight: 0.5,
            }}
          >
            {formatSecondsToTimeShort(walkingTime)}
          </Typography>
        </Stack>
      )}
      {drivingTime > 0 && (walkingTime > 1800 || walkingTime === 0) && (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <DirectionsCarIcon
            sx={theme => ({
              width: 20,
              height: 20,
              marginRight: 1,
              color: theme.palette.icon?.main || '#6E7191',
              [theme.breakpoints.down('sm')]: {
                marginRight: 0.5,
                width: 20,
                height: 20,
              },
            })}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              color: '#6E7191',
              marginRight: 0.5,
            }}
          >
            {formatSecondsToTimeShort(drivingTime)}
          </Typography>
        </Stack>
      )}
      <Box
        sx={{
          width: 5,
          height: 5,
          backgroundColor: '#6E7191',
          borderRadius: '50%',
          mx: 1,
        }}
      />
    </Stack>
  );
};
