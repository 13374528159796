import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  CardMedia,
  Grid,
  Typography,
  styled,
  Skeleton,
  Stack,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { Trip } from '../../generated/public_graphql';
import { useUserData } from '../../hooks/useUserData';
import {
  ProminentOrangeButton,
  TextButton,
} from '../../theme-components/Buttons';
import { CardLabel } from '../../theme-components/Lables';
import { TitleH2 } from '../../theme-components/Typography';
import { LikeTrip } from '../favourite/likeTrip';
import { QUERY_COUNTRIES_TRIPS } from '../gql-public/countriesTripsQuery';
import { QUERY_TRIPS } from '../gql-public/tripsListQuery';
import { TripCountry, TripLength, TripTitle } from '../user-account/UserTrips';

import { GradientContainer } from './HomeHeader';

interface PropsTripsList {
  readonly trips: Maybe<Trip>[];
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: 5,
  width: '100%',
  flexWrap: 'nowrap', // Prevent wrapping of items
  overflowY: 'auto', // Allows vertical scrolling
  [theme.breakpoints.down('md')]: {
    paddingLeft: 8,
  },
}));

const ScrollButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: -170,
  marginBottom: -80,
  justifyContent: 'space-between',
}));

const ButtonBackground = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  margin: 5,
  backgroundColor: '#FFF3DB',
  borderRadius: 20,
  position: 'relative',
  padding: 8,
}));

const TripImage = styled(CardMedia)(({ theme }) => ({
  borderRadius: '20px',
  height: 270,
  width: 400,
  [theme.breakpoints.down('sm')]: {
    height: 250,
    width: 340,
  },
}));

const MoreInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row', // Already in row, no change needed
  flexWrap: 'wrap', // Keeps wrapping if there are too many items
  // justifyContent: 'center', // Horizontally centers items
  alignItems: 'center', // Vertically centers items
  marginBottom: theme.spacing(1),
  // marginTop: theme.spacing(1),
}));

export function HomeTrips(props: { showExplore?: boolean }) {
  const navigate = useNavigate();
  // const [isMounted, setIsMounted] = useState(true);

  const { loading, error, data } = useQuery(QUERY_TRIPS);
  const { data: dataCountries } = useQuery(QUERY_COUNTRIES_TRIPS);

  // useEffect(() => {
  //   setIsMounted(true);
  //   return () => setIsMounted(false); // Set flag to false on unmount
  // }, []);

  // if (loading) {
  //   return <LoadingCards />;
  // }

  if (error) {
    return (
      <div>
        Couldn't load trips, please contact support at help@rooutie.com or
        refresh
      </div>
    );
  }

  return (
    <GradientContainer gradient="linear-gradient(180deg, #F9E7E1 0%, #FFFFFF 100%)">
      <TitleH2>Destinations</TitleH2>

      <MoreInfoBox>
        {dataCountries &&
          dataCountries.countriesTrips &&
          dataCountries.countriesTrips.map((countryTrip, index) => (
            <React.Fragment key={index}>
              {/* <ButtonBox> */}
              <TextButton
                onClick={() => {
                  navigate('/country/' + countryTrip.country.id);
                }}
              >
                {countryTrip.country.name}
              </TextButton>
              {dataCountries.countriesTrips.length - 1 !== index && (
                <Box
                  sx={{
                    width: 5, // Width of the dot
                    height: 5, // Height of the dot
                    backgroundColor: 'black', // Color of the dot
                    borderRadius: '50%', // Make it circular
                    mx: 1, // Horizontal margin for spacing
                  }}
                />
              )}

              {/* </ButtonBox> */}
            </React.Fragment>
          ))}
      </MoreInfoBox>
      {props.showExplore && (
        <>
          <ProminentOrangeButton
            sx={theme => ({
              marginRight: 1,
              marginBottom: 1,
            })}
            onClick={() => {
              gtag('event', 'explore-all-countries');
              navigate('/explore');
            }}
          >
            Explore all countries
          </ProminentOrangeButton>
        </>
      )}
      {/* <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}> */}
      <Box>
        <TitleH2>Trending trips</TitleH2>
      </Box>
      {loading ? (
        <LoadingCards />
      ) : (
        <>
          {error || !data || !data.tripsList ? (
            <div>
              Couldn't load trips, please contact support at help@rooutie.com or
              refresh
            </div>
          ) : (
            <Box
              sx={theme => ({
                marginRight: -2,
                marginLeft: -2,
              })}
            >
              <Trips trips={data.tripsList} />
            </Box>
          )}
        </>
      )}
    </GradientContainer>
  );
}

function LoadingCards() {
  const loadingCards = [1, 2, 3, 4, 5, 6, 7];

  return (
    <StyledGrid
      container
      key={1}
      sx={{
        paddingBottom: 1,
        width: '100%',
        flexWrap: 'nowrap', // Prevent wrapping of items
        overflowY: 'auto', // Allows vertical scrolling
      }}
    >
      {loadingCards.map(index => (
        <Box
          key={index}
          sx={theme => ({
            borderRadius: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            border: 'none',
            cursor: 'pointer',
            marginRight: 1,
            marginLeft: 1,
            marginBottom: 2,
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          })}
        >
          <Box
            sx={theme => ({
              justifyContent: 'flex-start',
              borderRadius: '20px',
              padding: 0,
              margin: 0,
              // position: 'relative',
              backgroundColor: '#FFFFFF',
              height: 270,
              width: 400,
              [theme.breakpoints.down('sm')]: {
                height: 250,
                width: 340,
              },
            })}
            key={index}
          >
            <Skeleton
              height="60%"
              variant="rectangular"
              sx={{
                // position: 'absolute',
                marginBottom: 1,
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                padding: 0,
                margin: 0,
                display: 'block',
              }}
            />
            <Skeleton
              sx={{
                padding: 0,
                margin: 1,
              }}
            />
            <Skeleton
              sx={{
                padding: 0,
                margin: 1,
              }}
            />
            <Skeleton
              sx={{
                padding: 0,
                margin: 1,
              }}
            />
          </Box>
        </Box>
      ))}
    </StyledGrid>
  );
}

function Trips(props: PropsTripsList) {
  useUserData();
  const navigate = useNavigate();
  const contentWrapper = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <StyledGrid container ref={contentWrapper}>
        {props.trips &&
          props.trips.length > 0 &&
          props.trips.map((trip, index) => (
            <Box sx={{ paddingRight: 2, paddingBottom: 2 }}>
              <Box
                key={index}
                boxShadow={1}
                onClick={() => {
                  navigate('/trip/' + trip!.id);
                }}
                sx={theme => ({
                  borderRadius: '20px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                  border: 'none',
                  cursor: 'pointer',
                  // marginRight: 1,
                  // marginLeft: 1,
                  // marginBottom: 2,
                  // width: 400,
                  backgroundColor: '#FFFFFF',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  [theme.breakpoints.down('sm')]: {
                    // width: 340,
                  },
                })}
              >
                {trip!.images && trip!.images.length > 0 ? (
                  <TripImage
                    image={
                      trip!.images?.find(image => image!.main)?.path ||
                      trip!.images[0]?.path ||
                      ''
                    }
                  />
                ) : (
                  <TripImage image={'/assets/image/placeholder-svg.svg'} />
                )}
                <CardLabel
                  text={
                    trip && trip.tripType && trip.tripType === 'guide'
                      ? 'Guide'
                      : 'Itinerary'
                  }
                />
                <AuthorizedApolloProvider>
                  <LikeTrip tripId={trip!.id} />
                </AuthorizedApolloProvider>
                <Box
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    bottom: 0, // Align the content to the bottom
                    left: 0,
                    right: 0,
                    background: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white background
                    backdropFilter: 'blur(3px)', // Blur effect on the background
                    paddingY: 1,
                    paddingX: 2,
                    paddingBottom: 1.5, // Adjust the padding to fit better
                    borderRadius: '0 0 20px 20px', // Rounded corners at the bottom
                  }}
                >
                  <TripTitle>{trip!.name}</TripTitle>
                  <Stack
                    direction="row"
                    alignItems="center" // Center vertically
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap', // This allows the countries to wrap onto the next line
                    }}
                  >
                    <>
                      {trip && trip.tripType && trip.tripType === 'guide' ? (
                        <>
                          {trip && trip.cities && trip.cities.length > 0 && (
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                            >
                              {trip.cities.map((city, index) => (
                                <React.Fragment key={index}>
                                  <TripCountry>{city?.name}</TripCountry>
                                  {index !== trip.cities!.length - 1 && (
                                    <Box
                                      sx={{
                                        width: 5,
                                        height: 5,
                                        backgroundColor: 'black',
                                        borderRadius: '50%',
                                        mx: 1,
                                      }}
                                    />
                                  )}
                                </React.Fragment>
                              ))}
                            </Stack>
                          )}
                        </>
                      ) : (
                        <>
                          {trip &&
                            trip.itineraryCountries &&
                            trip.itineraryCountries.length > 0 &&
                            trip.itineraryCountries.map((country, index) => (
                              <React.Fragment key={index}>
                                {country && (
                                  <>
                                    {trip.length && index === 0 && (
                                      <TripLength>
                                        {trip.length}
                                        {trip.length === 1 ? ' day' : ' days'}
                                      </TripLength>
                                    )}
                                    <Box
                                      sx={{
                                        width: 5, // Width of the dot
                                        height: 5, // Height of the dot
                                        backgroundColor: 'black', // Color of the dot
                                        borderRadius: '50%', // Make it circular
                                        mx: 1, // Horizontal margin for spacing
                                      }}
                                    />

                                    <TripCountry>
                                      {country.countryName}
                                    </TripCountry>
                                  </>
                                )}
                              </React.Fragment>
                            ))}
                        </>
                      )}
                    </>
                  </Stack>
                  {/* </StyledCardContent>
                   */}
                </Box>
              </Box>
            </Box>
          ))}
      </StyledGrid>
      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
        <ScrollButtons>
          <Box onClick={() => sideScroll(contentWrapper!.current!, 1, 420, -5)}>
            <ButtonBackground>
              <ChevronLeftIcon />
            </ButtonBackground>
          </Box>
          <ButtonBackground
            onClick={() => sideScroll(contentWrapper!.current!, 1, 420, 5)}
          >
            <ChevronRightIcon />
          </ButtonBackground>
        </ScrollButtons>
        <Box height={200} />
      </Box>
    </>
  );
}

const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number,
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);

  // Return a cleanup function
  return () => clearInterval(slideTimer);
};
