import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_ADD_RECOMMENDATION = gql`
  mutation createRecommendation(
    $url: String!
    $day_id: String
    $step_id: String
    $trip_id: String
  ) {
    createRecommendation(
      url: $url
      day_id: $day_id
      step_id: $step_id
      trip_id: $trip_id
    ) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
