import { useLazyQuery } from '@apollo/client';
import { Card, CardMedia, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';

import { Maybe, Image, StepPlaceImage } from '../../generated/public_graphql';
import { PlaceImage } from '../../generated/user_graphql';
import { unauthorisedClient } from '../../utils/auth';
import { FETCH_IMAGE_URL } from '../gql-public/getPlaceImage';

export function StepPlaceImages(props: {
  images: Maybe<StepPlaceImage>[] | Maybe<Image>[] | Maybe<PlaceImage>[];
}) {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null); // Make sure ref is typed

  useEffect(() => {
    if (!props.images || props.images.length === 0) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          setIsVisible(true); // Set visibility state to true
          observer.unobserve(entry.target); // Stop observing once visible
        }
      },
      {
        root: null, // Viewport is root
        rootMargin: '400px', // Trigger when 300px below the viewport
        threshold: 0.1, // Trigger when 10% of the element is visible
      },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    } else {
    }

    return () => {
      if (componentRef.current) {
        observer.disconnect();
      }
    };
  }, [props.images, isVisible]);

  return (
    <Box ref={componentRef}>
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 250,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container spacing={1} direction="row" wrap="nowrap">
              {/* Render only visible images */}
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  {index < 3 && (
                    <Grid
                      item
                      xs={12}
                      sm={props.images.length === 1 ? 12 : 6}
                      md={props.images.length === 3 ? 12 : 6}
                    >
                      {isVisible ? (
                        <ImageCard
                          imageUrl={
                            image && image.imageUrl ? image.imageUrl : ''
                          }
                          path={image && image.path ? image.path : ''}
                          title={image && image.title ? image.title : ''}
                          index={index}
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            height: 150,
                            width: '100%',
                            objectFit: 'cover',
                            [theme.breakpoints.down('sm')]: {
                              height: 130,
                            },
                          })}
                        />
                      )}
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export function PlaceSingleImage(props: {
  images: Maybe<StepPlaceImage>[] | Maybe<Image>[] | Maybe<PlaceImage>[];
}) {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!props.images || props.images.length === 0) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '400px',
        threshold: 0.1,
      },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    } else {
    }

    return () => {
      if (componentRef.current) {
        observer.disconnect();
      }
    };
  }, [props.images, isVisible]);

  return (
    <Box ref={componentRef}>
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 350,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <ImageCard
                      imageUrl={image && image.imageUrl ? image.imageUrl : ''}
                      path={image && image.path ? image.path : ''}
                      title={image && image.title ? image.title : ''}
                      index={index}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export function PlaceImages(props: { images: Maybe<PlaceImage>[] }) {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!props.images || props.images.length === 0) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '400px',
        threshold: 0.1,
      },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    } else {
    }

    return () => {
      if (componentRef.current) {
        observer.disconnect();
      }
    };
  }, [props.images, isVisible]);

  return (
    <Box ref={componentRef}>
      {props.images && props.images.length > 0 && (
        <Box
          sx={theme => ({
            minWidth: 250,
            marginTop: 1,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(1100)]: {
              marginTop: 1,
              marginLeft: 0,
            },
          })}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard
                          imageUrl={
                            image && image.imageUrl ? image.imageUrl : ''
                          }
                          path={image && image.path ? image.path : ''}
                          title={image && image.title ? image.title : ''}
                          index={index}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard
                          imageUrl={
                            image && image.imageUrl ? image.imageUrl : ''
                          }
                          path={image && image.path ? image.path : ''}
                          title={image && image.title ? image.title : ''}
                          index={index}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

interface ImageCardProps {
  imageUrl: string;
  path: string;
  title: string;
  index: number;
}

export const ImageCard: React.FC<ImageCardProps> = React.memo(
  ({ path, title, index, imageUrl }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [height, setHeight] = useState<number | null>(null);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const [imageSrc, setImageSrc] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);

    const [getImage] = useLazyQuery(FETCH_IMAGE_URL, {
      client: unauthorisedClient,
    });

    useEffect(() => {
      let isMounted = true;

      const fetchImage = async () => {
        if (path === null || path === '') {
          try {
            const response = await getImage({
              variables: { image_url: imageUrl },
            });

            if (response.data && response.data.fetchImage.imageData) {
              const base64Data = `data:image/jpeg;base64,${response.data.fetchImage.imageData}`;

              if (isMounted) {
                setImageSrc(base64Data);
                setImageLoaded(true);
                // saveImageToSessionStorage(imageUrl, base64Data);
              }
            }
          } catch (err) {
            console.error('Error fetching image:', err);
          }
        } else {
          if (isMounted && path !== '') {
            setImageLoaded(true);
            setImageSrc(path);
            // saveImageToSessionStorage(path, path);
          }
        }
      };

      fetchImage();

      return () => {
        isMounted = false;
      };
    }, [imageUrl, path]);

    useEffect(() => {
      let timeout: NodeJS.Timeout;
      const lastState = { isIntersecting: false };
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Clear any pending timeout
          if (timeout) {
            clearTimeout(timeout);
          }

          // Only update if state actually changed
          if (lastState.isIntersecting !== entry.isIntersecting) {
            timeout = setTimeout(() => {
              setIsVisible(entry.isIntersecting);
              lastState.isIntersecting = entry.isIntersecting;
            }, 150); // Debounce time
          }
        },
        {
          root: null,
          rootMargin: '400px',
          threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        },
      );

      if (elementRef.current) {
        observer.observe(elementRef.current);
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        if (elementRef.current) {
          observer.unobserve(elementRef.current);
        }
      };
    }, []);

    return (
      <Box
        ref={elementRef}
        sx={theme => ({
          height: 150,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [theme.breakpoints.down('md')]: {
            height: 130,
          },
        })}
      >
        {isVisible && imageLoaded ? (
          <Card
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CardMedia
              component="img"
              src={imageSrc}
              onLoad={() => {
                if (imageRef.current) {
                  setHeight(imageRef.current.offsetHeight);
                }
              }}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Card>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{
              height: height || 150,
              width: '100%',
            }}
          />
        )}
      </Box>
    );
  },
);

function saveImageToSessionStorage(key: string, data: string) {
  try {
    sessionStorage.setItem(key, data);
  } catch (e) {
    if (e.name === 'QuotaExceededError') {
      console.warn('SessionStorage quota exceeded. Clearing old items...');

      removeOldestItemFromSessionStorage();

      try {
        sessionStorage.setItem(key, data);
      } catch (e) {
        console.error('Failed to store data after eviction:', e);
      }
    } else {
      console.error('Error saving data to sessionStorage:', e);
    }
  }
}

function removeOldestItemFromSessionStorage() {
  const keys = Object.keys(sessionStorage);

  if (keys.length > 0) {
    let oldestKey = keys[0];
    sessionStorage.removeItem(oldestKey);
    oldestKey = keys[1];
    sessionStorage.removeItem(oldestKey);
  }
}
