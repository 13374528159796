import { gql } from 'graphql-tag';
import { ITINERARY_FIELDS } from './schema';

export const MUTATION_DELETE_STEP = gql`
  mutation deleteUserStep($step_id: String!) {
    deleteUserStep(step_id: $step_id) {
      ...ItineraryFields
    }
  }
  ${ITINERARY_FIELDS}
`;
