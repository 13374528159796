import { useMutation } from '@apollo/client';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import {
  daysSelector,
  setDayLoadingIndex,
  setDays,
  setStepLoadingIndex,
} from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector } from '../../../store/TripSlice';
import { MUTATION_MOVE_DAY } from '../../gql-user/moveDayMutation';
import { MUTATION_MOVE_STEP } from '../../gql-user/moveStepMutation';
import { RouteContent, RoutePage, RouteTitle } from '../../route';
import { MapDrawer } from '../../trip-details/MapDrawer';
import { TripDetails } from '../../trip-details/TripDetails';
import { TripHeaderImages } from '../../trip-details/TripHeader';
import { TripMap } from '../../trip-details/TripMap';
import DaysSidebar from '../../trip-details/TripSideNavigation';

import {
  moveStepBetweenDays,
  reorderDays,
  reorderSteps,
} from './dragAndDropFunctions';
import { DraggableDroppableDays } from './DraggableDroppableDays';
import { getSafeAreaInsetTop } from '../../../utils/helpers';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    $isDraggingOver?: boolean;
    $isDragging?: boolean;
  }
}

export function DragAndDropUserItinerarySingleTrip() {
  const dispatch = useDispatch();
  const [moveDayInItinerary] = useMutation(MUTATION_MOVE_DAY);
  const [moveStep] = useMutation(MUTATION_MOVE_STEP);
  gtag('event', 'create-user-trip-single-page');
  const stepRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const dayRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const scrollPositionRef = useRef<number>(0);
  const [selectedStepId, setSelectedStepId] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { trip } = useSelector(tripsSelector);
  const { days } = useSelector(daysSelector);

  const scrollToStep = (stepId: string) => {
    if (stepId) {
      const stepRef = stepRefs.current[stepId];
      if (stepRef) {
        stepRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setTimeout(() => {
          stepRef.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 1000);
      }
    }
  };

  const scrollToDay = (dayId: string) => {
    if (dayId) {
      const dayRef = dayRefs.current[dayId];
      const safeAreaTop = getSafeAreaInsetTop();
      if (dayRef) {
        if (isSmallScreen) {
          dayRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setTimeout(() => {
            dayRef.scrollIntoView({
              behavior: 'auto',
              block: 'start',
            });
          }, 1000);
          setTimeout(() => {
            window.scrollBy({
              top: -(70 + safeAreaTop),
              left: 0,
              behavior: 'auto',
            });
          }, 1100);
        } else {
          dayRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setTimeout(() => {
            dayRef.scrollIntoView({
              behavior: 'auto',
              block: 'start',
            });
          }, 1000);
          setTimeout(() => {
            window.scrollBy({
              top: -85,
              left: 0,
              behavior: 'auto',
            });
          }, 1100);
        }
      }
    }
  };

  useEffect(() => {
    // Restore the scroll position if it's stored
    if (scrollPositionRef.current && selectedStepId === null) {
      window.scrollTo(0, scrollPositionRef.current);
    }
  }, [selectedStepId]);

  const handleMarkerClick = stepId => {
    setSelectedStepId(stepId);
    scrollToStep(stepId);
  };

  const handleCloseClick = () => {
    scrollPositionRef.current = window.scrollY;
    setSelectedStepId(null);
  };

  const handleMenuDayClick = dayId => {
    scrollToDay(dayId);
  };
  const handleMenuStepClick = stepId => {
    scrollToStep(stepId);
  };

  if (!trip || !days) {
    return (
      <RoutePage>
        <RouteTitle>Something went wrong</RouteTitle>
        <RouteContent>Please reload the page and try again.</RouteContent>
      </RoutePage>
    );
  }

  async function onDragEnd(result) {
    const { source, destination, draggableId } = result;

    if (!destination || !days) {
      return;
    }

    if (
      source.droppableId === 'itinerary' &&
      destination.droppableId === 'itinerary'
    ) {
      // ------------------------
      // Move day
      // ------------------------
      gtag('event', 'move-day-in-trip');

      const existingDays = reorderDays(days, source.index, destination.index);

      dispatch(setDayLoadingIndex(destination.index));
      dispatch(setDays(existingDays));

      try {
        const { data } = await moveDayInItinerary({
          variables: {
            day_id: draggableId.replace('_temp', ''),
            new_day_number: destination.index + 1,
          },
        });
        dispatch(setDays(data.moveDay.days));
        dispatch(setDayLoadingIndex(-1));
      } catch (e) {
        dispatch(setDayLoadingIndex(-1));
        dispatch(setErrorTitle('Error moving day'));
        dispatch(setErrorModalOpen(true));
      }
    } else if (source.droppableId === destination.droppableId) {
      // ------------------------
      // Move step in day
      // ------------------------
      gtag('event', 'move-step-in-day');

      const { newDays, dayIndex } = reorderSteps(
        days,
        source.droppableId,
        source.index,
        destination.index,
      );

      dispatch(setDays(newDays));
      dispatch(setDayLoadingIndex(dayIndex));
      dispatch(setStepLoadingIndex(destination.index));

      try {
        const response = await moveStep({
          variables: {
            step_id: draggableId.replace('_temp', ''),
            new_day_id: destination.droppableId.replace('_temp', ''),
            original_day_id: source.droppableId.replace('_temp', ''),
            new_step_number: destination.index + 1,
          },
        });
        dispatch(setDays(response.data.moveStep.days));
        dispatch(setDayLoadingIndex(-1));
        dispatch(setStepLoadingIndex(-1));
      } catch (e) {
        dispatch(setDayLoadingIndex(-1));
        dispatch(setErrorTitle('Error moving activity'));
        dispatch(setErrorModalOpen(true));
      }
    } else if (
      destination.droppableId.replace('_temp', '') !==
      source.droppableId.replace('_temp', '')
    ) {
      // ------------------------
      // Move step between days
      // ------------------------
      gtag('event', 'move-step-between-days');

      const { newDays, destinationDayIndex } = moveStepBetweenDays(
        days,
        source,
        destination,
      );

      dispatch(setDays(newDays));
      dispatch(setDayLoadingIndex(destinationDayIndex));
      dispatch(setStepLoadingIndex(destination.index));

      try {
        const response = await moveStep({
          variables: {
            step_id: draggableId.replace('_temp', ''),
            new_day_id: destination.droppableId.replace('_temp', ''),
            original_day_id: source.droppableId.replace('_temp', ''),
            new_step_number: destination.index + 1,
          },
        });
        dispatch(setDays(response.data.moveStep.days));

        dispatch(setDayLoadingIndex(-1));
        dispatch(setStepLoadingIndex(-1));
      } catch (e) {
        dispatch(setDayLoadingIndex(-1));
        dispatch(setErrorTitle('Error moving activity'));
        dispatch(setErrorModalOpen(true));
      }
    } else {
      gtag('event', 'invalid-move-operation');
    }
  }

  return (
    <>
      <MapDrawer
        tripOwner="user"
        onMarkerClick={handleMarkerClick}
        onCloseClick={handleCloseClick}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Stack
          direction="row"
          alignItems="left"
          sx={{
            backgroundColor: '#fffaf5',
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            alignItems="left"
            sx={{
              backgroundColor: '#fffaf5',
              width: 'calc(100vw - 232px)',
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            }}
          >
            {!isSmallScreen && (
              <Box
                sx={theme => ({
                  display: 'block',
                  width: '50%',
                  [theme.breakpoints.down(1040)]: {
                    width: '44%',
                  },
                  [theme.breakpoints.down('md')]: {
                    width: '47%',
                  },
                  [theme.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                })}
              >
                <TripMap
                  tripOwner="user"
                  onMarkerClick={handleMarkerClick}
                  onCloseClick={handleCloseClick}
                />
              </Box>
            )}
            <Box
              sx={theme => ({
                marginTop: 2,
                display: 'block',
                width: '50%',
                [theme.breakpoints.down(1040)]: {
                  width: '56%',
                },
                [theme.breakpoints.down('md')]: {
                  width: '53%',
                  paddingRight: 1.5,
                },
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  marginTop: 0,
                  paddingRight: 0,
                },
              })}
            >
              <TripHeaderImages tripType="user" />

              <Stack
                direction="row"
                // direction={isSmallScreen ? 'column' : 'row'}
                alignItems="left"
                sx={{
                  width: '100%',
                  paddingLeft: 1.5,
                  backgroundColor: '#fffaf5',
                  [theme.breakpoints.down('sm')]: {
                    paddingRight: 1.5,
                  },
                }}
              >
                <Stack
                  direction="column"
                  alignItems="top"
                  sx={theme => ({
                    width: '100%',
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                    },
                  })}
                >
                  <TripDetails tripUser={'user'} />
                  <DraggableDroppableDays
                    showEmptyDay={false}
                    selectedStepId={selectedStepId}
                    stepRefs={stepRefs}
                    dayRefs={dayRefs}
                  />
                </Stack>
              </Stack>
            </Box>
          </Stack>

          {trip?.itinerary?.days && trip?.itinerary?.days.length > 0 && (
            <DaysSidebar
              days={trip.itinerary.days!}
              onDayClick={handleMenuDayClick}
              onStepClick={handleMenuStepClick}
              tripType={trip?.tripType ? trip.tripType : 'itinerary'}
              tripOwner="user"
            />
          )}
        </Stack>
      </DragDropContext>
    </>
  );
}
