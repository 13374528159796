import { useMutation } from '@apollo/client';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Trip } from '../../generated/user_graphql';
import { PrimaryButton, SecondaryButton } from '../../theme-components/Buttons';
import { Loader } from '../../theme-components/Loader';
import { TitleH3 } from '../../theme-components/Typography';
import UnauthorizedApolloProvider from '../../UnAuthorizedApolloProvider';
import { useAuth } from '../auth/firebase';
import SignUp from '../auth/SignUp';
import { MUTATION_COPY_TRIP } from '../gql-user/copyPublicTripMutation';
import SuccessIllustration from '../illustrations/Success';
import { RealisticConfetti } from '../styling/confetti';
import { CenteredModal, ModalPaper } from '../styling/modal';

export default function CopyTrip(props: { tripId: string }) {
  const { isAuthenticated } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [copyTrip, { data, loading }] = useMutation(MUTATION_COPY_TRIP, {
    variables: { original_trip_id: props.tripId },
  });

  return (
    <>
      <Box
        sx={theme => ({
          flexShrink: 0, // Prevent the button from shrinking
        })}
      >
        <Button
          variant="contained"
          startIcon={!loading && <ContentCopyTwoToneIcon />}
          onClick={async () => {
            if (isAuthenticated) {
              gtag('event', 'trip-page-copy-trip');

              try {
                const res = await copyTrip();
                if (
                  res &&
                  res.data &&
                  res.data.copyTrip &&
                  res.data.copyTrip.id
                ) {
                  setModalOpen(true);
                }
              } catch (e) {}
            } else {
              gtag('event', 'trip-page-copy-trip-login');
              // loginWithRedirect({
              //   appState: { targetUrl: window.location.pathname },
              // });
              handleOpenSignUp();
            }
          }}
          sx={theme => ({
            backgroundColor: '#36454F',
            borderRadius: 20,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            boxShadow: 'none',
            fontSize: 13,
            fontWeight: 700,
            paddingY: 0.65,
            paddingX: 1.75,
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: '#4A5D6F',
            },
            '& .MuiButton-startIcon': {
              marginRight: '4px', // Decrease this value as needed
            },
          })}
        >
          {loading ? <Loader /> : 'Copy trip'}
        </Button>
      </Box>
      {data && data.copyTrip && (
        <CenteredModal
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          open={isModalOpen}
          onClose={handleCloseModal}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
              backdropFilter: 'blur(10px)', // Apply the blur effect
            },
          }}
        >
          <ModalPaper>
            <SuccessModalContent
              trip={data.copyTrip}
              handleCloseModal={handleCloseModal}
              navigate={navigate}
            />
          </ModalPaper>
        </CenteredModal>
      )}
      {isModalOpen && <RealisticConfetti />}
      <UnauthorizedApolloProvider>
        <SignUp
          showInModal={true}
          loginDefault={true}
          open={isSignUpModalOpen}
          onClose={handleCloseSignUp}
        />
      </UnauthorizedApolloProvider>
    </>
  );
}

const IllustrationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

const SuccessModalContent: React.FC<{
  trip: Trip;
  handleCloseModal: () => void;
  navigate: (path: string) => void;
}> = ({ trip, handleCloseModal, navigate }) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <IllustrationContainer>
        <SuccessIllustration style={{ maxWidth: '200px', height: 'auto' }} />
      </IllustrationContainer>
      <Stack spacing={3} alignItems="center" sx={{ p: 3, textAlign: 'center' }}>
        <TitleH3>You are going places!</TitleH3>
        <Typography variant="h6" color="textSecondary">
          {'You have successfully created a new trip to: '}
          {trip &&
            trip.itineraryCountries &&
            trip.itineraryCountries.length > 0 &&
            trip.itineraryCountries.map((country, index) => (
              <React.Fragment key={index}>
                {country && (
                  <>
                    {country.countryName}
                    {trip.itineraryCountries &&
                      index !== trip.itineraryCountries.length - 1 && (
                        <>{', '}</>
                      )}
                  </>
                )}
              </React.Fragment>
            ))}
        </Typography>

        {/* Success Message and Buttons */}
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          width="100%"
        >
          <SecondaryButton
            onClick={handleCloseModal}
            color="secondary"
            variant="contained"
            sx={{
              fontSize: 16,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            Continue exploring
          </SecondaryButton>
          <PrimaryButton
            onClick={() => navigate('/create-route/' + trip.id)}
            color="primary"
            variant="contained"
            sx={{
              fontSize: 16,
              boxShadow: 1,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            Open my trip
          </PrimaryButton>
        </Stack>
      </Stack>
    </>
  );
};
