import { gql } from 'graphql-tag';
import { ITINERARY_FIELDS } from './schema';

export const MUTATION_MOVE_DAY = gql`
  mutation moveDay($day_id: String!, $new_day_number: Int!) {
    moveDay(day_id: $day_id, new_day_number: $new_day_number) {
      ...ItineraryFields
    }
  }
  ${ITINERARY_FIELDS}
`;
