import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_ADD_PLACE = gql`
  mutation createUserPlace(
    $url: String!
    $day_id: String
    $step_id: String
    $trip_type: String
    $step_type: String
    $as_step_number: Int
  ) {
    createUserPlace(
      url: $url
      day_id: $day_id
      step_id: $step_id
      trip_type: $trip_type
      step_type: $step_type
      as_step_number: $as_step_number
    ) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
