import { useMutation } from '@apollo/client';
import { Box, InputLabel, Select, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Step } from '../../../generated/user_graphql';
import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector } from '../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
// import { MUTATION_UPDATE_ACCOMMODATION } from '../../gql-user/updateAccommodation';
import { MUTATION_UPDATE_STEP } from '../../gql-user/updateStep';
import { VerticalFlex } from '../../helpers/flex';
import MyEditor from '../../MyEditor';
import {
  CenteredModal,
  ModalPaper,
  ModalDescription,
  ModalTitle,
} from '../../styling/modal';

import { categories } from './add-step/helpers';
import { StyledFormControl } from './Styles';
import Space from '../../../theme-components/Spacing';
import ModalHeader from '../../../theme-components/ModalComponents';
import TextDescription from '../../../theme-components/Typography';
import { textAlign } from '@mui/system';

export const EditStepModal: React.FC<{
  step: Step;
  handleCloseModal: () => void;
}> = ({ step, handleCloseModal }) => {
  const dispatch = useDispatch();
  // const [open, setOpen] = useState(true);
  const [stepType, setStepType] = useState(step.type);
  const [stepTitle, setStepTitle] = useState(step.title);
  const [stepDescription, setStepDescription] = useState(
    step.description ? step.description : undefined,
  );
  // const [stepDuration, setStepDuration] = useState(step.duration);
  const [stepMoreInfoUrl, setStepMoreInfoUrl] = useState(step.url);
  const [category, setCategory] = useState('');
  const [stepLatitude, setStepLatitude] = useState(step.latitude);
  const [stepLongitude, setStepLongitude] = useState(step.longitude);
  const [stepLocationUrl, setStepLocationUrl] = useState(step.locationUrl);

  const [accommodationName, setAccommodationName] = useState(
    step.accommodations && step.accommodations[0]?.name
      ? step.accommodations[0].name
      : '',
  );
  const [accommodationDescription, setAccommodationDescription] = useState(
    step.accommodations && step.accommodations[0]?.description
      ? step.accommodations[0].description
      : '',
  );
  const [accommodationUrl, setAccommodationUrl] = useState(
    step.accommodations && step.accommodations[0]?.url
      ? step.accommodations[0].url
      : '',
  );
  const [accommodationLatitude, setAccommodationLatitude] = useState(
    step.accommodations && step.accommodations[0]?.latitude
      ? step.accommodations[0].latitude
      : null,
  );
  const [accommodationLongitude, setAccommodationLongitude] = useState(
    step.accommodations && step.accommodations[0]?.longitude
      ? step.accommodations[0].longitude
      : null,
  );
  const [accommodationLocationUrl, setAccommodationLocationUrl] = useState(
    step.accommodations && step.accommodations[0]?.locationUrl
      ? step.accommodations[0]?.locationUrl
      : '',
  );

  const [updateStep, { loading }] = useMutation(MUTATION_UPDATE_STEP, {
    variables: {
      step_id: step.id,
      type: stepType,
      description: stepDescription,
      // duration: stepDuration,
      title: stepTitle,
      url: stepMoreInfoUrl,
      latitude: stepLatitude,
      longitude: stepLongitude,
      locationUrl: stepLocationUrl,
    },
  });

  const [updateTransportStep, { loading: loadingTransport }] = useMutation(
    MUTATION_UPDATE_STEP,
    {
      variables: {
        step_id: step.id,
        type: stepType,
        description: stepDescription,
        // duration: stepDuration,
        title: stepTitle,
      },
    },
  );

  const [updateAccommodationStep, { loading: accommodationStepLoading }] =
    useMutation(MUTATION_UPDATE_STEP, {
      variables: {
        step_id: step.id,
        title: accommodationName,
      },
    });

  let stepMainImageUrl = '';
  let stepSecondaryImageUrl = '';
  let accommodationId = '';

  if (step && step.accommodations && step.accommodations[0]) {
    accommodationId = step.accommodations[0].id!;
  }

  step &&
    step.images &&
    step.images.forEach(image => {
      if (image?.main) {
        stepMainImageUrl = image.path!;
      }
      if (image?.secondary) {
        stepSecondaryImageUrl = image.path!;
      }
    });

  let accommodationMainImageUrl = '';
  let accommodationSecondaryImageUrl = '';

  step &&
    step.accommodations &&
    step.accommodations[0] &&
    step.accommodations[0].images &&
    step.accommodations[0].images.forEach(image => {
      if (image?.main) {
        accommodationMainImageUrl = image.path!;
      }
      if (image?.secondary) {
        accommodationSecondaryImageUrl = image.path!;
      }
    });

  // const [updateAccommodation, { loading: loadingAccommodation }] = useMutation(
  //   MUTATION_UPDATE_ACCOMMODATION,
  //   {
  //     variables: {
  //       accommodation_id: accommodationId,
  //       description: accommodationDescription,
  //       name: accommodationName,
  //       url: accommodationUrl,
  //       latitude: accommodationLatitude,
  //       longitude: accommodationLongitude,
  //       locationUrl: accommodationLocationUrl,
  //     },
  //   },
  // );

  const handleEditorChangeStep = (content: string) => {
    setStepDescription(content);
  };

  const handleEditorChangeAccommodation = (content: string) => {
    setAccommodationDescription(content);
  };

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        {category !== 'CATEGORY_ACCOMMODATION' &&
          category !== 'CATEGORY_TRANSPORT' && (
            <>
              <ModalHeader
                title="Edit place/activity"
                description={<>Change any details or description you need</>}
              />
              <StyledFormControl>
                <MyEditor
                  value={stepDescription}
                  onChange={handleEditorChangeStep}
                />
              </StyledFormControl>
              <StyledFormControl>
                <TextField
                  label="Place or activity name"
                  name="title"
                  fullWidth={true}
                  value={stepTitle}
                  variant="outlined"
                  onChange={e => {
                    setStepTitle(e.target.value);
                  }}
                  sx={{ backgroundColor: '#fafafa' }}
                />
              </StyledFormControl>

              {step && (!step.place || !step.place.lat || !step.place.lng) && (
                <StyledFormControl>
                  <TextDescription size="md" sx={{ textAlign: 'left' }}>
                    Right click on <strong>Google Maps</strong> place and{' '}
                    <strong>copy</strong> coordinates
                  </TextDescription>
                  <Space size="xs" />
                  <TextField
                    id="standard-basic"
                    label="Location (paste coordinates)"
                    name="step-location"
                    multiline={true}
                    fullWidth={true}
                    variant="outlined"
                    value={
                      stepLatitude && stepLongitude
                        ? stepLatitude + ', ' + stepLongitude
                        : ''
                    }
                    onChange={e => {
                      const res = e.target.value.split(',', 2);
                      const latitude = parseFloat(res[0]);
                      const longitude = parseFloat(res[1]);
                      if (latitude <= 90 && latitude >= -90) {
                        setStepLatitude(latitude);
                      } else {
                        setStepLatitude(0);
                      }
                      if (longitude <= 180 && latitude >= -180) {
                        setStepLongitude(longitude);
                      } else {
                        setStepLongitude(0);
                      }
                    }}
                    sx={{ backgroundColor: '#fafafa' }}
                  />
                </StyledFormControl>
              )}

              <Space size="md" />
              <PrimaryButton
                disabled={loading}
                onClick={async () => {
                  gtag('event', 'edit-step-save');
                  try {
                    const res = await updateStep();
                    if (
                      res &&
                      res.data &&
                      res.data.updateUserStep &&
                      res.data.updateUserStep.days
                    ) {
                      dispatch(setDays(res.data.updateUserStep.days));
                      closeModal();
                    }
                  } catch (e) {
                    gtag('event', 'error-edit-step');
                    dispatch(setErrorTitle('Error updating location'));
                    dispatch(setErrorModalOpen(true));
                  }
                }}
              >
                {loading ? <Loader /> : 'Save'}
              </PrimaryButton>
              <Space size="md" />
              <SecondaryButton
                onClick={async () => {
                  gtag('event', 'edit-images-close');
                  // setOpen(false);
                  closeModal();
                }}
              >
                Close
              </SecondaryButton>
            </>
          )}
        {category === 'CATEGORY_ACCOMMODATION' && accommodationId && (
          <>
            <ModalHeader
              title="Edit accommodation"
              description={<>Change any details or description you need</>}
            />
            <StyledFormControl>
              <MyEditor
                value={accommodationDescription}
                onChange={handleEditorChangeAccommodation}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                label="Accommodation name"
                name="name"
                fullWidth={true}
                value={accommodationName}
                variant="outlined"
                onChange={e => {
                  setAccommodationName(e.target.value);
                }}
              />
            </StyledFormControl>

            <StyledFormControl>
              <TextField
                id="standard-basic"
                label="Any hotel link to book"
                name="step-location"
                multiline={true}
                fullWidth={true}
                variant="outlined"
                value={accommodationUrl}
                onChange={e => {
                  setAccommodationUrl(e.target.value);
                }}
              />
            </StyledFormControl>
            {step && (!step.place || !step.place.lat || !step.place.lng) && (
              <>
                <TextDescription size="md" sx={{ textAlign: 'left' }}>
                  Right click on <strong>Google Maps</strong> place and{' '}
                  <strong>copy</strong> coordinates
                </TextDescription>
                <Space size="xs" />
                <StyledFormControl>
                  <TextField
                    id="standard-basic"
                    label="Location (paste coordinates)"
                    name="accommodation-location"
                    multiline={true}
                    fullWidth={true}
                    variant="outlined"
                    value={
                      accommodationLatitude && accommodationLongitude
                        ? accommodationLatitude + ', ' + accommodationLongitude
                        : ''
                    }
                    onChange={e => {
                      const res = e.target.value.split(',', 2);
                      const latitude = parseFloat(res[0]);
                      const longitude = parseFloat(res[1]);

                      if (latitude <= 90 && latitude >= -90) {
                        setAccommodationLatitude(latitude);
                      } else {
                        setAccommodationLatitude(0);
                      }
                      if (longitude <= 180 && latitude >= -180) {
                        setAccommodationLongitude(longitude);
                      } else {
                        setAccommodationLongitude(0);
                      }
                    }}
                    sx={{ backgroundColor: '#fafafa' }}
                  />
                </StyledFormControl>
              </>
            )}
            <Space size="md" />
            <PrimaryButton
              disabled={loading}
              onClick={async () => {
                gtag('event', 'edit-accommodation-save');
                try {
                  const res = await updateAccommodationStep();
                  if (
                    res &&
                    res.data &&
                    res.data.updateUserStep &&
                    res.data.updateUserStep.days
                  ) {
                    dispatch(setDays(res.data.updateUserStep.days));
                  }
                } catch (e) {
                  gtag('event', 'error-edit-accommodation');
                  dispatch(setErrorTitle('Error updating accommodation'));
                  dispatch(setErrorModalOpen(true));
                }

                // try {
                //   const res = await updateAccommodation();
                //   if (
                //     res &&
                //     res.data &&
                //     res.data.updateUserAccommodation &&
                //     res.data.updateUserAccommodation.days
                //   ) {
                //     dispatch(setDays(res.data.updateUserAccommodation.days));
                //     closeModal();
                //   }
                // } catch (e) {
                //   dispatch(setErrorTitle('Error updating accommodation'));
                //   dispatch(setErrorModalOpen(true));
                // }
              }}
            >
              {accommodationStepLoading ? <Loader /> : 'Save'}
            </PrimaryButton>
            <Space size="md" />
            <SecondaryButton
              onClick={async () => {
                gtag('event', 'edit-images-close');
                // setOpen(false);
                closeModal();
              }}
            >
              Close
            </SecondaryButton>
          </>
        )}
        {category === 'CATEGORY_TRANSPORT' && (
          <>
            <ModalHeader title="Edit transport" />
            <StyledFormControl>
              <MyEditor
                value={stepDescription}
                onChange={handleEditorChangeStep}
              />
            </StyledFormControl>
            <StyledFormControl>
              <InputLabel htmlFor="grouped-native-select">
                Transport type
              </InputLabel>
              <Select
                native
                value={stepType}
                id="grouped-native-select"
                onChange={e => setStepType(e.target.value as string)}
                label="Activity type"
              >
                {categories.map((category, i) => (
                  <React.Fragment key={i}>
                    {category.type === 'CATEGORY_TRANSPORT' && (
                      <optgroup label={category.name} key={i}>
                        {category.subCategories &&
                          category.subCategories.map((subCategory, i) => (
                            <option key={i} value={subCategory.type}>
                              {subCategory.name}
                            </option>
                          ))}
                      </optgroup>
                    )}
                  </React.Fragment>
                ))}
              </Select>
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                label="Step or activity title"
                name="title"
                fullWidth={true}
                value={stepTitle}
                variant="outlined"
                onChange={e => {
                  setStepTitle(e.target.value);
                }}
              />
            </StyledFormControl>

            <PrimaryButton
              disabled={loading}
              onClick={async () => {
                gtag('event', 'edit-transport-save');
                try {
                  const res = await updateTransportStep();
                  if (
                    res &&
                    res.data &&
                    res.data.updateUserStep &&
                    res.data.updateUserStep.days
                  ) {
                    dispatch(setDays(res.data.updateUserStep.days));
                    closeModal();
                  }
                } catch (e) {
                  gtag('event', 'error-edit-transport');
                  dispatch(setErrorTitle('Error saving transport'));
                  dispatch(setErrorModalOpen(true));
                }
              }}
            >
              {loadingTransport ? <Loader /> : 'Save'}
            </PrimaryButton>
            <Space size="md" />
            <SecondaryButton
              onClick={async () => {
                gtag('event', 'edit-images-close');
                // setOpen(false);
                closeModal();
              }}
            >
              Close
            </SecondaryButton>
          </>
        )}
      </ModalPaper>
    </CenteredModal>
  );
};
