import { useLazyQuery } from '@apollo/client';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, CardMedia, Stack } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../store/DaySlice';
import { setTrip } from '../store/TripSlice';

import { Loader } from './../theme-components/Loader';
import { useAuth } from './auth/firebase';
import { QUERY_USER_TRIP } from './gql-user/userTrip';

export default function UploadImage(props: {
  tripId: string;
  stepId?: string;
  accommodationId?: string;
  header?: boolean | null;
  main?: boolean | null;
  secondary?: boolean | null;
  tertiary?: boolean | null;
}) {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { idToken } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState('');
  const auth = getAuth();
  const user = auth.currentUser;

  const handleChange = async (e: any) => {
    if (e.target.files.length) {
      const preview = URL.createObjectURL(e.target.files[0]);
      setPreview(preview);
      await handleUpload(e.target.files[0].type, preview);
    }
  };

  const [getUserTrip, { data }] = useLazyQuery(QUERY_USER_TRIP, {
    fetchPolicy: 'no-cache',
    variables: { trip_id: props.tripId },
  });

  useEffect(() => {
    if (data && data.userTrip) {
      dispatch(setTrip(data.userTrip));
    }
  }, [data, dispatch]);

  const formData = new FormData();

  const handleUpload = async (imageType: string, preview: string) => {
    setUploading(true);
    const blob = await fetch(preview).then(r => r.blob());

    const file = new File([blob], 'image', {
      type: imageType,
    });

    const imageFile = file;

    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      maxIteration: 8,
    };

    if (user != null) {
      try {
        const compressedFile = await imageCompression(imageFile, options);
        formData.append('image', compressedFile);

        if (!props.stepId && !props.accommodationId && props.tripId) {
          gtag('event', 'upload-in-add-flow-trip-image');
          formData.append('tripId', props.tripId);
        }
        if (props.stepId) {
          gtag('event', 'upload-in-add-flow-step-image');
          formData.append('stepId', props.stepId);
        }
        if (props.accommodationId) {
          gtag('event', 'upload-in-add-flow-accommodation-image');
          formData.append('accommodationId', props.accommodationId);
        }

        if (props.header) {
          gtag('event', 'upload-in-add-flow-header-image');
          formData.append('isHeader', 'true');
        } else if (props.main) {
          gtag('event', 'upload-in-add-flow-main-image');
          formData.append('isMain', 'true');
        } else if (props.secondary) {
          gtag('event', 'upload-in-add-flow-secondary-image');
          formData.append('isSecondary', 'true');
        } else if (props.tertiary) {
          gtag('event', 'upload-in-add-flow-tertiary-image');
          formData.append('isTertiary', 'true');
        }

        // const token = await getAccessTokenSilently();

        // const token = await getIdToken(user);
        await fetch(process.env.REACT_APP_CONFIG_URL_LOCKED + '/upload', {
          credentials: 'same-origin',
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        // Get updated trip with pictures
        const { data } = await getUserTrip();
        if (data && data.userTrip) {
          dispatch(setTrip(data.userTrip));
          dispatch(setDays(data.userTrip.itinerary.days));
        }
        setUploading(false);
      } catch (error) {
        // TODO
      }
    }
  };

  return (
    <div>
      <label htmlFor="upload-button">
        <Box
          sx={theme => ({
            minWidth: 300,
            marginTop: 0,
            marginBottom: 1,
            marginLeft: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Center content horizontally
            justifyContent: 'center', // Center content vertically
            [theme.breakpoints.down(1100)]: {
              marginTop: 2,
              marginLeft: 0,
            },
          })}
        >
          {/* Placeholder with image */}
          <Box
            sx={theme => ({
              width: '100%',
              height: 320,
              position: 'relative',
              // borderRadius: 1,
              borderRadius: '10px',
              border: preview ? 0 : '1px dashed #ccc', // Border style
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f4f4f4', // Placeholder background
              [theme.breakpoints.down('sm')]: {
                height: 230,
              },
            })}
          >
            {preview ? (
              <CardMedia
                sx={{
                  borderRadius: '10px',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // Ensure image covers the area
                }}
                image={preview}
                // alt="Uploaded Preview"
              />
            ) : (
              <Stack direction="column" alignItems="center" spacing={1}>
                <CloudUploadIcon sx={{ fontSize: 40, color: '#bbb' }} />
                {/* Icon for upload */}
                <Box
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: '#888',
                    textAlign: 'center',
                    padding: '10px 20px',
                    borderRadius: 5,
                    backgroundColor: '#e8e8e8',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  }}
                >
                  Click here to choose image
                </Box>
              </Stack>
            )}
          </Box>

          {/* Change image button (visible after image upload) */}
          {preview && (
            <Box sx={{ marginTop: 1 }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CloudUploadIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                <Box
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: 'primary.main',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Change image
                </Box>
              </Stack>
            </Box>
          )}
        </Box>
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      {uploading ? <Loader /> : null}
    </div>
  );
}
