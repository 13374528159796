import { gql } from 'graphql-tag';
import { ACTIVITY_FIELDS } from './schemaActivity';

export const QUERY_GET_USER_FAVOURITE_ACTIVITIES = gql`
  query userLikedActivities {
    userLikedActivities {
      ...ActivityFields
    }
  }
  ${ACTIVITY_FIELDS}
`;
