import { gql } from 'graphql-tag';
import { PLACE_FIELDS } from './schemaPlace';

export const QUERY_GET_USER_FAVOURITE_PLACES = gql`
  query userLikedPlaces {
    userLikedPlaces {
      ...PlaceFields
    }
  }
  ${PLACE_FIELDS}
`;
