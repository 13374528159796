import { useLazyQuery, useMutation } from '@apollo/client';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
} from '@mui/material';
import imageCompression from 'browser-image-compression';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Image, Maybe } from '../../../generated/public_graphql';
import { Step, StepPlaceImage } from '../../../generated/user_graphql';
import { setDays } from '../../../store/DaySlice';
import { setTrip } from '../../../store/TripSlice';
import { SecondaryButton } from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';
import TextDescription, { TitleH4 } from '../../../theme-components/Typography';
import { unauthorizedClient } from '../../../UnAuthorizedApolloProvider';
import { useAuth } from '../../auth/firebase';
import { SetMainImage } from '../../favourite/mainImage';
import { FETCH_IMAGE_URL } from '../../gql-public/getPlaceImage';
import {
  MUTATION_DELETE_STEP_PLACE_IMAGE,
  MUTATION_DELETE_USER_IMAGE,
} from '../../gql-user/images';
import { QUERY_USER_TRIP } from '../../gql-user/userTrip';
import { CenteredModal, ModalPaper } from '../../styling/modal';

export const EditImagesModal: React.FC<{
  step: Step;
  tripId: string;
  handleCloseModal: () => void;
}> = ({ step, tripId, handleCloseModal }) => {
  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        <ManageStepImages tripId={tripId} step={step} />
        {step.placeImages && (
          <RenderImages
            tripId={tripId}
            images={step.placeImages}
            userImages={false}
            placeImages={true}
            tripImages={false}
          />
        )}
        <Space size="md" />
        <SecondaryButton
          onClick={async () => {
            gtag('event', 'edit-images-close');
            closeModal();
          }}
        >
          Close
        </SecondaryButton>
      </ModalPaper>
    </CenteredModal>
  );
};

export function ManageStepImages(props: { step: Step; tripId: string }) {
  const dispatch = useDispatch();
  const { idToken } = useAuth();
  const [imagePreview, setImagePreview] = useState('');
  const [imageLoading, setImageLoading] = useState(false);

  // const [compressedFile, setCompressedFile] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  const addImage = async (e: any) => {
    if (e.target.files.length) {
      const formData = new FormData();
      gtag('event', 'upload-image');
      formData.append('stepId', props.step.id);
      setImageLoading(true);
      const preview = URL.createObjectURL(e.target.files[0]);
      setImagePreview(preview);
      await handleUpload(e.target.files[0].type, preview, formData);
      setImageLoading(false);
    }
  };

  const [getUserTrip] = useLazyQuery(QUERY_USER_TRIP, {
    fetchPolicy: 'no-cache',
    variables: { trip_id: props.tripId },
  });

  // useEffect(() => {
  //   if (data && data.userTrip) {
  //     dispatch(setDays(data.userTrip.itinerary.days));
  //   }
  // }, [data, dispatch]);

  // useEffect(() => {
  //   if (uploadComplete) {
  //     getUserTrip();
  //     setImageLoading(false);
  //   }
  // }, [uploadComplete, getUserTrip]);

  const handleUpload = async (
    imageType: string,
    preview: string,
    formData: FormData,
  ) => {
    const blob = await fetch(preview).then(r => r.blob());

    const file = new File([blob], 'image', {
      type: imageType,
    });

    const imageFile = file;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };

    if (user != null) {
      try {
        const compressedFile = await imageCompression(imageFile, options);
        formData.append('image', compressedFile);

        await fetch(process.env.REACT_APP_CONFIG_URL_LOCKED + '/upload', {
          credentials: 'same-origin',
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        const { data } = await getUserTrip();
        if (data && data.userTrip) {
          dispatch(setTrip(data.userTrip));
          dispatch(setDays(data.userTrip.itinerary.days));
        }
      } catch (error) {
        // TODO
        console.log(error);
      }
    }
  };

  //NEW
  let image: string | null = null;
  if (imagePreview) {
    image = imagePreview;
  }

  return (
    <>
      {props.step.images && (
        <>
          <ModalHeader title="My images" />
          <TextDescription size="md" sx={{ textAlign: 'left' }}>
            Only 3 images will show on the itinerary
          </TextDescription>
          <RenderImages
            tripId={props.tripId}
            images={props.step.images}
            userImages={true}
            placeImages={false}
            tripImages={false}
          />
        </>
      )}
      <label htmlFor="upload-image">
        <Box>
          {!imageLoading ? (
            <>
              <Space size="md" />
              <Stack direction="row">
                <CloudUploadTwoToneIcon
                  sx={{ width: 25, height: 25, marginRight: 1 }}
                />
                <Box
                  sx={theme => ({
                    alignSelf: 'center',
                    fontWeight: 500,
                    fontSize: 16,
                    color: theme.palette.primary.main,
                  })}
                >
                  Upload image
                </Box>
              </Stack>
            </>
          ) : (
            <Loader />
          )}
        </Box>
      </label>
      <input
        type="file"
        id="upload-image"
        style={{ display: 'none' }}
        onChange={addImage}
      />
    </>
  );
}

export function RenderImages(props: {
  tripId: string;
  images: Maybe<Image>[] | Maybe<StepPlaceImage>[];
  userImages: boolean;
  placeImages: boolean;
  tripImages: boolean;
}) {
  //
  return (
    <>
      {props.images && props.images.length > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {props.placeImages && (
            <>
              <TitleH4>Place images</TitleH4>
              <TextDescription size="md" sx={{ textAlign: 'left' }}>
                Only 3 images will show on the itinerary
              </TextDescription>
            </>
          )}

          <Grid
            container
            spacing={1}
            direction="row"
            // wrap="nowrap"
            sx={{ justifyContent: 'left', paddingTop: 2 }}
          >
            {props.images.map((image, index) => (
              <Grid
                item
                xs={4} // 12/4 = 3 items per row on extra-small screens and above
                sm={4} // 3 items per row on small screens and above
                md={4} // 3 items per row on medium screens and above
                lg={4} // 3 items per row on large screens and above
                xl={4} // 3 items per row on extra-large screens and above
                key={index}
              >
                <ImageCard
                  tripId={props.tripId}
                  image={image}
                  index={index}
                  userImages={props.userImages}
                  placeImages={props.placeImages}
                  tripImages={props.tripImages}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}

function ImageCard(props: {
  image;
  index: number;
  userImages: boolean;
  placeImages: boolean;
  tripImages: boolean;
  tripId: string;
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    if (props.image.path === null || props.image.path === '') {
      const fetchImage = async () => {
        try {
          // const response = await unauthorizedClient.mutate({
          //   mutation: FETCH_IMAGE_URL,
          //   variables: { image_url: props.image.imageUrl },
          // });
          const response = await unauthorizedClient.query({
            query: FETCH_IMAGE_URL,
            variables: { image_url: props.image.imageUrl },
          });

          if (response.data && response.data.fetchImage.imageData) {
            // Ensure the response contains base64 data in the correct format
            const base64Data = `data:image/jpeg;base64,${response.data.fetchImage.imageData}`; // Adjust MIME type as needed
            setImageSrc(base64Data);
            setImageLoaded(true);
          }
        } catch (err) {
          console.error('Error fetching image:', err);
          return <></>;
        }
      };

      fetchImage();
    } else {
      setImageSrc(props.image.path);
    }
  }, [props.image.imageUrl]);

  const [deleteUserImage, { loading }] = useMutation(
    MUTATION_DELETE_USER_IMAGE,
    {
      variables: {
        id: props.image.id,
      },
    },
  );
  const [deleteStepPlaceImage, { loading: loadingPlaceImage }] = useMutation(
    MUTATION_DELETE_STEP_PLACE_IMAGE,
    {
      variables: {
        step_place_image_id: props.image.id,
      },
    },
  );

  const [getUserTrip] = useLazyQuery(QUERY_USER_TRIP, {
    fetchPolicy: 'no-cache',
    variables: { trip_id: props.tripId },
  });

  let imageUrl = '';
  if (props.userImages || props.tripImages) {
    imageUrl = props.image.path;
  }

  if (props.placeImages) {
    if (imageSrc === '') {
      return (
        <Skeleton
          variant="rectangular"
          sx={theme => ({
            height: 150,
            width: '100%',
            objectFit: 'cover',
            [theme.breakpoints.down('sm')]: {
              height: 130,
            },
          })}
        />
      );
    }
  }

  if (props.placeImages) {
    imageUrl = imageSrc;
  }

  const handleDeleteImage = async () => {
    try {
      if (props.placeImages) {
        await deleteStepPlaceImage();
      } else if (props.userImages || props.tripImages) {
        await deleteUserImage();
      }

      const { data } = await getUserTrip();
      if (data && data.userTrip) {
        dispatch(setTrip(data.userTrip));
        dispatch(setDays(data.userTrip.itinerary.days));
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {!imageLoaded && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{
              maxHeight: 100,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        <Tooltip
          key={props.index}
          title={(props.image && props.image.title) || 'Image'}
          disableInteractive
          arrow
          placement="top"
        >
          <CardMedia
            component="img"
            src={imageUrl}
            alt={props.image?.title || 'Place Image'}
            onLoad={() => setImageLoaded(true)} // Set imageLoaded to true once the image is loaded
            sx={{
              maxHeight: 150,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              display: imageLoaded ? 'block' : 'none',
            }}
          />
        </Tooltip>
        <Box
          sx={{
            position: 'absolute',
            bottom: 4,
            right: 4,
            width: 39,
            height: 39,
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            borderRadius: 20,
            zIndex: 2,
          }}
        >
          <IconButton
            aria-label="delete"
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              zIndex: 3,
              color: '#FFFFFF',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
            }}
            disabled={loading || loadingPlaceImage}
            onClick={handleDeleteImage}
          >
            {loading || loadingPlaceImage ? (
              <CircularProgress size={15} sx={{ color: '#FFFFFF' }} />
            ) : (
              <DeleteIcon
                sx={{
                  height: 15,
                  width: 15,
                }}
              />
            )}
          </IconButton>
        </Box>
        {props.tripImages && (
          <SetMainImage
            tripId={props.tripId}
            imageId={props.image.id}
            isMain={props.image.main}
            bottom="45px"
            right="4px"
          />
        )}
      </Box>
    </Card>
  );
}
