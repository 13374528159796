import { gql } from 'graphql-tag';
import { ITINERARY_FIELDS } from './schema';

export const MUTATION_DELETE_DAY = gql`
  mutation deleteUserDay($day_id: String!) {
    deleteUserDay(day_id: $day_id) {
      ...ItineraryFields
    }
  }
  ${ITINERARY_FIELDS}
`;
