import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';
import { MUTATION_DELETE_RECOMMENDATION } from '../../gql-user/deleteRecommendationMutation';
import { CenteredModal, ModalPaper } from '../../styling/modal';
import { Box, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useEffect } from 'react';
import { setTrip, setUserTrips } from '../../../store/TripSlice';

const DeleteRecommendationModal: React.FC<{
  dayId?: string;
  stepId?: string;
  tripId?: string;
  recommendationId: string;
  handleCloseModal: () => void;
}> = ({ dayId, stepId, tripId, recommendationId, handleCloseModal }) => {
  const dispatch = useDispatch();

  const [deleteRecommendation, { loading: deleteDayLoading }] = useMutation(
    MUTATION_DELETE_RECOMMENDATION,
    {
      variables: {
        day_id: dayId,
        step_id: stepId,
        trip_id: tripId,
        recommendation_id: recommendationId,
      },
    },
  );

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={true}
        onClose={closeModal}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader
            title="Are you sure?"
            description="This can not be undone!"
          />
          <Space size="md" />
          <PrimaryButton
            disabled={deleteDayLoading}
            onClick={async () => {
              gtag('event', 'delete-recommendation-confirm');
              try {
                const res = await deleteRecommendation();
                if (
                  res &&
                  res.data &&
                  res.data.deleteRecommendation &&
                  res.data.deleteRecommendation.itinerary &&
                  res.data.deleteRecommendation.itinerary.days
                ) {
                  dispatch(setTrip(res.data.deleteRecommendation));
                  dispatch(
                    setDays(res.data.deleteRecommendation.itinerary.days),
                  );
                  closeModal();
                }
              } catch (e) {
                gtag('event', 'error-delete-recommendation');
                dispatch(setErrorTitle('Error deleting recommendation'));
                dispatch(setErrorModalOpen(true));
              }
            }}
          >
            {deleteDayLoading ? <Loader /> : 'Delete'}
          </PrimaryButton>
          <Space size="md" />
          <SecondaryButton
            disabled={deleteDayLoading}
            onClick={async () => {
              gtag('event', 'delete-recommendation-close');
              closeModal();
            }}
          >
            Close
          </SecondaryButton>
        </ModalPaper>
      </CenteredModal>
    </>
  );
};

export const DeleteRecommendationButton: React.FC<{
  dayId?: string;
  stepId?: string;
  tripId?: string;
  recommendationId: string;
  small?: boolean;
}> = ({ dayId, stepId, tripId, recommendationId, small = false }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        aria-label="delete"
        sx={{
          position: 'absolute',
          top: 4,
          left: 4,
          width: small ? 20 : 30,
          height: small ? 20 : 30,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          backdropFilter: 'blur(3px)',
          borderRadius: 20,
          zIndex: 2,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)', // Change to red on hover
          },
        }}
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <DeleteIcon
          sx={{
            height: small ? 10 : 15,
            width: small ? 10 : 15,
          }}
        />
      </IconButton>
      {open && (
        <DeleteRecommendationModal
          dayId={dayId}
          stepId={stepId}
          tripId={tripId}
          recommendationId={recommendationId}
          handleCloseModal={() => setOpen(false)}
        />
      )}
    </>
  );
};

export const RecommendationMenu: React.FC<{
  dayId?: string;
  stepId?: string;
  tripId?: string;
  recommendationId: string;
  small?: boolean;
}> = ({ dayId, stepId, tripId, recommendationId, small = false }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const openMenu = Boolean(anchorEl);
  // const [placeName, setPlaceName] = useState('');
  // const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);
  // const [added, setAdded] = useState(false);
  // const [open, setOpen] = useState(false);
  // const navigate = useNavigate();
  // const [fixedAnchorPosition, setFixedAnchorPosition] =
  //   useState<AnchorPosition | null>(null);
  // const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  // const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  // const [scrollY, setScrollY] = useState(0);

  // const handleClick = event => {
  // document.body.style.top = `-${scrollY}px`;
  // setAnchorEl(event.currentTarget);
  // const rect = event.currentTarget.getBoundingClientRect();
  // setFixedAnchorPosition({ top: rect.top, left: rect.left });
  // setPlaceName(
  //   country ? `Trip to ${country}` : 'Placeholder name (change me)',
  // );
  // };

  // const disableScroll = () => {
  //   document.body.style.overflow = 'hidden'; // Disable scroll
  //   document.body.style.position = 'fixed'; // Prevent page jump
  //   document.body.style.top = `-${scrollY}px`; // Move body to the saved scroll position
  // };

  // const enableScroll = () => {
  //   document.body.style.overflow = ''; // Restore scroll
  //   document.body.style.position = ''; // Reset positioning
  //   window.scrollTo(0, scrollY); // Restore previous scroll position
  // };

  // useEffect(() => {
  //   if (isModalDisplayed) {
  //     disableScroll(); // Lock scrolling when modal is displayed
  //   }

  //   return () => {
  //     enableScroll(); // Clean up scrolling state when modal is closed
  //   };
  // }, [isModalDisplayed]);

  const handleClose = () => {
    setAnchorEl(null);
    setModalOpen(false);
  };

  useEffect(() => {
    if (openMenu) {
      const handleScroll = () => {
        handleClose();
      };

      const handleTouchMove = () => {
        handleClose();
      };

      const handleClickOutside = (event: MouseEvent) => {
        if (anchorEl && !anchorEl.contains(event.target as Node)) {
          handleClose();
        }
      };

      window.addEventListener('scroll', handleScroll);
      window.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [openMenu, anchorEl]);

  return (
    <>
      <>
        <IconButton
          onClick={e => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
          sx={theme => ({
            marginLeft: 1,
            width: 20,
            height: 20,
            // padding: '3px',
            backgroundColor: '#36454F',
            borderRadius: 20,
          })}
        >
          <MoreVertIcon sx={{ width: 15, height: 15, color: 'white' }} />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={() => {
            handleClose();
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          disableScrollLock={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              gtag('event', 'click-delete-recommendation');
              setModalOpen(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>

        {isModalOpen && (
          <DeleteRecommendationModal
            dayId={dayId}
            stepId={stepId}
            tripId={tripId}
            recommendationId={recommendationId}
            handleCloseModal={() => setModalOpen(false)}
          />
        )}
      </>
    </>
  );
};
