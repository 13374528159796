import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import { Box, Stack, Rating, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import {
  Maybe,
  Step,
  Image,
  StepPlaceImage,
} from '../../generated/public_graphql';
import { Activity } from '../../generated/user_graphql';
import { LinkButton } from '../../theme-components/Buttons';
import {
  CustomBookingComBlueIcon,
  GetYourGuideIcon,
} from '../../theme-components/Icons';
import Space from '../../theme-components/Spacing';
import TextDescription, {
  Body1,
  TitleH4,
  TitleH5,
} from '../../theme-components/Typography';
import {
  formatReviewString,
  formatSecondsToTime,
  formatSecondsToTimeShort,
} from '../../utils/helpers';
import { ActionsStep } from '../create-trip/edit-trip/ActionsStep';
import { LikeActivity } from '../favourite/LikeActivity';
import GoogleIcon from '../icons/GoogleIcon';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';
import { ExternalLinkRegular } from '../trip-details/TripDay';

import { AddToTripButton } from './AddToTrip';
import InstagramEmbedModal, {
  PhotosModal,
  TikTokEmbedModal,
  YouTubeEmbedModal,
} from './InstagramEmbeded';
import ActivityCardCTAs from './PublicActivityCardCTA';
import { SideScrollRecommendationsForStep } from './Recommendation';
import { StepPlaceImages, PlaceSingleImage } from './StepImages';
import { TripUser } from './TripItinerary';
import { PlaceOrActivityMoreInfoModal } from './PlaceMoreInfoModal';
import { useState } from 'react';

interface ActivityCardProps {
  step: Step;
  dayId?: string;
  selected?: boolean;
  tripId?: string;
  showEdit?: boolean;
  collapsedDetails?: boolean;
  tripType: string;
  tripUser: TripUser;
}

export const PublicActivityCard: React.FC<ActivityCardProps> = ({
  step,
  dayId,
  selected,
  tripId,
  showEdit,
  collapsedDetails,
  tripType,
  tripUser,
}) => {
  // const dispatch = useDispatch();
  let activity: Activity | null = null;

  if (step && step.activity) {
    activity = step.activity;
  }
  if (!step) {
    return <></>;
  }

  let customAccomodationImages: Maybe<Image>[] = [];
  if (
    step.accommodations &&
    step.accommodations[0] &&
    step.accommodations[0].images &&
    step.accommodations[0].images.length > 0
  ) {
    customAccomodationImages = step.accommodations[0].images;
  }

  let customStepImages: Maybe<Image>[] = [];
  if (step.images && step.images.length > 0) {
    customStepImages = step.images;
  }

  let placeImages: Maybe<StepPlaceImage>[] = [];
  if (step && step.placeImages && step.placeImages.length > 0) {
    placeImages = step.placeImages;
  }

  let instagramLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const instagramItem = step.socialLinks.find(
      item => item?.socialType === 'instagram',
    );
    if (instagramItem) {
      instagramLink = instagramItem.urlLink!;
    }
  }

  let tikTokLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const tikTokItem = step.socialLinks.find(
      item => item?.socialType === 'tiktok',
    );
    if (tikTokItem) {
      tikTokLink = tikTokItem.urlLink!;
    }
  }

  let youTubeLink: string | null = null;

  if (step && step.socialLinks && step.socialLinks.length > 0) {
    const youTubeItem = step.socialLinks.find(
      item => item?.socialType === 'youtube',
    );
    if (youTubeItem) {
      youTubeLink = youTubeItem.urlLink!;
    }
  }

  if (!activity) {
    return <></>;
  }

  return (
    <Stack
      direction="column"
      alignItems="left"
      sx={theme => ({
        width: '100%',
        paddingTop: 1.5,
        border: 2,
        borderColor: '#E4DBFF',
        paddingBottom: 2,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        borderRadius: '20px',
        backgroundColor: selected ? '#fff7e6' : '#f6f0ff',
        marginBottom: 0,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
          marginBottom: 0,
        },
      })}
    >
      <Stack
        direction="row"
        alignItems="left"
        sx={theme => ({
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
          },
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'flex-start',
            marginRight: 1,

            [theme.breakpoints.down('sm')]: {
              paddingTop: 0.5,
              marginRight: 0.5,
            },
          })}
        >
          <PlaceTwoToneIcon
            sx={theme => ({
              width: 25,
              height: 25,
              color: selected
                ? theme.palette.primary.main
                : theme.palette.icon.main,
              [theme.breakpoints.down('sm')]: {
                width: 20,
                height: 20,
              },
            })}
          />
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="top"
          sx={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {step.title ? <TitleH4>{step.title}</TitleH4> : <Box />}
          <>
            <Stack direction="row" spacing={0.75}>
              {activity && <LikeActivity activityId={activity.id} />}
              {dayId ? (
                <Box>
                  <AddToTripButton
                    stepId={step.id}
                    dayId={dayId}
                    buttonType="insideTitle"
                    buttonCopy="Save"
                    showDropDownIcon={true}
                  />
                </Box>
              ) : (
                <>
                  {tripId && !collapsedDetails && (
                    <Box>
                      {showEdit && (
                        <ActionsStep
                          copy={'Edit'}
                          step={step}
                          tripId={tripId}
                          tripType={tripType}
                        />
                      )}
                    </Box>
                  )}
                </>
              )}
            </Stack>
          </>
        </Stack>
      </Stack>

      <Box
        display={collapsedDetails === true ? 'none' : undefined}
        sx={theme => ({
          position: 'relative',
          [theme.breakpoints.down('sm')]: {},
        })}
      >
        {activity.rating && (
          <>
            <Stack direction="row" alignItems="center">
              <Box
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 0.5,
                  color: '#AB88F3',
                  display: 'flex', // Enable flexbox layout
                  alignItems: 'center', // Vertically center content
                  justifyContent: 'center', // Horizontally center content
                  [theme.breakpoints.down('sm')]: {},
                  [theme.breakpoints.down('sm')]: {
                    marginRight: 0.5,
                    width: 20,
                    height: 20,
                  },
                })}
              >
                {activity.dataSource === 'BOOKING_DOT_COM' && (
                  <CustomBookingComBlueIcon
                    sx={{ width: '100%', height: '100%' }}
                  />
                )}

                {activity.dataSource === 'GET_YOUR_GUIDE' && (
                  <GetYourGuideIcon sx={{ width: '100%', height: '100%' }} />
                )}

                {activity.dataSource !== 'GET_YOUR_GUIDE' &&
                  activity.dataSource !== 'BOOKING_DOT_COM' && <GoogleIcon />}
              </Box>
              {activity.rating && (
                <Stack direction="row" spacing={0.5}>
                  <Rating
                    name="activity-rating"
                    value={activity.rating}
                    precision={0.1}
                    readOnly
                    size="medium"
                  />
                  {activity.noOfReviews && (
                    <TextDescription size="md">
                      ({formatReviewString(activity.noOfReviews)})
                    </TextDescription>
                  )}
                </Stack>
              )}
            </Stack>
            <Space size="xs" />
          </>
        )}

        {activity.bookingLink && (
          <>
            <Stack direction="row" alignItems="center">
              <EventTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                  [theme.breakpoints.down('sm')]: {
                    marginRight: 0.5,
                    width: 20,
                    height: 20,
                  },
                })}
              />
              <LinkButton
                url={activity.bookingLink}
                type="external"
                prominent={true}
              >
                Check availability
              </LinkButton>
            </Stack>
            <Space size="xs" />
          </>
        )}
        {!!activity.durationInMinutes && (
          <>
            <Stack direction="row" alignItems="center">
              <UpdateTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                  [theme.breakpoints.down('sm')]: {
                    marginRight: 0.5,
                    width: 20,
                    height: 20,
                  },
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 16,
                  alignSelf: 'center',
                  color: '#36454F',
                  marginRight: 0.5,
                }}
              >
                Takes:&nbsp;
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    alignSelf: 'center',
                    color: '#6E7191',
                    marginRight: 0.5,
                  }}
                >
                  {formatSecondsToTime(activity.durationInMinutes * 60)}
                </Typography>
              </Typography>
            </Stack>{' '}
            <Space size="xs" />
          </>
        )}

        {!!activity.validForInMinutes && !activity.durationInMinutes && (
          <>
            <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
              <UpdateTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                  [theme.breakpoints.down('sm')]: {
                    marginRight: 0.5,
                    width: 20,
                    height: 20,
                  },
                })}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 16,
                  alignSelf: 'center',
                  color: '#36454F',
                  marginRight: 0.5,
                }}
              >
                Valid:&nbsp;
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    alignSelf: 'center',
                    color: '#6E7191',
                    marginRight: 0.5,
                  }}
                >
                  {formatSecondsToTime(activity.validForInMinutes * 60)}
                </Typography>
              </Typography>
            </Stack>
            <Space size="xs" />
          </>
        )}
        {activity.minPrice && (
          <>
            <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
              <LocalActivityTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: theme.palette.icon.main,
                  [theme.breakpoints.down('sm')]: {
                    marginRight: 0.5,
                    width: 20,
                    height: 20,
                  },
                })}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  alignSelf: 'center',
                  color: '#6E7191',
                  marginRight: 0.5,
                }}
              >
                {activity.minPrice && (
                  <>
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 600,
                        fontSize: 16,
                        alignSelf: 'center',
                        color: '#36454F',
                        marginRight: 0.5,
                      }}
                    >
                      Price from:
                    </Typography>{' '}
                    {activity.minPrice}
                    {!activity.maxPrice &&
                    activity.priceCurrency &&
                    activity.priceCurrency === 'GBP' ? (
                      <>£</>
                    ) : (
                      <></>
                    )}
                    {activity.maxPrice && (
                      <>
                        -{activity.maxPrice}
                        {activity.priceCurrency &&
                        activity.priceCurrency === 'GBP' ? (
                          <>£</>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                )}{' '}
                {activity.priceCategory ? (
                  <>{activity.priceCategory}</>
                ) : (
                  <>per person</>
                )}
              </Typography>
            </Stack>
            <Space size="xs" />
          </>
        )}
        {<ActivityCardCTAs activity={activity} />}

        {step.description && (
          <Body1>
            <RenderHtmlFromResponseNoMargin
              content={step.description}
              limitToLines={3}
            />
          </Body1>
        )}
        {(instagramLink || tikTokLink || youTubeLink) && (
          <>
            <TitleH5>Check out on</TitleH5>
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              sx={{ mb: 1, mt: 0.5, overflow: 'hidden' }}
            >
              {step && step.placeImages && step.placeImages.length > 0 && (
                <PhotosModal images={step.placeImages} />
              )}
              {instagramLink && <InstagramEmbedModal url={instagramLink} />}
              {tikTokLink && <TikTokEmbedModal url={tikTokLink} />}
              {youTubeLink && <YouTubeEmbedModal url={youTubeLink} />}
            </Stack>
          </>
        )}

        {step.url && <ExternalLinkRegular url={step.url} copy={'Read more'} />}
        <Box>
          {customStepImages.length > 0 &&
            customAccomodationImages.length === 0 && (
              <>
                {customStepImages.length > 1 ? (
                  <StepPlaceImages images={customStepImages} />
                ) : (
                  <PlaceSingleImage images={customStepImages} />
                )}
              </>
            )}
          {customStepImages.length === 0 &&
            customAccomodationImages.length === 0 &&
            placeImages.length > 0 && (
              <>
                {placeImages.length > 1 ? (
                  <StepPlaceImages images={placeImages} />
                ) : (
                  <PlaceSingleImage images={placeImages} />
                )}
              </>
            )}
        </Box>
        {step.recommendations &&
          step.recommendations.length > 0 &&
          (step.recommendations[0]?.place ||
            step.recommendations[0]?.activity) && (
            <SideScrollRecommendationsForStep
              recommentations={step.recommendations}
              type="step"
              step={step}
              tripUser={tripUser}
            />
          )}
        {/* <Box
          key={activity ? activity.id : 'place'}
          style={{
            position: 'absolute', // Position absolutely inside the parent
            top: '5px', // Place it at the top
            right: '0', // Place it at the right
            zIndex: 1,
          }}
        >
          {dayId ? (
            <>{ <LikePlace placeId={activity.id} />}</>
          ) : (
            <>{ <LikePlace placeId={activity.id} />}</>
          )}
        </Box> */}
      </Box>
    </Stack>
  );
};
