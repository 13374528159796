import { useQuery } from '@apollo/client';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import {
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { Trip } from '../../generated/public_graphql';
import { errorsSelector } from '../../store/ErrorSlice';
import { CardLabel } from '../../theme-components/Lables';
import { isAdmin, isPublisher, useUserRoles } from '../../utils/helpers';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { Header } from '../AppWrapper';
import CreateTripModal from '../create-trip/manage-trip-modals/CreateTripModal';
import ErrorModal from '../Error';
import { LikeTrip } from '../favourite/likeTrip';
import { QUERY_GET_USER_SHARED_TRIPS } from '../gql-user/userSharedTripsQuery';
import { QUERY_GET_USER_TRIPS } from '../gql-user/userTripsQuery';
import { TravelSvg, TravelSvgWithBackground } from '../illustrations/Travel';

import ShareTripModal from './ShareTripModal';
import UnpublishTripModal from './UnpublishTripModal';

interface PropsTripsList {
  readonly trips: Maybe<Trip>[];
}

export function UserTrips() {
  const { loading, error, data } = useQuery(QUERY_GET_USER_TRIPS);
  const {
    loading: loadingShared,
    error: errorShared,
    data: dataShared,
  } = useQuery(QUERY_GET_USER_SHARED_TRIPS);
  const { errorModalOpen } = useSelector(errorsSelector);

  if (loading || loadingShared) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingLeft: 1,
        paddingRight: 1,
        [theme.breakpoints.down('sm')]: {
          paddingTop: 1,
        },
      })}
    >
      {errorModalOpen && <ErrorModal />}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header>My trips</Header>
        {data && data.userTripsList && data.userTripsList.length > 3 && (
          <CreateTripModal type="itinerary" />
        )}
      </Box>

      {error || !data ? (
        <div>Error loading your trips, refresh to try agian </div>
      ) : (
        <CreatedTrips trips={data.userTripsList} />
      )}

      {errorShared || !dataShared ? (
        <div>Error loading shared trips </div>
      ) : (
        <>
          {dataShared.userSharedTripsList.length > 0 && (
            <>
              <Header>Shared with me</Header>
              <Divider />
              <RenderTrips
                trips={dataShared.userSharedTripsList}
                publicTrip={false}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
}

function CreatedTrips(props: PropsTripsList) {
  const navigate = useNavigate();
  const roles = useUserRoles();
  const [needsUnpublishing, setNeedsUnpublishing] = useState(false);
  const [selectedTripId, setSelectedTripId] = useState('');

  return (
    <Box mb={2}>
      {props.trips && props.trips.length > 0 ? (
        <>
          <Grid container>
            {props.trips.map((trip, index) => (
              <React.Fragment key={index}>
                {trip && (
                  <Stack
                    key={index + trip.id}
                    direction="column"
                    sx={theme => ({
                      // position: 'relative',
                      cursor: 'pointer',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                      borderRadius: '20px',
                      marginTop: 2,
                      marginRight: 1,
                      width: '31%',
                      [theme.breakpoints.down('xl')]: {
                        width: '31%',
                      },
                      [theme.breakpoints.down('lg')]: {
                        width: '48%',
                      },
                      [theme.breakpoints.down('md')]: {
                        width: '47%',
                      },
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        marginRight: 0,
                        marginLeft: 0,
                      },
                    })}
                  >
                    <Box
                      onClick={() => {
                        if (trip.published === false) {
                          navigate('/create-route/' + trip.id);
                        } else if (isAdmin(roles) || isPublisher(roles)) {
                          navigate('/create-route/' + trip.id);
                        } else {
                          setNeedsUnpublishing(true);
                          setSelectedTripId(trip.id);
                        }
                        gtag('event', 'user-trip-edit-card');
                      }}
                      key={index + trip.id}
                      sx={theme => ({
                        position: 'relative',
                      })}
                    >
                      <Box>
                        {trip.images && trip.images.length > 0 ? (
                          <CardMedia
                            component="img"
                            height="250"
                            sx={{ borderRadius: '20px 20px 0px 0px' }}
                            image={
                              trip.images?.find(image => image!.main)?.path ||
                              trip.images[0]?.path ||
                              ''
                            }
                          />
                        ) : (
                          <CardMedia
                            component={TravelSvgWithBackground}
                            height="250"
                            sx={{
                              borderRadius: '20px 20px 0px 0px',
                              // backgroundColor: 'FFD54F',
                            }}
                          />
                        )}
                        <CardLabel
                          text={
                            trip && trip.tripType && trip.tripType === 'guide'
                              ? 'Guide'
                              : 'Itinerary'
                          }
                        />
                        {trip && trip.published && (
                          <CardLabel
                            text="Published"
                            right={
                              trip && trip.tripType && trip.tripType === 'guide'
                                ? '65px'
                                : '82px'
                            }
                          />
                        )}

                        {/* Blurred content at the bottom */}
                        <Box
                          sx={{
                            position: 'absolute',
                            width: '100%',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(3px)',
                            padding: 1,
                            paddingX: 2,
                            paddingBottom: 1,
                            // borderRadius: '0 0 20px 20px',
                          }}
                        >
                          <TripTitle>{trip!.name}</TripTitle>
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          >
                            <>
                              {trip &&
                              trip.tripType &&
                              trip.tripType === 'guide' ? (
                                <>
                                  {trip &&
                                    trip.cities &&
                                    trip.cities.length > 0 && (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                        }}
                                      >
                                        {trip.cities.map((city, index) => (
                                          <React.Fragment key={index}>
                                            <TripCountry>
                                              {city?.name}
                                            </TripCountry>
                                            {index !==
                                              trip.cities!.length - 1 && (
                                              <Box
                                                sx={{
                                                  width: 5,
                                                  height: 5,
                                                  backgroundColor: 'black',
                                                  borderRadius: '50%',
                                                  mx: 1,
                                                }}
                                              />
                                            )}
                                          </React.Fragment>
                                        ))}
                                      </Stack>
                                    )}
                                </>
                              ) : (
                                <>
                                  {trip &&
                                    trip.itineraryCountries &&
                                    trip.itineraryCountries.length > 0 &&
                                    trip.itineraryCountries.map(
                                      (country, index) => (
                                        <React.Fragment key={index}>
                                          {country && (
                                            <>
                                              {trip.length && index === 0 && (
                                                <TripLength>
                                                  {trip.length}
                                                  {trip.length === 1
                                                    ? ' day'
                                                    : ' days'}
                                                </TripLength>
                                              )}
                                              <Box
                                                sx={{
                                                  width: 5,
                                                  height: 5,
                                                  backgroundColor: 'black',
                                                  borderRadius: '50%',
                                                  mx: 1,
                                                }}
                                              />
                                              <TripCountry>
                                                {country.countryName}
                                              </TripCountry>
                                            </>
                                          )}
                                        </React.Fragment>
                                      ),
                                    )}
                                </>
                              )}
                            </>
                          </Stack>
                        </Box>
                      </Box>
                    </Box>
                    {/* Button container right under the image box */}
                    <Stack
                      width="100%"
                      direction="row"
                      sx={theme => ({
                        paddingTop: 1.5, // Reduced padding to remove extra space
                        paddingBottom: 1.5,
                        justifyContent: 'space-around', // Distribute buttons evenly
                      })}
                      display="flex"
                      alignItems="center"
                    >
                      <Button
                        onClick={() => {
                          navigate('/preview/' + trip.id);
                          gtag('event', 'user-trip-preview-icon');
                        }}
                        sx={{
                          padding: 0,
                          backgroundColor: 'transparent',
                          color: theme => theme.palette.icon.main,
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: theme =>
                              theme.palette.action.hover,
                          },
                        }}
                      >
                        <VisibilityTwoToneIcon
                          sx={{
                            width: 20,
                            height: 20,
                            marginRight: 0.5,
                          }}
                        />
                        <TripButtons>View</TripButtons>
                      </Button>

                      <Button
                        onClick={() => {
                          isAdmin(roles);
                          if (trip.published === false) {
                            navigate('/create-route/' + trip.id);
                          } else if (isAdmin(roles) || isPublisher(roles)) {
                            navigate('/create-route/' + trip.id);
                          } else {
                            setNeedsUnpublishing(true);
                            setSelectedTripId(trip.id);
                          }

                          gtag('event', 'user-trip-edit-icon');
                        }}
                        sx={{
                          padding: 0,
                          backgroundColor: 'transparent',
                          color: theme => theme.palette.icon.main,
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: theme =>
                              theme.palette.action.hover,
                          },
                        }}
                      >
                        <EditTwoToneIcon
                          sx={{
                            width: 20,
                            height: 20,
                            marginRight: 0.5,
                          }}
                        />
                        <TripButtons>Edit</TripButtons>
                      </Button>

                      <ShareTripModal tripId={trip.id} />
                    </Stack>
                    {needsUnpublishing && (
                      <UnpublishTripModal
                        tripId={trip.id}
                        tripType={trip.tripType ? trip.tripType : undefined}
                        handleCloseModal={() => {
                          setNeedsUnpublishing(false);
                        }}
                        open={selectedTripId === trip.id}
                      />
                    )}
                  </Stack>
                )}
              </React.Fragment>
            ))}
          </Grid>

          <Box
            mt={3}
            sx={{
              border: '2px solid',
              borderColor: '#d3d3d3',
              borderRadius: '20px',
              padding: 1,
              backgroundColor: '#f0f0f0',
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <CreateTripModal type="itinerary" />
              <CreateTripModal type="guide" />
            </Stack>
          </Box>
        </>
      ) : (
        <Stack
          direction="column"
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          sx={theme => ({
            marginTop: '10%',
            [theme.breakpoints.down('sm')]: {
              marginTop: '40%',
            },
          })}
        >
          <Box
            sx={theme => ({
              width: '50%',
              [theme.breakpoints.down('sm')]: {
                width: '85%',
              },
            })}
          >
            <TravelSvg />
          </Box>
          <Box mt={5} sx={theme => ({})}>
            <Stack
              direction="row"
              display="flex"
              spacing={1}
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <CreateTripModal type="itinerary" />

              <CreateTripModal type="guide" />
            </Stack>
          </Box>
        </Stack>
      )}
    </Box>
  );
}

interface PropsRenderTripsList {
  readonly trips: Maybe<Trip>[];
  readonly publicTrip: boolean;
}

export function RenderTrips(props: PropsRenderTripsList) {
  const navigate = useNavigate();

  return (
    <Box mb={2}>
      {props.trips && props.trips.length > 0 && (
        <>
          <Grid container>
            {props.trips.map((trip, index) => (
              <React.Fragment key={index}>
                {trip && (
                  <Stack
                    key={index + trip.id}
                    direction="column"
                    sx={theme => ({
                      // position: 'relative',
                      cursor: 'pointer',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                      borderRadius: '20px',
                      marginTop: 2,
                      marginRight: 1,
                      width: '31%',

                      [theme.breakpoints.down('xl')]: {
                        width: '31%',
                      },
                      [theme.breakpoints.down('lg')]: {
                        width: '48%',
                      },
                      [theme.breakpoints.down('md')]: {
                        width: '47%',
                      },
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        marginRight: 0,
                        marginLeft: 0,
                      },
                    })}
                  >
                    <Box
                      onClick={() => {
                        if (props.publicTrip) {
                          navigate('/trip/' + trip.id);
                          gtag('event', 'user-trip-edit-card');
                        } else {
                          navigate('/preview/' + trip.id);
                          gtag('event', 'user-trip-edit-card');
                        }
                      }}
                      key={index + trip.id}
                      sx={theme => ({
                        height: '270px',
                        position: 'relative',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        borderBottomLeftRadius: !props.publicTrip ? 0 : '20px',
                        borderBottomRightRadius: !props.publicTrip ? 0 : '20px',
                      })}
                    >
                      {trip.images && trip.images.length > 0 ? (
                        <CardMedia
                          component="img"
                          height="100%" // Increased height to accommodate the card content
                          sx={{
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderBottomLeftRadius: !props.publicTrip
                              ? 0
                              : '20px',
                            borderBottomRightRadius: !props.publicTrip
                              ? 0
                              : '20px',
                          }}
                          image={
                            trip.images?.find(image => image!.main)?.path ||
                            trip.images[0]?.path ||
                            ''
                          }
                        />
                      ) : (
                        <Box
                          sx={theme => ({
                            // backgroundColor: '#d3d3d3',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderBottomLeftRadius: !props.publicTrip
                              ? 0
                              : '20px',
                            borderBottomRightRadius: !props.publicTrip
                              ? 0
                              : '20px',
                            // padding: '25px',
                            // overflow: 'hidden',
                            backgroundColor: '#d3d3d3',
                            // borderRadius: '20px',
                            overflow: 'hidden',
                            // position: 'relative',
                            width: '100%',
                            height: '100%',
                            paddingTop: '15px',
                            paddingBottom: '70px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            [theme.breakpoints.down('sm')]: {
                              paddingTop: '5px',
                              paddingBottom: '70px',
                              paddingLeft: '5px',
                              paddingRight: '5px',
                            },
                          })}
                        >
                          <CardMedia
                            component={TravelSvgWithBackground}
                            sx={{
                              // width: '100%',
                              // height: '100%',
                              objectFit: 'cover',
                              // borderRadius: 'inherit',
                              // overflow: 'hidden',
                              // borderTopLeftRadius: '20px',
                              // borderTopRightRadius: '20px',
                              // borderBottomLeftRadius: !props.publicTrip
                              //   ? 0
                              //   : '20px',
                              // borderBottomRightRadius: !props.publicTrip
                              //   ? 0
                              //   : '20px',
                            }}
                          />
                        </Box>
                      )}
                      <AuthorizedApolloProvider>
                        <LikeTrip tripId={trip!.id} />
                      </AuthorizedApolloProvider>
                      <CardLabel
                        text={
                          trip && trip.tripType && trip.tripType === 'guide'
                            ? 'Guide'
                            : 'Itinerary'
                        }
                      />

                      {/* Card content moved to bottom with a blur background */}
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '100%',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          background: 'rgba(255, 255, 255, 0.7)',
                          backdropFilter: 'blur(3px)',
                          paddingTop: 1,
                          paddingX: 2,
                          paddingBottom: 1.5,
                          borderBottomLeftRadius: !props.publicTrip
                            ? 0
                            : '20px',
                          borderBottomRightRadius: !props.publicTrip
                            ? 0
                            : '20px',
                        }}
                      >
                        <TripTitle>{trip.name}</TripTitle>

                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <>
                            {trip &&
                            trip.tripType &&
                            trip.tripType === 'guide' ? (
                              <>
                                {trip &&
                                  trip.cities &&
                                  trip.cities.length > 0 && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                      }}
                                    >
                                      {trip.cities.map((city, index) => (
                                        <React.Fragment key={index}>
                                          <TripCountry>
                                            {city?.name}
                                          </TripCountry>
                                          {index !==
                                            trip.cities!.length - 1 && (
                                            <Box
                                              sx={{
                                                width: 5,
                                                height: 5,
                                                backgroundColor: 'black',
                                                borderRadius: '50%',
                                                mx: 1,
                                              }}
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                    </Stack>
                                  )}
                              </>
                            ) : (
                              <>
                                {trip &&
                                  trip.itineraryCountries &&
                                  trip.itineraryCountries.length > 0 &&
                                  trip.itineraryCountries.map(
                                    (country, index) => (
                                      <React.Fragment key={index}>
                                        {country && (
                                          <>
                                            {trip.length && index === 0 && (
                                              <TripLength>
                                                {trip.length}
                                                {trip.length === 1
                                                  ? ' day'
                                                  : ' days'}
                                              </TripLength>
                                            )}
                                            <Box
                                              sx={{
                                                width: 5,
                                                height: 5,
                                                backgroundColor: 'black',
                                                borderRadius: '50%',
                                                mx: 1,
                                              }}
                                            />
                                            <TripCountry>
                                              {country.countryName}
                                            </TripCountry>
                                          </>
                                        )}
                                      </React.Fragment>
                                    ),
                                  )}
                              </>
                            )}
                          </>
                        </Stack>
                      </Box>
                    </Box>
                    {!props.publicTrip && (
                      <Stack
                        width="100%"
                        direction="row"
                        sx={theme => ({
                          paddingTop: 1.5, // Reduced padding to remove extra space
                          paddingBottom: 1.5,
                          justifyContent: 'space-around', // Distribute buttons evenly
                        })}
                        display="flex"
                        alignItems="center"
                      >
                        <Button
                          onClick={() => {
                            navigate('/preview/' + trip.id);
                            gtag('event', 'user-trip-preview-icon');
                          }}
                          sx={{
                            padding: 0,
                            backgroundColor: 'transparent',
                            color: theme => theme.palette.icon.main,
                            display: 'flex',
                            alignItems: 'center',
                            '&:hover': {
                              backgroundColor: theme =>
                                theme.palette.action.hover,
                            },
                          }}
                        >
                          <VisibilityTwoToneIcon
                            sx={{
                              width: 20,
                              height: 20,
                              marginRight: 0.5,
                            }}
                          />
                          <TripButtons>View</TripButtons>
                        </Button>
                        {trip.tripAccess?.canEdit && (
                          <Button
                            onClick={() => {
                              navigate('/create-route/' + trip.id);
                              gtag('event', 'user-trip-edit-icon');
                            }}
                            sx={{
                              padding: 0,
                              backgroundColor: 'transparent',
                              color: theme => theme.palette.icon.main,
                              display: 'flex',
                              alignItems: 'center',
                              '&:hover': {
                                backgroundColor: theme =>
                                  theme.palette.action.hover,
                              },
                            }}
                          >
                            <EditTwoToneIcon
                              sx={{
                                width: 20,
                                height: 20,
                                marginRight: 0.5,
                              }}
                            />
                            <TripButtons>Edit</TripButtons>
                          </Button>
                        )}
                      </Stack>
                    )}
                  </Stack>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}

export const TripTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  // color: '#36454F',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}));

export const TripLength = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  color: '#36454F',
  // color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const TripCountry = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  marginRight: 5,
  color: '#36454F',
  // color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const TripButtons = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  marginRight: 5,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));
