import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FavoriteIcon: React.FC<SvgIconProps> = props => {
  return <Favorite {...props} />;
};

export const FavoriteBorderIcon: React.FC<SvgIconProps> = props => {
  return <FavoriteBorder {...props} />;
};

export const TikTokIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
    </SvgIcon>
  );
};

export const CustomBookingComWhiteIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#FFFFFF" />{' '}
      {/* White circular background */}
      <text
        x="50%"
        y="55%"
        textAnchor="middle"
        fontFamily="Arial, sans-serif"
        fontSize="16"
        fontWeight="bold"
        fill="#003580"
        dominantBaseline="middle"
      >
        B.
      </text>
    </SvgIcon>
  );
};

export const CustomBookingComBlueIcon = props => {
  return (
    <SvgIcon {...props} height="20" width="20" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#003580" />{' '}
      {/* Blue circular background */}
      <text
        x="50%"
        y="55%"
        textAnchor="middle"
        fontFamily="Arial, sans-serif"
        fontSize="16"
        fontWeight="bold"
        fill="#FFFFFF"
        dominantBaseline="middle"
      >
        B.
      </text>
    </SvgIcon>
  );
};

export const GetYourGuideIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="#FF5533" />{' '}
      {/* Blue circular background */}
      <path
        d="M7.49075 13.5C8.06194 13.4998 8.6241 13.3509 9.12647 13.0666C9.62885 12.7824 10.0556 12.3719 10.3683 11.872V13.317H12V8.51286H7.62685V10.1705H9.46196C9.37818 10.6129 9.15081 11.0115 8.81831 11.2988C8.48581 11.586 8.06861 11.7443 7.63732 11.7469C6.62928 11.7469 5.81417 10.893 5.81417 9.64978V8.37055C5.81417 7.15074 6.68611 6.24996 7.90802 6.24996C8.86072 6.24996 9.71023 6.79418 9.9929 7.58862L11.7158 6.96308C11.2387 5.4477 9.69826 4.5 7.91999 4.5C5.67658 4.5 4 6.07481 4 8.37368V9.65291C4.0015 11.872 5.56441 13.5 7.49075 13.5Z"
        fill="white"
        // Center and scale the path
        transform="translate(0, -1.3) scale(1.5)"
      />
    </SvgIcon>
  );
};

export const GetYourGuideIconLetter: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="get-your-guide-icon-title"
      role="img"
    >
      <path
        d="M7.49075 13.5C8.06194 13.4998 8.6241 13.3509 9.12647 13.0666C9.62885 12.7824 10.0556 12.3719 10.3683 11.872V13.317H12V8.51286H7.62685V10.1705H9.46196C9.37818 10.6129 9.15081 11.0115 8.81831 11.2988C8.48581 11.586 8.06861 11.7443 7.63732 11.7469C6.62928 11.7469 5.81417 10.893 5.81417 9.64978V8.37055C5.81417 7.15074 6.68611 6.24996 7.90802 6.24996C8.86072 6.24996 9.71023 6.79418 9.9929 7.58862L11.7158 6.96308C11.2387 5.4477 9.69826 4.5 7.91999 4.5C5.67658 4.5 4 6.07481 4 8.37368V9.65291C4.0015 11.872 5.56441 13.5 7.49075 13.5Z"
        fill="white"
        transform="translate(0, -1.3) scale(1.5)"
      />
    </SvgIcon>
  );
};
