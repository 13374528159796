import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_PUBLISH_TRIP = gql`
  mutation publishUserTrip($trip_id: String!, $publish: Boolean!) {
    publishUserTrip(trip_id: $trip_id, publish: $publish) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
