import { gql } from 'graphql-tag';
import { PUBLIC_TRIP_FIELDS } from './schema';

export const QUERY_GET_TRIP = gql`
  query trip($trip_id: String!) {
    trip(trip_id: $trip_id) {
      ...PublicTripFields
    }
  }
  ${PUBLIC_TRIP_FIELDS}
`;
