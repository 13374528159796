import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import './LoadingDots.css';

interface Props {
  readonly color?: 'primary' | 'secondary' | 'inherit';
  readonly size?: string | number;
}

export function Loader(props: Props) {
  return <CircularProgress color={props.color} size={props.size} />;
}

export function LoaderCentred(props: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <CircularProgress color={props.color} size={props.size} />
    </Box>
  );
}

export function LoaderFullScreen(props: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '90vh',
      }}
    >
      <CircularProgress color={props.color} size={props.size} />
    </Box>
  );
}

export const LoadingDots = () => {
  return (
    <Box className="loading" component="div">
      <span></span>
      <span></span>
      <span></span>
    </Box>
  );
};
