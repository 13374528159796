import { useMutation } from '@apollo/client';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import StarsTwoToneIcon from '@mui/icons-material/StarsTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import {
  Box,
  FormControlLabel,
  Switch,
  FormGroup,
  Typography,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { daysSelector, setDays } from '../../../store/DaySlice';
import { tripsSelector } from '../../../store/TripSlice';
import { Loader } from '../../../theme-components/Loader';
import Space from '../../../theme-components/Spacing';
import { TitleH3 } from '../../../theme-components/Typography';
import {
  isRunningStandalone,
  returnFormattedDate,
} from '../../../utils/helpers';
import { MUTATION_ADD_DAY_TO_ITINERARY } from '../../gql-user/addDayToItinerary';
import { AddAccomodationDirections } from '../../trip-details/AddAccomodationDirections';
import DayFullness from '../../trip-details/DayFullnessIndicator';
import { ActionsDay } from '../edit-trip/ActionsDay';

import { DraggableDroppableSteps } from './DraggableDroppableSteps';
import { ContainedHandle, ContainerDay, Item } from './Styles';
import { SideScrollRecommendations } from '../../trip-details/Recommendation';

export function DraggableDroppableDays(props: {
  showEmptyDay: boolean;
  selectedStepId?;
  stepRefs?;
  dayRefs?;
}) {
  const dispatch = useDispatch();
  const [collapsedSteps, setCollapsedSteps] = useState(false);
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true
  const location = useLocation(); // React Router hook (optional)
  const [hasSelectedTrip, setHasSelectedTrip] = useState(false);

  useEffect(() => {
    // Get the query parameters from the current URL
    const queryParams = new URLSearchParams(location.search); // or window.location.search
    const hasSelectedTripParam = queryParams.has('selected-trip'); // Check if 'selected-trip' exists

    // Set the boolean value based on the presence of 'selected-trip'
    setHasSelectedTrip(hasSelectedTripParam);
  }, [location]);

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  const { days, dayLoadingIndex, stepLoadingIndex } = useSelector(daysSelector);
  const { trip } = useSelector(tripsSelector);

  const [addDay, { loading: loadingNewDay, error: errorNewDay }] = useMutation(
    MUTATION_ADD_DAY_TO_ITINERARY,
  );

  return (
    <>
      <Box
        sx={theme => ({
          position: 'fixed',
          bottom: isStandalone
            ? 'calc(100px + env(safe-area-inset-bottom))'
            : '16px',
          left: hasSelectedTrip ? '25%' : '60%',
          transform: 'translateX(-50%)',
          borderRadius: '20px',
          backgroundColor: theme.palette.grey[900],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingRight: 3,
          paddingLeft: 1,
          paddingY: 1,
          zIndex: 10000,
          [theme.breakpoints.down('sm')]: {
            left: '50%',
            zIndex: 1000,
          },
        })}
      >
        <FormGroup>
          <FormControlLabel
            onClick={() => {
              gtag('event', 'collapse-details');
              setCollapsedSteps(!collapsedSteps);
            }}
            control={
              <Switch
                size="small"
                checked={collapsedSteps}
                onChange={() => setCollapsedSteps(!collapsedSteps)}
                sx={{
                  '& .MuiSwitch-switchBase': {
                    color: 'white', // Handle color when unchecked
                  },
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: 'primary.main', // Handle color when checked
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: 'white', // Track color when unchecked
                  },
                  '& .MuiSwitch-track.Mui-checked': {
                    backgroundColor: 'primary.main', // Track color when checked
                  },
                }}
              />
            }
            labelPlacement="start"
            label={
              <Typography
                onClick={() => {
                  gtag('event', 'collapse-details');
                  setCollapsedSteps(!collapsedSteps);
                }}
                sx={{
                  fontWeight: 700,
                  color: 'white',
                  whiteSpace: 'nowrap',
                  marginRight: 1,
                }}
              >
                {/* {collapsedSteps ? 'Show details' : 'Hide details'} */}
                {hasSelectedTrip ? 'Hide details' : 'Reorder itinerary'}
              </Typography>
            }
          />
        </FormGroup>
      </Box>
      <Box
        sx={{
          width: '100%',
          // paddingRight: 1,
          marginLeft: collapsedSteps ? -1 : 0,
        }}
      >
        {trip && (!trip.tripType || trip.tripType !== 'guide') && (
          <Box sx={{ paddingLeft: 1 }}>
            <TitleH3>Itinerary</TitleH3>
          </Box>
        )}
        <Droppable type="itineraryDay" droppableId="itinerary">
          {(provided, snapshot) => (
            <ContainerDay
              ref={provided.innerRef}
              $isDraggingOver={snapshot.isDraggingOver}
            >
              {days &&
                days.map((day, dayIndex) => (
                  <Box
                    key={dayIndex}
                    width="100%"
                    sx={theme => ({
                      [theme.breakpoints.down('sm')]: {
                        // marginLeft: '-5px',
                      },
                    })}
                  >
                    {day && (
                      <Draggable
                        key={day.id}
                        draggableId={day.id}
                        index={dayIndex}
                      >
                        {(provided, snapshot) => (
                          <>
                            {/* {day.steps && day.steps.length > 0 && ( */}
                            <>
                              <Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                $isDragging={snapshot.isDragging}
                                style={provided.draggableProps.style}
                              >
                                <Stack width="100%" direction="column">
                                  <Box width="100%">
                                    <Stack
                                      direction="row"
                                      alignItems="flex-start"
                                      sx={{
                                        marginTop: 1,
                                        width: '100%',
                                        // position: 'relative',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {collapsedSteps && (
                                        <>
                                          {trip &&
                                          (!trip.tripType ||
                                            trip.tripType !== 'guide') ? (
                                            <>
                                              {dayLoadingIndex === dayIndex &&
                                              stepLoadingIndex === -1 ? (
                                                <ContainedHandle
                                                  {...provided.dragHandleProps}
                                                >
                                                  <Box
                                                    sx={theme => ({
                                                      marginTop: 1.5,
                                                      width: 25,
                                                      height: 25,
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                    })}
                                                  >
                                                    <Loader size={15} />
                                                  </Box>
                                                </ContainedHandle>
                                              ) : (
                                                <ContainedHandle
                                                  {...provided.dragHandleProps}
                                                >
                                                  <DragIndicatorIcon
                                                    sx={theme => ({
                                                      marginTop: 1.5,
                                                      width: 25,
                                                      height: 25,
                                                    })}
                                                  />
                                                </ContainedHandle>
                                              )}
                                            </>
                                          ) : (
                                            <Box
                                              sx={theme => ({
                                                marginTop: 1.5,
                                                width: 25,
                                                height: 25,
                                              })}
                                            />
                                          )}
                                        </>
                                      )}
                                      <Stack
                                        direction="column"
                                        width="100%"
                                        sx={{
                                          position: 'relative',
                                          overflow: 'hidden',
                                          borderRadius: '20px',
                                          backgroundColor: '#FFFEFD',
                                          border:
                                            '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
                                          padding: 1,
                                          paddingLeft: 1.5,
                                          marginBottom: 1.5,
                                          boxShadow:
                                            '0px 4px 8px rgba(0, 0, 0, 0.07)',
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          width="100%"
                                          justifyContent="space-between"
                                        >
                                          <Stack
                                            direction="row"
                                            width="100%"
                                            ref={el => {
                                              if (
                                                props &&
                                                props.dayRefs &&
                                                props.dayRefs.current
                                              ) {
                                                props.dayRefs.current[day.id] =
                                                  el; // Safely assign the ref
                                              }
                                            }}
                                          >
                                            {trip &&
                                            (!trip.tripType ||
                                              trip.tripType !== 'guide') ? (
                                              <>
                                                <TodayTwoToneIcon
                                                  sx={theme => ({
                                                    // marginLeft: 0,
                                                    marginRight: 1,
                                                    width: 25,
                                                    height: 25,
                                                    alignSelf: 'center',
                                                    color: '#F39C6A',
                                                    [theme.breakpoints.down(
                                                      'sm',
                                                    )]: {
                                                      // marginLeft: 0,
                                                      // width: 20,
                                                      // height: 20,
                                                    },
                                                  })}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <StarsTwoToneIcon
                                                  sx={theme => ({
                                                    marginTop: 0.5,
                                                    marginRight: 1,
                                                    width: 25,
                                                    height: 25,
                                                    alignSelf: 'center',
                                                    color: '#F39C6A',
                                                    [theme.breakpoints.down(
                                                      'sm',
                                                    )]: {
                                                      // marginLeft: 0,
                                                      // width: 20,
                                                      // height: 20,
                                                    },
                                                  })}
                                                />
                                              </>
                                            )}
                                            <TitleH3 color="#001B30">
                                              {trip &&
                                              (!trip.tripType ||
                                                trip.tripType !== 'guide') ? (
                                                <>{'Day ' + day.dayNumber}</>
                                              ) : (
                                                <>Guide</>
                                              )}
                                            </TitleH3>
                                            {trip && trip.startDate && (
                                              <Box pl={0.5}>
                                                <Typography
                                                  sx={theme => ({
                                                    fontWeight: 700,
                                                    fontSize: 14,
                                                    marginTop:
                                                      theme.spacing(0.5),
                                                    color: '#2E3C44',
                                                    [theme.breakpoints.down(
                                                      'sm',
                                                    )]: {
                                                      fontSize: 14,
                                                    },
                                                  })}
                                                >
                                                  {returnFormattedDate(
                                                    trip.startDate,
                                                    dayIndex,
                                                    false,
                                                  )}
                                                </Typography>
                                              </Box>
                                            )}
                                          </Stack>

                                          {!collapsedSteps && (
                                            <Box>
                                              <ActionsDay
                                                stepNumber={1}
                                                dayId={day.id}
                                                marginLeft={true}
                                                dayNumber={day.dayNumber}
                                                iconsOnly={false}
                                                tripType={
                                                  trip && trip.tripType
                                                    ? trip.tripType
                                                    : 'itinerary'
                                                }
                                              />
                                            </Box>
                                          )}
                                        </Stack>
                                        {!collapsedSteps && (
                                          <>
                                            {day &&
                                              day.cities &&
                                              day.cities.length > 0 && (
                                                <Stack
                                                  direction="row"
                                                  width="100%"
                                                  sx={theme => ({
                                                    [theme.breakpoints.down(
                                                      'sm',
                                                    )]: {
                                                      // paddingLeft: '40px',
                                                    },
                                                  })}
                                                >
                                                  <MapsHomeWorkTwoToneIcon
                                                    sx={theme => ({
                                                      marginRight: 1,
                                                      width: 25,
                                                      height: 25,
                                                      color: '#F39C6A',
                                                      // [theme.breakpoints.down(
                                                      //   'sm',
                                                      // )]: {
                                                      //   width: 20,
                                                      //   height: 20,
                                                      // },
                                                    })}
                                                  />

                                                  <Typography
                                                    sx={theme => ({
                                                      fontWeight: 600,
                                                      fontSize: 16,
                                                      color: '#2E3C44',
                                                      marginRight: 1,
                                                      [theme.breakpoints.down(
                                                        'sm',
                                                      )]: {
                                                        fontSize: 16,
                                                      },
                                                    })}
                                                  >
                                                    {day.cities.length === 1
                                                      ? 'City'
                                                      : 'Cities'}{' '}
                                                    <Typography
                                                      component={'span'}
                                                      // sx={theme => ({
                                                      //   fontWeight: 500,
                                                      //   fontSize: 18,
                                                      //   color: '#003366',
                                                      //   [theme.breakpoints.down(
                                                      //     'sm',
                                                      //   )]: {
                                                      //     fontSize: 16,
                                                      //   },
                                                      // })}
                                                      sx={theme => ({
                                                        fontWeight: 600,
                                                        fontSize: 16,
                                                        color: '#6E7191',
                                                        marginRight: 1,
                                                        [theme.breakpoints.down(
                                                          'sm',
                                                        )]: {
                                                          fontSize: 16,
                                                        },
                                                      })}
                                                    >
                                                      {day.cities.map(
                                                        (city, i) => (
                                                          <React.Fragment
                                                            key={i}
                                                          >
                                                            {city && (
                                                              <>
                                                                {city.cityName}
                                                                {i !==
                                                                  day.cities!
                                                                    .length -
                                                                    1 && (
                                                                  <>{', '}</>
                                                                )}
                                                              </>
                                                            )}
                                                          </React.Fragment>
                                                        ),
                                                      )}
                                                    </Typography>
                                                  </Typography>
                                                </Stack>
                                              )}
                                            <DayFullness
                                              dayId={day!.id}
                                              numberOfStepsInDay={
                                                day?.steps?.length ?? 0
                                              }
                                              tripType={
                                                trip && trip.tripType
                                                  ? trip.tripType
                                                  : 'itinerary'
                                              }
                                            />
                                          </>
                                        )}
                                      </Stack>
                                    </Stack>
                                  </Box>
                                  <Box width="100%">
                                    <AddAccomodationDirections
                                      dayId={day.id}
                                      isFirstDirection={true}
                                      isLastDirection={false}
                                      tripUser={'user'}
                                      tripType={
                                        trip && trip.tripType
                                          ? trip.tripType
                                          : 'itinerary'
                                      }
                                    />
                                    <DraggableDroppableSteps
                                      tripId={trip!.id}
                                      day={day}
                                      dayIndex={dayIndex}
                                      collapsedSteps={collapsedSteps}
                                      showEmptyDay={props.showEmptyDay}
                                      tripType={
                                        trip && trip.tripType
                                          ? trip.tripType
                                          : 'itinerary'
                                      }
                                      selectedStepId={props.selectedStepId}
                                      stepRefs={props.stepRefs}
                                    />
                                    <AddAccomodationDirections
                                      dayId={day.id}
                                      isFirstDirection={false}
                                      isLastDirection={true}
                                      tripUser={'user'}
                                      tripType={
                                        trip && trip.tripType
                                          ? trip.tripType
                                          : 'itinerary'
                                      }
                                    />
                                    {day.steps && day.steps.length > 0 && (
                                      <Space size="md" />
                                    )}
                                  </Box>
                                </Stack>
                              </Item>
                            </>
                            {!collapsedSteps &&
                              day.recommendations &&
                              day.recommendations.length > 0 &&
                              (day.recommendations[0]?.place ||
                                day.recommendations[0]?.activity) && (
                                <Box sx={{ paddingLeft: 0.5 }}>
                                  <SideScrollRecommendations
                                    recommentations={day.recommendations}
                                    type="day"
                                    tripUser="user"
                                  />
                                  <Space size="md" />
                                </Box>
                              )}

                            {!collapsedSteps && (
                              <ActionsDay
                                marginLeft={true}
                                dayId={day.id}
                                dayNumber={day.dayNumber}
                                iconsOnly={true}
                                dayDate={
                                  trip?.startDate ? trip.startDate : undefined
                                }
                                tripType={
                                  trip && trip.tripType
                                    ? trip.tripType
                                    : 'itinerary'
                                }
                              />
                            )}
                          </>
                        )}
                      </Draggable>
                    )}
                  </Box>
                ))}
              {provided.placeholder}
              {trip &&
                trip.itinerary &&
                (!trip.tripType || trip.tripType !== 'guide') && (
                  <>
                    {/* <Divider sx={{ marginTop: 3, marginLeft: 2 }} /> */}
                    <Stack
                      direction="row"
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                      flexWrap="wrap"
                      mt={2}
                      mb={3}
                    >
                      <Box
                        onClick={async () => {
                          gtag('event', 'add-new-day');
                          const response = await addDay({
                            variables: {
                              itinerary_id: trip.itinerary!.id,
                            },
                          });
                          dispatch(setDays(response.data.createUserDay.days));
                        }}
                        sx={theme => ({
                          fontSize: 16,
                          fontWeight: 600,
                          paddingTop: 1,
                          paddingBottom: 1,
                          paddingLeft: 3,
                          paddingRight: 3,
                          color: '#FFFFFF',
                          backgroundColor: '#36454F',
                          borderRadius: 20,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          boxShadow: 'none', // Disable shadow
                          '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: '#4A5D6F',
                          },
                          [theme.breakpoints.down('sm')]: {
                            fontSize: 14,
                          },
                        })}
                      >
                        {loadingNewDay ? (
                          <Loader color="primary" size={20} />
                        ) : (
                          <>
                            {'Add another day (Day ' + (days.length + 1) + ')'}
                          </>
                        )}
                      </Box>
                      <br />
                      {errorNewDay && (
                        <div>Error adding day to this itinerary</div>
                      )}
                    </Stack>
                  </>
                )}
            </ContainerDay>
          )}
        </Droppable>
      </Box>
    </>
  );
}
