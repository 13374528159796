import { useMutation } from '@apollo/client';
import {
  TextField,
  Button,
  Box,
  Typography,
  InputAdornment,
  FormHelperText,
  Divider,
  Link,
} from '@mui/material';
import { signInWithCustomToken, getAuth } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';

import { PrimaryButton, SecondaryButton } from '../../theme-components/Buttons';
import { Loader } from '../../theme-components/Loader';
import ModalHeader from '../../theme-components/ModalComponents';
import Space from '../../theme-components/Spacing';
import TextDescription from '../../theme-components/Typography';
import {
  MUTATION_SEND_OTP,
  MUTATION_VERIFY_OTP,
} from '../gql-public/authMutation';
import { CenteredModal, ModalPaper } from '../styling/modal';

import { useAuth } from './firebase';
import { unauthorisedClient } from '../../utils/auth';

interface SignUpProps {
  showInModal: boolean;
  loginDefault: boolean;
  open?: boolean;
  onClose?: () => void;
}

const SignUp: React.FC<SignUpProps> = ({
  showInModal,
  loginDefault,
  open,
  onClose,
}) => {
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [showCodeField, setShowCodeField] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(loginDefault);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [codeErrorMessage, setCodeErrorMessage] = useState<string>('');
  const auth = getAuth();
  const emailRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);

  const { user } = useAuth();

  useEffect(() => {
    if (user && onClose !== undefined) {
      onClose();
    }
  }, [user, onClose]);

  useEffect(() => {
    if (showCodeField && codeRef.current) {
      codeRef.current.focus(); // Focus the TextField when needed
    }
  }, [showCodeField]);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus(); // Focus the TextField on component mount
    }
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if using a form tag
      if (showCodeField) {
        handleVerifyOTP(); // Trigger verify function when Enter is pressed
      } else {
        handleSendOTP(); // Trigger send OTP function when Enter is pressed
      }
    }
  };

  const [sendOTP, { loading: sending }] = useMutation(MUTATION_SEND_OTP, {
    client: unauthorisedClient,
    variables: { email: email },
  });

  const [verifyOTP, { loading: verifying }] = useMutation(MUTATION_VERIFY_OTP, {
    client: unauthorisedClient,
    variables: { email: email, code: code },
  });

  const handleSendOTP = async () => {
    try {
      const result = await sendOTP();

      if (
        result &&
        result.data &&
        result.data.sendOtp &&
        result.data.sendOtp.success === true
      ) {
        setEmailSent(true);
        setShowCodeField(true);
        setErrorMessage('');
      } else {
        // Show error message for failed OTP sending
        setErrorMessage('Failed to send verification email.');
      }
    } catch (e) {
      setErrorMessage('Failed to send verification email.');
    }
  };

  const handleVerifyOTP = async () => {
    if (code && email) {
      try {
        const res = await verifyOTP();
        if (
          res &&
          res.data &&
          res.data.validateOtp &&
          res.data.validateOtp.token &&
          res.data.validateOtp.token !== ''
        ) {
          const token = res.data.validateOtp.token;
          await signInWithCustomToken(auth, token);
        } else {
          // Show error message for wrong code
          setCodeErrorMessage('Wrong code. Please resend.');
        }
      } catch (e) {
        setCodeErrorMessage('Wrong code. Please resend.');
      }
    }
  };

  const handleReset = () => {
    setShowCodeField(false);
    setEmailSent(false);
    setCode('');
    setErrorMessage('');
    setCodeErrorMessage('');
  };

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        if (emailRef.current) {
          emailRef.current.focus();
        }
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [open]);

  useEffect(() => {
    if (showCodeField && codeRef.current) {
      codeRef.current.focus();
    }
  }, [showCodeField]);

  return (
    <>
      {!showInModal ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingX: 2,
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            {isLogin ? 'Login' : 'Sign Up'}
          </Typography>
          <Box
            sx={theme => ({
              maxWidth: 350,
            })}
          >
            <Typography sx={{ textAlign: 'center' }}>
              {isLogin
                ? `Please enter your email, and we'll send you a code. Then, use the code to log in.`
                : `Please enter your email, and we'll send you a code. Then, use the code to sign up.`}
            </Typography>
          </Box>
          <Space size="lg" />
          <TextField
            label="Email"
            type="email"
            value={email}
            inputRef={emailRef}
            onKeyDown={handleKeyDown}
            onChange={e => setEmail(e.target.value)}
            sx={{ marginBottom: 1, width: '300px' }}
            InputProps={{
              endAdornment: emailSent && (
                <InputAdornment position="end">
                  <Button sx={{ padding: 0 }} onClick={handleReset}>
                    Change
                  </Button>
                </InputAdornment>
              ),
            }}
            disabled={emailSent}
            error={!!errorMessage}
          />
          <FormHelperText error>{errorMessage}</FormHelperText>
          {/* Code Input Field */}
          {showCodeField && (
            <TextField
              label="Enter Code"
              type="text"
              value={code}
              inputRef={codeRef}
              onKeyDown={handleKeyDown}
              onChange={e => setCode(e.target.value)}
              sx={{ marginBottom: 1, width: '300px' }}
              InputProps={{
                endAdornment: !!codeErrorMessage && emailSent && (
                  <InputAdornment position="end">
                    <Button
                      sx={{ padding: 0 }}
                      onClick={() => {
                        handleSendOTP();
                        setCode('');
                        setCodeErrorMessage('');
                      }}
                    >
                      Resent
                    </Button>
                  </InputAdornment>
                ),
              }}
              error={!!codeErrorMessage}
            />
          )}
          <FormHelperText error>{codeErrorMessage}</FormHelperText>
          <PrimaryButton
            variant="contained"
            onClick={showCodeField ? handleVerifyOTP : handleSendOTP}
            sx={{ width: '300px' }}
            disabled={sending || verifying}
          >
            {(sending || verifying) && <Loader />}
            {isLogin ? 'Login' : 'Sign Up'}
          </PrimaryButton>
          <Space size="md" />
          {/* <Divider sx={{ width: '100%' }} /> */}
          <Divider
            sx={theme => ({
              minWidth: 350,
              [theme.breakpoints.down('md')]: {
                minWidth: '100%',
                width: '100%',
              },
            })}
          >
            <Typography
              color="text.secondary"
              sx={{ px: 0, whiteSpace: 'nowrap', fontSize: 13 }}
            >
              {isLogin ? "DON'T HAVE AN ACCOUNT?" : 'HAVE AN ACCOUNT?'}
            </Typography>
          </Divider>
          <Space size="md" />
          <SecondaryButton
            variant="contained"
            onClick={() => setIsLogin(!isLogin)}
            sx={{ width: '300px' }}
            disabled={sending || verifying}
          >
            {!isLogin ? 'Login' : 'Sign Up'}
          </SecondaryButton>
          <Space size="sm" />
          <Box
            sx={theme => ({
              width: 300,
            })}
          >
            <TextDescription size="sm" sx={{ textAlign: 'center' }}>
              By continuing, you agree to our{' '}
              <Link
                href="/terms"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: 'inherit', textDecorationColor: 'inherit' }}
              >
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link
                href="/privacy"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: 'inherit', textDecorationColor: 'inherit' }}
              >
                Privacy Policy
              </Link>
            </TextDescription>
          </Box>
        </Box>
      ) : (
        <CenteredModal
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          open={open !== undefined ? open : false}
          onClose={onClose}
          closeAfterTransition
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
              backdropFilter: 'blur(10px)', // Apply the blur effect
            },
          }}
        >
          <ModalPaper>
            {/* <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding={0}
            >
              <Box></Box>
              <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box> */}
            <ModalHeader
              title={isLogin ? 'Login' : 'Sign Up'}
              description={
                isLogin
                  ? `Please enter your email, and we'll send you a code. Then, use the code to log in.`
                  : `Please enter your email, and we'll send you a code. Then, use the code to sign up.`
              }
            />
            <Space size="md" />
            <TextField
              label="Email"
              type="email"
              value={email}
              inputRef={emailRef}
              onKeyDown={handleKeyDown}
              onChange={e => setEmail(e.target.value)}
              sx={{ marginBottom: 1, width: '300px' }}
              InputProps={{
                endAdornment: emailSent && (
                  <InputAdornment position="end">
                    <Button sx={{ padding: 0 }} onClick={handleReset}>
                      Change
                    </Button>
                  </InputAdornment>
                ),
              }}
              disabled={emailSent}
              error={!!errorMessage}
            />
            <FormHelperText error>{errorMessage}</FormHelperText>

            {/* Code Input Field */}
            {showCodeField && (
              <TextField
                label="Enter Code"
                type="text"
                value={code}
                inputRef={codeRef}
                onKeyDown={handleKeyDown}
                onChange={e => setCode(e.target.value)}
                sx={{ marginBottom: 1, width: '300px' }}
                InputProps={{
                  endAdornment: !!codeErrorMessage && emailSent && (
                    <InputAdornment position="end">
                      <Button
                        sx={{ padding: 0 }}
                        onClick={() => {
                          handleSendOTP();
                          setCode('');
                          setCodeErrorMessage('');
                        }}
                      >
                        Resent
                      </Button>
                    </InputAdornment>
                  ),
                }}
                error={!!codeErrorMessage}
              />
            )}
            <FormHelperText error>{codeErrorMessage}</FormHelperText>

            {/* Login Button */}
            <PrimaryButton
              variant="contained"
              onClick={showCodeField ? handleVerifyOTP : handleSendOTP}
              sx={{ width: '300px' }}
              disabled={sending || verifying}
            >
              {(sending || verifying) && <Loader />}
              {isLogin ? 'Login' : 'Sign Up'}
            </PrimaryButton>
            <Space size="md" />
            {/* <Divider sx={{ width: '100%' }} /> */}
            <Divider
              sx={theme => ({
                minWidth: 350,
                [theme.breakpoints.down('md')]: {
                  minWidth: '100%',
                  width: '100%',
                },
              })}
            >
              <Typography
                color="text.secondary"
                sx={theme => ({
                  px: 0,
                  whiteSpace: 'nowrap',
                  fontSize: 13,
                })}
              >
                {isLogin ? "DON'T HAVE AN ACCOUNT?" : 'HAVE AN ACCOUNT?'}
              </Typography>
            </Divider>
            <Space size="md" />
            <SecondaryButton
              variant="contained"
              onClick={() => setIsLogin(!isLogin)}
              sx={{ width: '300px' }}
              disabled={sending || verifying}
            >
              {!isLogin ? 'Login' : 'Sign Up'}
            </SecondaryButton>
            <Space size="sm" />
            <Box
              sx={theme => ({
                width: 300,
              })}
            >
              <TextDescription size="sm" sx={{ textAlign: 'center' }}>
                By continuing, you agree to our{' '}
                <Link
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: 'inherit', textDecorationColor: 'inherit' }}
                >
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link
                  href="/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: 'inherit', textDecorationColor: 'inherit' }}
                >
                  Privacy Policy
                </Link>
              </TextDescription>
            </Box>
          </ModalPaper>
        </CenteredModal>
      )}
    </>
  );
};

export default SignUp;
