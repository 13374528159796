import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_ADD_STEP_TO_DAY = gql`
  mutation createUserStep(
    $day_id: String!
    $type: String!
    $description: String
    $archived: Boolean
    $latitude: Float
    $longitude: Float
    $locationUrl: String
    $title: String
    $duration: Int
    $url: String
    $as_step_number: Int
  ) {
    createUserStep(
      day_id: $day_id
      type: $type
      description: $description
      archived: $archived
      latitude: $latitude
      longitude: $longitude
      locationUrl: $locationUrl
      title: $title
      duration: $duration
      url: $url
      as_step_number: $as_step_number
    ) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
