import { gql } from '@apollo/client';

export const PLACE_FIELDS = gql`
  fragment PlaceFields on Place {
    id
    lat
    lng
    city
    country
    countryCode
    region
    externalPlaceId
    title
    category
    noOfReviews
    rating
    description
    quickFact
    phoneNumber
    website
    address
    aiDescription
    tags {
      id
      name
    }
    checkInTime
    checkOutTime
    placeType
    dataSource
    dataSource
    bookingLink
    hotelStarRating
    openingHours {
      weekDay
      hours
    }
    images {
      title
      path
      contributorId
      imageUrl
    }
    minPrice
    maxPrice
    admissionPrice
    priceCategory
    priceCurrency
  }
`;
