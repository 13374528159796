import { Box } from '@mui/material';
import React, { Component } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  errorsSelector,
  resetError,
  setErrorModalOpen,
} from '../store/ErrorSlice';
import { PrimaryButton, SecondaryButton } from '../theme-components/Buttons';

import {
  CenteredModal,
  ModalDescription,
  ModalPaper,
  ModalTitle,
  SmallModalPaper,
} from './styling/modal';
import ModalHeader from '../theme-components/ModalComponents';
import Space from '../theme-components/Spacing';

export default function ErrorModal() {
  const { errorModalOpen, errorTitle } = useSelector(errorsSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    resetError();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={errorModalOpen}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
          backdropFilter: 'blur(10px)', // Apply the blur effect
        },
      }}
    >
      <ModalPaper>
        <ModalHeader
          title={errorTitle}
          description="Please try again later. If it is still happening get in touch with us on help@rooutie.com"
        />

        <PrimaryButton
          onClick={() => {
            dispatch(setErrorModalOpen(false));
          }}
        >
          Dismiss
        </PrimaryButton>
        <Space size="md" />
        <SecondaryButton
          onClick={() => {
            dispatch(setErrorModalOpen(false));
            window.location.reload();
          }}
        >
          Reload
        </SecondaryButton>
      </ModalPaper>
    </CenteredModal>
  );
}

// A simple error boundary class component
// export class ErrorBoundary extends Component {
//   state = {
//     hasError: false,
//   };

//   static getDerivedStateFromError(error: Error) {
//     // Update state to trigger fallback UI
//     return { hasError: true };
//   }

//   componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
//     // Log the error to an error reporting service if needed
//     console.error('Error caught by ErrorBoundary:', error, errorInfo);
//   }

//   handleReload = () => {
//     // Reload the current page
//     window.location.reload();
//   };

//   render() {
//     if (this.state.hasError) {
//       // Render the fallback UI when an error occurs
//       return (
//         <div style={styles.overlay}>
//           <div style={styles.modal}>
//             <h2>An unexpected error occurred</h2>
//             <p>
//               Sorry for the inconvenience. Please reload the page to continue.
//             </p>
//             <button onClick={this.handleReload} style={styles.button}>
//               Reload Page
//             </button>
//           </div>
//         </div>
//       );
//     }

//     // If no error, render the children as normal
//     return this.props.children;
//   }
// }

// // Simple styles for modal and overlay
// const styles = {
//   overlay: {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   modal: {
//     backgroundColor: '#fff',
//     padding: '20px',
//     borderRadius: '8px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     textAlign: 'center',
//   },
//   button: {
//     marginTop: '10px',
//     padding: '10px 20px',
//     backgroundColor: '#007BFF',
//     color: '#fff',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//   },
// };
