import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import {
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Button,
  Avatar,
  IconButton,
  Box,
  Tooltip,
  Divider,
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { User } from '../../generated/user_graphql';
import { setUser, userSelector } from '../../store/UserSlice';
import { PrimaryButton } from '../../theme-components/Buttons';
import { Loader } from '../../theme-components/Loader';
import {
  isAdmin,
  isPublisher,
  returnFormattedDate,
  useUserRoles,
} from '../../utils/helpers';
import {
  MUTATION_UPDATE_USER,
  MUTATION_UPSERT_INSURANCE,
  QUERY_GET_USER,
} from '../gql-user/user';
import { CenteredModal, ModalPaper, ModalTitle } from '../styling/modal';

import LogoutButton from './LogoutButton';
import { useAuth } from './firebase';
import { getAuth } from 'firebase/auth';
import imageCompression from 'browser-image-compression';
import ModalHeader from '../../theme-components/ModalComponents';
import Space from '../../theme-components/Spacing';

export function Profile() {
  const dispatch = useDispatch();

  const { data } = useQuery<{ getUser: User }>(QUERY_GET_USER);

  useEffect(() => {
    if (data && data.getUser) {
      dispatch(setUser(data.getUser));
    }
  }, [data, dispatch]);

  return (
    <Box>
      <UserProfileCard />
    </Box>
  );
}

export default Profile;

type ModalContentType = 'passportExpiry' | 'insuranceExpiry' | null;

const UserProfileCard = () => {
  const roles = useUserRoles();
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [nickName, setNickName] = useState('');
  const [tikTokUrl, setTikTokUrl] = useState<string | null>(null);
  const [instagramUrl, setInstagramUrl] = useState<string | null>(null);
  const [youTubeUrl, setYouTubeUrl] = useState<string | null>(null);
  const [blogUrl, setBlogUrl] = useState<string | null>(null);
  const [modalContent, setModalContent] = useState<ModalContentType>(null);
  const [pasportExpiry, setPasportExpiry] = useState<Date | null>(null);
  const [insuranceExpiry, setInsuranceExpiry] = useState<Date | null>(null);
  const [insuranceContactNo, setInsuranceContactNo] = useState<string | null>(
    null,
  );
  const [insurancePolicyNo, setInsurancePolicyNo] = useState<string | null>(
    null,
  );
  useState(false);
  const [isEditingPolicyNumber, setIsEditingPolicyNumber] = useState(false);
  const [isEditingInsuranceContact, setIsEditingInsuranceContact] =
    useState(false);
  const [isEditingTikTok, setIsEditingTikTok] = useState(false);
  const [isEditingInstagram, setIsEditingInstagram] = useState(false);
  const [isEditingYouTube, setIsEditingYouTube] = useState(false);
  const [isEditingBlog, setIsEditingBlog] = useState(false);
  const { myUser } = useSelector(userSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [updateUser, { loading: updateUserLoading, data: updateUserData }] =
    useMutation<{ updateUser: User }>(MUTATION_UPDATE_USER, {
      variables: {
        ...(nickName.trim() && { nickname: nickName.trim() }),
        ...(pasportExpiry && { pasport_expiry_date: pasportExpiry }),
        ...(instagramUrl && { instagram_url: instagramUrl }),
        ...(tikTokUrl && { tik_tok_url: tikTokUrl }),
        ...(youTubeUrl && { you_tube_url: youTubeUrl }),
        ...(blogUrl && { blog_url: blogUrl }),
      },
    });

  const [
    updateInsurance,
    { loading: updateInsuranceLoading, data: updateInsuranceData },
  ] = useMutation<{ updateInsurance: User }>(MUTATION_UPSERT_INSURANCE, {
    variables: {
      ...(insuranceExpiry && { insurance_expiry_date: insuranceExpiry }),
      ...(insuranceContactNo &&
        insuranceContactNo.trim() && {
          contact_number: insuranceContactNo.trim(),
        }),
      ...(insurancePolicyNo &&
        insurancePolicyNo.trim() && {
          policy_number: insurancePolicyNo.trim(),
        }),
    },
  });

  useEffect(() => {
    if (updateInsuranceData && updateInsuranceData.updateInsurance) {
      dispatch(setUser(updateInsuranceData.updateInsurance));
      setIsEditingPolicyNumber(false);
      setIsEditingInsuranceContact(false);
      setIsEditingTikTok(false);
      setIsEditingInstagram(false);
      setIsEditingYouTube(false);
      setIsEditingBlog(false);
    }
  }, [updateInsuranceData, dispatch]);

  useEffect(() => {
    if (updateUserData && updateUserData.updateUser) {
      dispatch(setUser(updateUserData.updateUser));
      setIsEditingPolicyNumber(false);
      setIsEditingInsuranceContact(false);
      setIsEditingTikTok(false);
      setIsEditingInstagram(false);
      setIsEditingYouTube(false);
      setIsEditingBlog(false);
    }
  }, [updateUserData, dispatch]);

  return (
    <>
      <Box>
        <Box>
          <Box
            sx={{
              borderRadius: '20px',
              boxShadow: 3,
              padding: 2,
              backgroundColor: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2} // Add some spacing between Avatar and the text content
              sx={{
                maxWidth: '100%',
                // width: '100%',
                // overflow: 'hidden',
                // width: 'fit-content',
                p: 2, // Padding around the container for breathing room
              }}
            >
              {/* Profile Picture */}
              <UploadProfileImage
                profilePictureUrl={
                  myUser?.profilePictureUrl ? myUser.profilePictureUrl : null
                }
              />
              {/* User Info */}
              <Stack
                sx={{
                  maxWidth: '100%',
                  display: 'block',
                  boxSizing: 'border-box',
                  width: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                {/* Email */}
                {myUser?.email && (
                  <>
                    <Typography
                      sx={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: 'text.primary',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        // whiteSpace: 'nowrap',
                        maxWidth: '100%',
                        display: 'inline-block',
                      }}
                    >
                      {myUser.email}
                    </Typography>
                  </>
                )}

                {/* Nickname Editing */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    // mt: 1, // Margin for spacing between email and nickname
                  }}
                >
                  {isEditingNickname ? (
                    <TextField
                      variant="standard"
                      defaultValue={myUser?.nickName || ''}
                      label="Nickname"
                      onChange={e => setNickName(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              color="primary"
                              disabled={updateUserLoading}
                              onClick={async () => {
                                await updateUser();
                                setIsEditingNickname(false);
                              }}
                            >
                              {updateUserLoading ? <Loader /> : <CheckIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        color: 'text.secondary', // Use secondary color for less important text
                        maxWidth: '180px', // Control the size of the input field
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        mr: 1,
                        color: 'text.primary',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {myUser?.nickName ? (
                        `@${myUser.nickName}`
                      ) : (
                        <Button
                          color="primary"
                          onClick={() => setIsEditingNickname(true)}
                          sx={{
                            padding: 0, // Reduce button padding to align with native styling
                            minWidth: 0, // Remove button min width
                            textTransform: 'none', // Remove uppercase transformation
                            fontWeight: 500, // Native look with slight bold
                          }}
                        >
                          Add Nickname
                        </Button>
                      )}
                    </Typography>
                  )}

                  {myUser?.nickName && !isEditingNickname && (
                    <Typography
                      onClick={() => setIsEditingNickname(true)}
                      sx={{
                        fontSize: '0.875rem', // Slightly smaller font size for the edit link
                        color: 'primary.main',
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      Edit
                    </Typography>
                  )}
                </Box>

                {/* Followers and Following */}
              </Stack>
            </Stack>

            <Box
              sx={{
                width: '85%',
                // paddingX: 6,
              }}
            >
              <Divider
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  paddingX: 10,
                }}
              />
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              spacing={2} // Add space between followers and following
              mt={1} // Margin top for spacing
            >
              <Stack direction="column" alignItems="center">
                <Typography sx={{ fontWeight: 600, color: 'text.primary' }}>
                  Followers
                </Typography>
                <Typography
                  sx={{ fontSize: 30, fontWeight: 600, color: 'text.primary' }}
                >
                  {myUser?.followers?.length || 0}
                </Typography>
              </Stack>
              <Stack direction="column" alignItems="center">
                <Typography sx={{ fontWeight: 600, color: 'text.primary' }}>
                  Following
                </Typography>
                <Typography
                  sx={{ fontSize: 30, fontWeight: 600, color: 'text.primary' }}
                >
                  {myUser?.following?.length || 0}
                </Typography>
              </Stack>
            </Stack>
          </Box>

          {/* <Divider sx={{ marginBottom: 2, marginTop: 1 }} /> */}
          {isPublisher(roles) && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              mb={1}
              mt={1}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                }}
              >
                Thanks for adding all the trips, you are a{' '}
              </Typography>
              <StarIcon
                sx={theme => ({
                  width: 20,
                  height: 20,
                  color: '#E6B800',
                  [theme.breakpoints.down('sm')]: {
                    width: 20,
                    height: 20,
                  },
                })}
              />
            </Stack>
          )}
          {/* Details Section */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              mb: { xs: 1, sm: 0 },
              mt: 1,
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: 'text.secondary',
                fontSize: '1.25rem', // Slightly larger font size for the header
              }}
            >
              Important info on hand
            </Typography>
            <ComingSoonBox
              heading="Coming soon:"
              body="Insurance and pasport expiry reminders"
            />
            {/* Passport Expiry Date */}
            <Box mb={2} mt={1}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: 'text.primary', mb: 1 }} // Margin bottom for spacing
              >
                Passport expiry
              </Typography>
              <Box
                onClick={() => {
                  handleOpen();
                  setModalContent('passportExpiry');
                }}
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid', // Border style
                  borderColor: 'grey.400', // Border color
                  borderRadius: 1, // Rounded corners
                  padding: '10px', // Padding inside the box
                  display: 'flex', // Flexbox for layout
                  alignItems: 'center', // Vertically center items
                  justifyContent: 'flex-start', // Align items to the left
                  cursor: 'text', // Change cursor to indicate it's editable
                  height: '56px', // Set height to match typical input height
                  '&:hover': {
                    borderColor: 'primary.main', // Change border color on hover
                  },
                  width: 500,
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              >
                <Typography
                  sx={{
                    color:
                      (myUser && myUser.pasportExpiry) || pasportExpiry
                        ? 'text.primary'
                        : 'grey.700',
                    fontWeight: 400,
                    width: '100%', // Make text fill the width of the box
                  }}
                >
                  {myUser && myUser.pasportExpiry
                    ? returnFormattedDate(myUser.pasportExpiry, 0)
                    : 'Enter passport expiry date'}
                </Typography>
              </Box>
            </Box>
            {/* Insurance Expiry Date */}
            <Box mb={2}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: 'text.primary', mb: 1 }} // Margin bottom for spacing
              >
                Insurance expiry
              </Typography>
              <Box
                onClick={() => {
                  handleOpen();
                  setModalContent('insuranceExpiry');
                }}
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid', // Border style
                  borderColor: 'grey.400', // Border color
                  borderRadius: 1, // Rounded corners
                  padding: '10px', // Padding inside the box
                  display: 'flex', // Flexbox for layout
                  alignItems: 'center', // Vertically center items
                  justifyContent: 'flex-start', // Align items to the left
                  cursor: 'text', // Change cursor to indicate it's editable
                  height: '56px', // Set height to match typical input height
                  '&:hover': {
                    borderColor: 'primary.main', // Change border color on hover
                  },
                  width: 500,
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              >
                <Typography
                  sx={{
                    color:
                      (myUser && myUser.insurance && myUser.insurance.expiry) ||
                      insuranceExpiry
                        ? 'text.primary'
                        : 'grey.700',
                    fontWeight: 400,
                    width: '100%', // Make text fill the width of the box
                  }}
                >
                  {myUser && myUser.insurance && myUser.insurance.expiry
                    ? returnFormattedDate(myUser.insurance.expiry, 0)
                    : 'Enter insurance expiry date'}
                </Typography>
              </Box>
            </Box>

            {/* Insurance Contact No */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: 'text.primary', mb: 1 }} // Margin bottom for spacing
              >
                Insurance contact No
              </Typography>

              <TextField
                variant="outlined" // Outlined variant for a border
                value={
                  insuranceContactNo || insuranceContactNo === ''
                    ? insuranceContactNo
                    : myUser?.insurance?.contactNumber
                }
                label={
                  myUser?.insurance?.contactNumber
                    ? ''
                    : 'Enter insurance contact number'
                }
                onChange={e => setInsuranceContactNo(e.target.value)}
                onFocus={() => setIsEditingInsuranceContact(true)} // Set editing mode when focused
                // onBlur={async () => {
                //   setTimeout(async () => {
                //     setIsEditingInsuranceContact(false);
                //   }, 1);
                // }}
                InputProps={{
                  endAdornment: (
                    <>
                      {isEditingInsuranceContact && (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            disabled={updateInsuranceLoading}
                            onClick={async () => {
                              await updateInsurance();
                              setIsEditingInsuranceContact(false);
                            }}
                          >
                            {updateInsuranceLoading ? (
                              <Loader />
                            ) : (
                              <CheckIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                sx={theme => ({
                  color: 'text.secondary',
                  backgroundColor: 'white',
                  width: 500,
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              />
            </Box>
            {/* Insurance Policy Number */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: 'text.primary', mb: 1 }} // Margin bottom for spacing
              >
                Insurance policy No
              </Typography>

              <TextField
                variant="outlined" // Outlined variant for a border
                value={
                  insurancePolicyNo || insurancePolicyNo === ''
                    ? insurancePolicyNo
                    : myUser?.insurance?.policyNumber
                }
                label={
                  myUser?.insurance?.policyNumber
                    ? ''
                    : 'Enter insurance policy number'
                }
                onChange={e => setInsurancePolicyNo(e.target.value)}
                onFocus={() => setIsEditingPolicyNumber(true)} // Set editing mode when focused
                // onBlur={async () => {
                //   setTimeout(async () => {
                //     setIsEditingPolicyNumber(false);
                //   }, 1);
                // }}
                InputProps={{
                  endAdornment: (
                    <>
                      {isEditingPolicyNumber && (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            disabled={updateInsuranceLoading}
                            onClick={async () => {
                              await updateInsurance();
                              setIsEditingPolicyNumber(false);
                            }}
                          >
                            {updateInsuranceLoading ? (
                              <Loader />
                            ) : (
                              <CheckIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                sx={theme => ({
                  color: 'text.secondary',
                  width: 500,
                  backgroundColor: 'white',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              />
            </Box>

            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: 'text.secondary',
                fontSize: '1.25rem', // Slightly larger font size for the header
              }}
            >
              Social info
            </Typography>
            {/* Insurance Contact No */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: 'text.primary', mb: 1 }} // Margin bottom for spacing
              >
                Instagram
              </Typography>

              <TextField
                variant="outlined" // Outlined variant for a border
                value={
                  instagramUrl || instagramUrl === ''
                    ? instagramUrl
                    : myUser?.instagramUrl
                }
                label={myUser?.instagramUrl ? '' : 'Enter your Instagram URL'}
                onChange={e => setInstagramUrl(e.target.value)}
                onFocus={() => setIsEditingInstagram(true)} // Set editing mode when focused
                // onBlur={async () => {
                //   setTimeout(async () => {
                //     setIsEditingInstagram(false);
                //   }, 1);
                // }}
                InputProps={{
                  endAdornment: (
                    <>
                      {isEditingInstagram && (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            disabled={updateUserLoading}
                            onClick={async () => {
                              await updateUser();
                              setIsEditingInstagram(false);
                            }}
                          >
                            {updateUserLoading ? <Loader /> : <CheckIcon />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                sx={theme => ({
                  color: 'text.secondary',
                  width: 500,
                  backgroundColor: 'white',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: 'text.primary', mb: 1 }} // Margin bottom for spacing
              >
                YouTube
              </Typography>

              <TextField
                variant="outlined" // Outlined variant for a border
                value={
                  youTubeUrl || youTubeUrl === ''
                    ? youTubeUrl
                    : myUser?.youTubeUrl
                }
                label={myUser?.youTubeUrl ? '' : 'Enter your YouTube URL'}
                onChange={e => setYouTubeUrl(e.target.value)}
                onFocus={() => setIsEditingYouTube(true)} // Set editing mode when focused
                // onBlur={async () => {
                //   setTimeout(async () => {
                //     setIsEditingYouTube(false);
                //   }, 1);
                // }}
                InputProps={{
                  endAdornment: (
                    <>
                      {isEditingYouTube && (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            disabled={updateUserLoading}
                            onClick={async () => {
                              await updateUser();
                              setIsEditingYouTube(false);
                            }}
                          >
                            {updateUserLoading ? <Loader /> : <CheckIcon />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                sx={theme => ({
                  color: 'text.secondary',
                  width: 500,
                  backgroundColor: 'white',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: 'text.primary', mb: 1 }} // Margin bottom for spacing
              >
                TikTok
              </Typography>

              <TextField
                variant="outlined" // Outlined variant for a border
                value={
                  tikTokUrl || tikTokUrl === '' ? tikTokUrl : myUser?.tikTokUrl
                }
                label={myUser?.tikTokUrl ? '' : 'Enter your TikTok URL'}
                onChange={e => setTikTokUrl(e.target.value)}
                onFocus={() => setIsEditingTikTok(true)} // Set editing mode when focused
                // onBlur={async () => {
                //   setTimeout(async () => {
                //     setIsEditingTikTok(false);
                //   }, 1);
                // }}
                InputProps={{
                  endAdornment: (
                    <>
                      {isEditingTikTok && (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            disabled={updateUserLoading}
                            onClick={async () => {
                              await updateUser();
                              setIsEditingTikTok(false);
                            }}
                          >
                            {updateUserLoading ? <Loader /> : <CheckIcon />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                sx={theme => ({
                  color: 'text.secondary',
                  width: 500,
                  backgroundColor: 'white',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: 'text.primary', mb: 1 }} // Margin bottom for spacing
              >
                Blog URL
              </Typography>

              <TextField
                variant="outlined" // Outlined variant for a border
                value={blogUrl || blogUrl === '' ? blogUrl : myUser?.blogUrl}
                label={myUser?.blogUrl ? '' : 'Enter your blog URL'}
                onChange={e => setBlogUrl(e.target.value)}
                onFocus={() => setIsEditingBlog(true)} // Set editing mode when focused
                // onBlur={async () => {
                //   setTimeout(async () => {
                //     setIsEditingBlog(false);
                //   }, 1);
                // }}
                InputProps={{
                  endAdornment: (
                    <>
                      {isEditingBlog && (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            disabled={updateUserLoading}
                            onClick={async () => {
                              await updateUser();
                              setIsEditingBlog(false);
                            }}
                          >
                            {updateUserLoading ? <Loader /> : <CheckIcon />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                sx={theme => ({
                  color: 'text.secondary',
                  width: 500,
                  backgroundColor: 'white',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              />
            </Box>
          </Box>
        </Box>
        <Box
          mb={2}
          sx={{ display: 'flex', justifyContent: 'flex-end' }} // Aligns items to the right
        >
          <LogoutButton />
        </Box>
        {isAdmin(roles) && (
          <>
            <Debug />
            <Space size="xl" />
          </>
        )}
      </Box>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          {modalContent === 'passportExpiry' && (
            <ModalHeader title="When does your passport expire?" />
          )}
          {modalContent === 'insuranceExpiry' && (
            <ModalHeader title="When does your insurance expire?" />
          )}

          <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {modalContent === 'passportExpiry' && (
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={pasportExpiry}
                  onChange={(newValue: Date | null) => {
                    setPasportExpiry(newValue);
                  }}
                />
              )}
              {modalContent === 'insuranceExpiry' && (
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={pasportExpiry}
                  onChange={(newValue: Date | null) => {
                    setInsuranceExpiry(newValue);
                  }}
                />
              )}
            </LocalizationProvider>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <PrimaryButton
                disabled={updateUserLoading}
                onClick={async () => {
                  setNickName('');
                  try {
                    if (modalContent === 'passportExpiry') {
                      await updateUser();
                    }
                    if (modalContent === 'insuranceExpiry') {
                      await updateInsurance();
                    }
                    handleClose();
                    setModalContent(null);
                  } catch (e) {
                    //TODO handle error
                  }
                }}
              >
                {updateUserLoading ? <Loader /> : 'Save expiry date'}
              </PrimaryButton>
            </Box>
          </Box>
        </ModalPaper>
      </CenteredModal>
    </>
  );
};

interface ComingSoonBoxProps {
  heading: string;
  body: string;
}

const ComingSoonBox: React.FC<ComingSoonBoxProps> = ({ heading, body }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(255, 165, 0, 0.1)', // Very light orange background
        borderRadius: '8px',
        padding: '16px',
        textAlign: 'left',
        width: 'fit-content',
        // maxWidth: '400px', // Adjust the width as needed
        // margin: 'auto', // Center horizontally
        // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Slight shadow for depth
      }}
    >
      <Stack direction="row">
        <Typography sx={{ fontSize: 14 }}>
          <span style={{ fontWeight: 600 }}>{heading}</span>{' '}
          <span style={{ fontWeight: 400 }}>{body}</span>
        </Typography>
      </Stack>
    </Box>
  );
};

function UploadProfileImage(props: {
  profilePictureUrl?: string | undefined | null;
}) {
  const { idToken } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState('');
  const auth = getAuth();
  const user = auth.currentUser;

  const handleChange = async (e: any) => {
    if (e.target.files.length) {
      const preview = URL.createObjectURL(e.target.files[0]);
      setPreview(preview);
      await handleUpload(e.target.files[0].type, preview);
    }
  };

  const formData = new FormData();

  const handleUpload = async (imageType: string, preview: string) => {
    setUploading(true);
    const blob = await fetch(preview).then(r => r.blob());

    const file = new File([blob], 'image', {
      type: imageType,
    });

    const imageFile = file;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
      useWebWorker: true,
      maxIteration: 8,
    };

    if (user != null) {
      try {
        const compressedFile = await imageCompression(imageFile, options);
        formData.append('image', compressedFile);
        formData.append('isProfilePicture', 'true');

        await fetch(process.env.REACT_APP_CONFIG_URL_LOCKED + '/upload', {
          credentials: 'same-origin',
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        setUploading(false);
      } catch (error) {
        // TODO
        console.log(error);
      }
    }
  };

  return (
    <div>
      <label htmlFor="upload-button">
        {preview ? (
          <Box
            sx={{
              width: 80,
              height: 80,
            }}
          >
            <Avatar
              alt="Profile Picture"
              src={preview}
              sx={{
                width: 80,
                height: 80,
              }}
            >
              <PersonIcon
                sx={{
                  width: 60,
                  height: 60,
                }}
              />
            </Avatar>
          </Box>
        ) : (
          <Box
            sx={{
              width: 80,
              height: 80,
            }}
          >
            {props.profilePictureUrl ? (
              <Avatar
                alt="Profile Picture"
                src={props.profilePictureUrl}
                sx={{
                  width: 80,
                  height: 80,
                }}
              >
                <PersonIcon
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                />
              </Avatar>
            ) : (
              <Box
                sx={{
                  width: 80,
                  height: 80,
                  position: 'relative',
                  display: 'inlinex-block',
                }}
              >
                <Avatar
                  alt="Profile Picture"
                  sx={{
                    width: 80,
                    height: 80,
                    filter: 'blur(4px)',
                  }}
                >
                  <PersonIcon
                    sx={{
                      width: 60,
                      height: 60,
                    }}
                  />
                </Avatar>
                <Typography
                  sx={theme => ({
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Centers the text
                    fontWeight: 600,
                    fontSize: 16,
                    color: 'black', // Change color to make sure it's visible
                    // textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', // Optional shadow for better contrast
                  })}
                >
                  Add
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      {uploading ? <Loader /> : null}
    </div>
  );
}

function Debug() {
  // Check if on iOS standalone mode
  const isIosStandalone = (window.navigator as any).standalone;
  const isAndroidStandalone = window.matchMedia(
    '(display-mode: standalone)',
  ).matches;

  const isAndroidStandalone111 = document.referrer.includes('android-app://');
  const url = 'https://www.booking.com';
  // Format the URL to force HTTPS if necessary
  const formattedUrl = url.replace(/^http?:\/\//, 'https://');

  const link = 'https://www.booking.com';
  const intentUrl = `intent://${link.replace(/^https?:\/\//, '')}#Intent;scheme=https;action=android.intent.action.VIEW;end`;

  // Open with Safari-specific fallback in iOS standalone mode
  return (
    <>
      {/* <Button
        onClick={() => {
          window.open(`x-safari-${formattedUrl}, "_blank",`);
        }}
      >
        {isIosStandalone ? <>isIosStandalone</> : <>not isIosStandalone</>}
      </Button>
      <Button
        onClick={() => {
          window.location.href = intentUrl;
        }}
      >
        {isAndroidStandalone ? (
          <>isAndroidStandalone</>
        ) : (
          <>not isAndroidStandalone</>
        )}
      </Button>
      <Button
        onClick={() => {
          const link = document.createElement('a');
          link.href = 'https://www.booking.com';
          link.target = '_blank';
          link.rel = 'noopener noreferrer';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }}
      >
        {isAndroidStandalone111 ? (
          <>isAndroidStandalone111</>
        ) : (
          <>not isAndroidStandalone111</>
        )}
      </Button>
      <Button
        onClick={() => {
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.rel = 'noopener noreferrer';
          link.click();
        }}
      >
        Android link 1
      </Button>
      <Button
        onClick={() => {
          const newWindow = window.open('', '_blank', 'noopener,noreferrer');
          setTimeout(() => {
            if (newWindow) {
              newWindow.location.href = url;
            }
          }, 1000);
        }}
      >
        Android link 2
      </Button>
      <Button
        onClick={() => {
          window.location.assign(url);
        }}
      >
        Android link 3
      </Button>
      <Button
        onClick={() => {
          const intentUrl = `intent://${url.replace(/^https?:\/\//, '')}#Intent;scheme=https;package=com.android.chrome;end;`;
          window.location.href = intentUrl;
        }}
      >
        Android link 4
      </Button>
      <Button
        onClick={() => {
          const intentUrl = `intent://${url.replace(/^https?:\/\//, '')}#Intent;scheme=https;package=com.android.chrome;end;`;
          window.open(intentUrl, '_blank', 'noopener,noreferrer');
        }}
      >
        Android link 5
      </Button>
      <Button
        onClick={() => {
          const intentUrl = `intent://${url.replace(/^https?:\/\//, '')}#Intent;scheme=https;end;`;
          window.location.href = intentUrl;
        }}
      >
        Android link 6
      </Button>
      <Button
        onClick={() => {
          const intentUrl = `intent://${link.replace(/^https?:\/\//, '')}#Intent;scheme=https;action=android.intent.action.VIEW;end`;
          window.location.href = intentUrl;
        }}
      >
        Android link 7
      </Button>

      <Button
        onClick={() => {
          const intentUrl = `intent://${link.replace(/^https?:\/\//, '')}#Intent;scheme=https;action=android.intent.action.VIEW;end`;
          window.open(intentUrl, '_blank', 'noopener,noreferrer');
        }}
      >
        Android link 8
      </Button>
      <Button
        onClick={() => {
          const intentUrl = `googlechrome://${link.replace(/^https?:\/\//, '')}#Intent;scheme=https;action=android.intent.action.VIEW;end`;
          window.open(intentUrl, '_blank', 'noopener,noreferrer');
        }}
      >
        Android link 9
      </Button>
      <Button
        onClick={() => {
          window.open(url, '_blank', 'noopener,noreferrer');
        }}
      >
        Just regular link
      </Button> */}
    </>
  );
}
