import { gql } from '@apollo/client';
import { PLACE_FIELDS } from './schemaPlace';
import { ACTIVITY_FIELDS } from './schemaActivity';
import { RECOMMENDATION_FIELDS } from './schemaRecommendation';

export const ITINERARY_FIELDS = gql`
  fragment ItineraryFields on Itinerary {
    id
    days {
      id
      dayNumber
      cities {
        cityName
      }
      recommendations {
        ...RecommendationFields
      }
      steps {
        id
        title
        type
        description
        stepNumber
        latitude
        longitude
        locationUrl
        duration
        url
        city
        country
        countryCode
        region
        tags {
          id
          name
        }
        images {
          id
          path
          header
          main
          secondary
          tertiary
        }
        socialLinks {
          id
          socialType
          urlLink
          hashtag
          searchTerm
        }
        placeCategory
        placeImages {
          path
          id
          title
          contributorId
          imageUrl
        }
        place {
          ...PlaceFields
        }
        activity {
          ...ActivityFields
        }
        accommodations {
          id
          name
          description
          url
          budget
          latitude
          longitude
          locationUrl
          images {
            id
            path
            header
            main
            secondary
            tertiary
          }
        }
        recommendations {
          ...RecommendationFields
        }
      }
    }
    relatedPlacesAndActivities {
      activities {
        ...ActivityFields
      }
      places {
        ...PlaceFields
      }
    }
    coordinates {
      id
      title
      latitude
      longitude
      step {
        id
        title
        type
        description
        stepNumber
        latitude
        longitude
        locationUrl
        duration
        url
        city
        country
        countryCode
        region
        images {
          id
          path
          header
          main
          secondary
          tertiary
        }
        socialLinks {
          id
          socialType
          urlLink
          hashtag
          searchTerm
        }
        placeCategory
        placeImages {
          path
          id
          title
          contributorId
          imageUrl
        }
        place {
          ...PlaceFields
        }
        accommodations {
          id
          name
          description
          url
          budget
          latitude
          longitude
          locationUrl
          images {
            id
            path
            header
            main
            secondary
            tertiary
          }
        }
        recommendations {
          ...RecommendationFields
        }
      }
    }
  }
  ${RECOMMENDATION_FIELDS}
  ${ACTIVITY_FIELDS}
  ${PLACE_FIELDS}
`;

export const USER_TRIP_FIELDS = gql`
  fragment UserTripFields on Trip {
    id
    name
    description
    length
    archived
    startDate
    published
    tripType
    publish_pending
    tags {
      id
      name
    }
    recommendations {
      ...RecommendationFields
    }
    countries {
      id
      name
      description
      url
      iso2Code
      iso3Code
    }
    itineraryCountries {
      countryName
      itineraryId
    }
    cities {
      id
      name
    }
    images {
      id
      path
      name
      header
      main
      secondary
      tertiary
    }
    itinerary {
      ...ItineraryFields
    }
  }
  ${RECOMMENDATION_FIELDS}
  ${ITINERARY_FIELDS}
`;
