import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_UPSERT_SOCIAL_LINK = gql`
  mutation upsertSocialLink(
    $source_trip_id: String!
    $social_type: String!
    $step_id: String
    $day_id: String
    $trip_id: String
    $url_link: String
    $hashtag: String
    $search_term: String
  ) {
    upsertSocialLink(
      source_trip_id: $source_trip_id
      social_type: $social_type
      step_id: $step_id
      day_id: $day_id
      trip_id: $trip_id
      url_link: $url_link
      hashtag: $hashtag
      search_term: $search_term
    ) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
