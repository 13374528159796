import { Box, Divider, useTheme } from '@mui/material';
import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';

export function RenderHtmlFromResponse(props: { content: string }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(props.content),
      }}
    />
  );
}

// export function RenderHtmlFromResponseNoMargin(props: {
//   content: string;
//   limitToLines?: number;
// }) {
//     // Sanitize the content and wrap it with a div if it doesn't already start with one.
//     const sanitizedContent = DOMPurify.sanitize(props.content, {
//       ADD_TAGS: ['style'],
//     });

//     return (
//       <div
//         style={{
//           marginBlockStart: '-0.5em',
//           marginBlockEnd: '-0.5em',
//         }}
//         dangerouslySetInnerHTML={{
//           __html: sanitizedContent,
//         }}
//       />
//     );
//   }

export function RenderHtmlFromResponseNoMargin(props: {
  content: string;
  limitToLines?: number;
}) {
  const theme = useTheme();
  const { content, limitToLines = 100 } = props; // Default to 100 lines
  const [isExpanded, setIsExpanded] = useState(false);
  const [truncatedContent, setTruncatedContent] = useState<string | null>(null);
  const [sanitizedContent, setSanitizedContent] = useState<string>(
    DOMPurify.sanitize(content),
  );

  const containerRef = useRef<HTMLDivElement>(null);

  // const sanitizedContent = DOMPurify.sanitize(content, {
  //   ADD_TAGS: ['style'],
  // });

  // const sanitizedContent = DOMPurify.sanitize(content);

  useEffect(() => {
    if (!isExpanded) {
      const sanitizedContent = DOMPurify.sanitize(content); // Sanitize the full content

      // Create a temporary container to manipulate the DOM
      const container = document.createElement('div');
      container.innerHTML = sanitizedContent;

      // Find the last <p> tag
      const paragraphs = container.querySelectorAll('p');
      if (paragraphs.length > 0) {
        const lastParagraph = paragraphs[paragraphs.length - 1];
        // Append the "Less" link inside the last <p>
        lastParagraph.innerHTML += ` <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:500;"></span>`;
      }

      // Set the updated content
      setSanitizedContent(container.innerHTML);
      return;
    }
  }, [isExpanded, content, theme.palette.primary.main]);

  useEffect(() => {
    if (isExpanded) {
      setTruncatedContent(null); // Show full content if no limit or expanded
      return;
    }

    if (containerRef.current) {
      const fullText = containerRef.current.innerHTML;
      let currentText = fullText;
      const words = fullText.split(' ');

      // Reset container to full content to measure it properly
      containerRef.current.innerHTML = fullText;

      // Fit content within the line limit
      while (
        containerRef.current.scrollHeight > containerRef.current.clientHeight &&
        words.length
      ) {
        words.pop();
        currentText = words.join(' ');
        containerRef.current.innerHTML = currentText + '... More'; // Add ellipsis and "More" link
      }

      // Append "More" to truncated content only if needed
      if (fullText !== currentText) {
        setTruncatedContent(
          currentText +
            `... <span style="color:${theme.palette.primary.main}; cursor:pointer; font-weight:500;">More</span>`,
        );
      }
    }
  }, [sanitizedContent, limitToLines, isExpanded]);

  const handleClickMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      ref={containerRef}
      sx={theme => ({
        marginTop: '-0.5em',
        marginBottom: '-0.5em',
        display: isExpanded ? 'block' : '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: isExpanded ? 'none' : limitToLines, // Limit to specified lines when not expanded
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: isExpanded ? 'normal' : 'initial', // Proper multi-line handling
        [theme.breakpoints.down('sm')]: {
          marginBottom: '0em',
        },
      })}
      dangerouslySetInnerHTML={{
        __html: truncatedContent || sanitizedContent,
      }}
      onClick={handleClickMore}
    />
  );
}
