// apolloClient.ts
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

// Create a single instance of ApolloClient globally
export const unauthorisedClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_CONFIG_URL, // Using the dynamic endpoint
  }),
  cache: new InMemoryCache(),
});
