import { useLazyQuery } from '@apollo/client';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import FilterTwoToneIcon from '@mui/icons-material/FilterTwoTone';
import { Box, Stack } from '@mui/material';
import { textAlign } from '@mui/system';
import imageCompression from 'browser-image-compression';
import { getAuth } from 'firebase/auth';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Maybe, Image, Trip } from '../../../generated/user_graphql';
import { setDays } from '../../../store/DaySlice';
import { setTrip } from '../../../store/TripSlice';
import {
  LinkButtonOld,
  PrimaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import ModalHeader from '../../../theme-components/ModalComponents';
import Space from '../../../theme-components/Spacing';
import TextDescription from '../../../theme-components/Typography';
import { useAuth } from '../../auth/firebase';
import { QUERY_USER_TRIP } from '../../gql-user/userTrip';
import { CenteredModal, ModalPaper } from '../../styling/modal';

import { RenderImages } from './EditAddStepImages';

export default function AddTripImagesModal(props: {
  tripId: string;
  trip: Trip;
}) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let images: Maybe<Image>[] = [];
  if (props.trip.images && props.trip.images) {
    images = props.trip.images;
  }

  return (
    <>
      <Box
        sx={theme => ({
          position: 'absolute',
          bottom: 5,
          right: 5,
          paddingLeft: 1,
          paddingRight: 1,
          paddingTop: 1,
          paddingBottom: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          borderRadius: 20,
          zIndex: 2,
          [theme.breakpoints.down('md')]: {
            bottom: 5,
            right: 5,
          },
        })}
      >
        <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <LinkButtonOld
            onClick={() => {
              gtag('event', 'edit-trip-images-button');
              handleOpen();
            }}
          >
            <FilterTwoToneIcon
              sx={theme => ({
                marginRight: 0.5,
                width: 20,
                [theme.breakpoints.down('md')]: {
                  marginRight: 0.5,
                },
              })}
            />
            Edit images
          </LinkButtonOld>
        </Box>
      </Box>

      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader title="Add trip header images" />

          {props.trip && (
            <ManageTripImages tripId={props.tripId} tripImages={images} />
          )}
          <Space size="md" />
          <PrimaryButton
            onClick={() => {
              gtag('event', 'add-trip-images-done');
              handleClose();
            }}
          >
            Done
          </PrimaryButton>
        </ModalPaper>
      </CenteredModal>
    </>
  );
}

function ManageTripImages(props: {
  tripImages: Maybe<Image>[];
  tripId: string;
}) {
  const dispatch = useDispatch();
  const { idToken } = useAuth();
  const [imagePreview, setImagePreview] = useState('');
  const [imageLoading, setImageLoading] = useState(false);

  // const [compressedFile, setCompressedFile] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  const addImage = async (e: any) => {
    if (e.target.files.length) {
      const formData = new FormData();
      gtag('event', 'upload-image');
      formData.append('tripId', props.tripId);
      setImageLoading(true);
      const preview = URL.createObjectURL(e.target.files[0]);
      setImagePreview(preview);
      await handleUpload(e.target.files[0].type, preview, formData);
      setImageLoading(false);
    }
  };

  const [getUserTrip] = useLazyQuery(QUERY_USER_TRIP, {
    fetchPolicy: 'no-cache',
    variables: { trip_id: props.tripId },
  });

  const handleUpload = async (
    imageType: string,
    preview: string,
    formData: FormData,
  ) => {
    const blob = await fetch(preview).then(r => r.blob());

    const file = new File([blob], 'image', {
      type: imageType,
    });

    const imageFile = file;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };

    if (user != null) {
      try {
        const compressedFile = await imageCompression(imageFile, options);

        formData.append('image', compressedFile);

        await fetch(process.env.REACT_APP_CONFIG_URL_LOCKED + '/upload', {
          credentials: 'same-origin',
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        const { data } = await getUserTrip();
        if (data && data.userTrip) {
          dispatch(setTrip(data.userTrip));
          dispatch(setDays(data.userTrip.itinerary.days));
        }
      } catch (error) {
        // TODO
        console.log(error);
      }
    }
  };

  //NEW
  let image: string | null = null;
  if (imagePreview) {
    image = imagePreview;
  }

  return (
    <>
      {props.tripImages && (
        <>
          <TextDescription size="md" sx={{ textAlign: 'left' }}>
            Only 3 images will show on the itinerary
          </TextDescription>
          <Space size="md" />
          <RenderImages
            tripId={props.tripId}
            images={props.tripImages}
            userImages={false}
            placeImages={false}
            tripImages={true}
          />
        </>
      )}
      <label htmlFor="upload-image">
        <Box>
          {!imageLoading ? (
            <Box sx={{ marginLeft: 0.5, marginTop: 0.5, cursor: 'pointer' }}>
              <Stack direction="row">
                <CloudUploadTwoToneIcon
                  sx={{ width: 25, height: 25, marginRight: 1 }}
                />
                <Box
                  sx={theme => ({
                    alignSelf: 'center',
                    fontWeight: 500,
                    fontSize: 16,
                    color: theme.palette.primary.main,
                  })}
                >
                  Upload image
                </Box>
              </Stack>
            </Box>
          ) : (
            <Loader />
          )}
        </Box>
      </label>
      <input
        type="file"
        id="upload-image"
        style={{ display: 'none' }}
        onChange={addImage}
      />
    </>
  );
}
