import { gql } from '@apollo/client';

export const ACTIVITY_FIELDS = gql`
  fragment ActivityFields on Activity {
    id
    name
    noOfReviews
    rating
    lat
    lng
    city
    country
    countryCode
    address
    externalId
    region
    addressSource
    category
    description
    highlights
    aiDescription
    activityType
    dataSource
    bookingLink
    durationInMinutes
    validForInMinutes
    images {
      title
      path
      contributorId
      imageUrl
    }
    minPrice
    maxPrice
    priceCategory
    priceCurrency
  }
`;
