import { useMutation } from '@apollo/client';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { User } from '../../generated/user_graphql';
import { setUser, userSelector } from '../../store/UserSlice';
import { PlaceLike } from '../../theme-components/Lables';
import { Loader } from '../../theme-components/Loader';
import { useAuth } from '../auth/firebase';
import SignUp from '../auth/SignUp';
import { MUTATION_LIKE_ACTIVITY, MUTATION_LIKE_PLACE } from '../gql-user/user';

export function LikeActivity(props: { activityId: string }) {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [likedActivities, setLikedActivities] = useState<string[]>([]);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const handleLike = async () => {
    await likePlace();
  };

  const [likePlace, { loading, data }] = useMutation<{ likeActivity: User }>(
    MUTATION_LIKE_ACTIVITY,
    {
      variables: {
        activity_id: props.activityId,
        is_liking: !likedActivities.includes(props.activityId),
      },
    },
  );

  const { myUser } = useSelector(userSelector);

  useEffect(() => {
    if (myUser?.likedActivities) {
      const uniqueLikedPlaces = new Set<string>();
      myUser.likedActivities.forEach(activity => {
        if (activity?.activityId) uniqueLikedPlaces.add(activity.activityId);
      });
      setLikedActivities(Array.from(uniqueLikedPlaces));
    }
  }, [myUser]);

  useEffect(() => {
    if (data && data.likeActivity) {
      dispatch(setUser(data.likeActivity));
    }
  }, [data]);

  return (
    <Box key={props.activityId}>
      <Box
        onClick={event => {
          event.stopPropagation();
          if (isAuthenticated) {
            handleLike();
          } else {
            handleOpenSignUp();
          }
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <PlaceLike liked={likedActivities.includes(props.activityId)} />
          </>
        )}
      </Box>
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </Box>
  );
}
