import { gql } from 'graphql-tag';
import { ACTIVITY_FIELDS } from './schemaActivity';
import { PLACE_FIELDS } from './schemaPlace';

export const QUERY_GET_BOUNDING_BOX_PLACES_ACTIVITIES = gql`
  query placesAndActivitiesForBoundingBox(
    $min_lat: Float!
    $min_lng: Float!
    $max_lat: Float!
    $max_lng: Float!
  ) {
    placesAndActivitiesForBoundingBox(
      min_lat: $min_lat
      min_lng: $min_lng
      max_lat: $max_lat
      max_lng: $max_lng
    ) {
      activities {
        ...ActivityFields
      }
      places {
        ...PlaceFields
      }
    }
  }
  ${ACTIVITY_FIELDS}
  ${PLACE_FIELDS}
`;
