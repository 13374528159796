import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_DELETE_RECOMMENDATION = gql`
  mutation deleteRecommendation(
    $recommendation_id: String!
    $step_id: String
    $day_id: String
    $trip_id: String
  ) {
    deleteRecommendation(
      recommendation_id: $recommendation_id
      step_id: $step_id
      day_id: $day_id
      trip_id: $trip_id
    ) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
