import { gql } from 'graphql-tag';
import { USER_TRIP_FIELDS } from './schema';

export const MUTATION_DELETE_USER_IMAGE = gql`
  mutation deleteUserImage($id: String!) {
    deleteUserImage(id: $id) {
      id
    }
  }
`;

export const MUTATION_DELETE_STEP_PLACE_IMAGE = gql`
  mutation deleteStepPlaceImage($step_place_image_id: String!) {
    deleteStepPlaceImage(step_place_image_id: $step_place_image_id) {
      id
    }
  }
`;

export const MUTATION_SET_TRIP_MAIN_IMAGE = gql`
  mutation setMainTripImage($image_id: String!, $trip_id: String!) {
    setMainTripImage(image_id: $image_id, trip_id: $trip_id) {
      ...UserTripFields
    }
  }
  ${USER_TRIP_FIELDS}
`;
