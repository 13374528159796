import { useMutation } from '@apollo/client';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { setTrip } from '../../../store/TripSlice';
import { PrimaryButton, TextButton } from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { returnFormattedDate } from '../../../utils/helpers';
import { MUTATION_UPDATE_USER_TRIP } from '../../gql-user/updateUserTrip';
import { VerticalFlex } from '../../helpers/flex';
import { CenteredModal, ModalPaper, ModalTitle } from '../../styling/modal';
import ModalHeader from '../../../theme-components/ModalComponents';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import Space from '../../../theme-components/Spacing';

export default function AddStartDateModal(props: {
  tripId: string;
  date: Date;
  add: boolean;
}) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [addStartDay, { loading }] = useMutation(MUTATION_UPDATE_USER_TRIP, {
    variables: {
      startDate: selectedDate,
      trip_id: props.tripId,
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack direction="row" justifyContent={props.add ? 'center' : 'normal'}>
      {props.add ? (
        <>
          {/* <TextButton
            onClick={() => {
           
            }}
          >
            When are you leaving?
          </TextButton> */}
          {/* <AddCircleTwoToneIcon
            sx={theme => ({
              width: 25,
              height: 25,
              // marginLeft: 1,
              marginTop: 0.75,
              marginRight: 1,
              // color: theme.palette.icon.main,
              color: 'primary.main',
              // alignSelf: 'center',
              [theme.breakpoints.down('sm')]: {
                // marginRight: 0.5,
                marginTop: 1,
                // width: 20,
                // height: 20,
              },
            })}
          /> */}
          <Button
            variant="text"
            color="primary"
            size="small"
            startIcon={<AddCircleTwoToneIcon sx={{ marginLeft: 0.5 }} />}
            onClick={e => {
              gtag('event', 'add-trip-start-date-button');
              handleOpen();
            }}
            sx={{
              borderRadius: '20px',
              border: '2px solid',
              right: 0,
              fontSize: 14,
              fontWeight: 600,
              paddingRight: 1.5,
              marginY: 1,
            }}
          >
            Add your departure date
          </Button>
        </>
      ) : (
        <Box>
          <Stack direction="row" width="100%">
            <EventTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                marginRight: 1,
                color: '#F39C6A',
                [theme.breakpoints.down('sm')]: {
                  // marginTop: 1,
                },
              })}
            />
            <Typography
              sx={theme => ({
                fontWeight: 600,
                fontSize: 16,
                color: '#36454F',
                marginRight: 0.5,
                [theme.breakpoints.down('sm')]: {
                  fontSize: 16,
                },
              })}
            >
              Leaving:
              <Typography
                component="span"
                sx={theme => ({
                  fontWeight: 600,
                  fontSize: 16,
                  color: '#6E7191',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                  },
                })}
              >
                {' '}
                {props.date && returnFormattedDate(props.date, 0)}
              </Typography>
            </Typography>

            <Typography
              sx={theme => ({
                alignSelf: 'center',
                fontWeight: 500,
                fontSize: 14,
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                  fontSize: 14,
                },
              })}
              onClick={() => {
                gtag('event', 'edit-trip-start-date-button');
                handleOpen();
              }}
            >
              edit
            </Typography>
          </Stack>
          <Space size="xs" />
        </Box>
      )}
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <ModalPaper>
          <ModalHeader title="When are you leaving?" />
          <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={selectedDate}
                onChange={(newValue: Date | null) => setSelectedDate(newValue)}
                sx={{
                  backgroundColor: '#fffaf5',
                  borderRadius: '10px',
                  '& .MuiPickersDay-root': {
                    fontWeight: 800,
                  },
                  '& .MuiTypography-root': {
                    fontWeight: 'bold',
                  },
                  '& .MuiPickersCalendarHeader-label': {
                    fontWeight: 'bold',
                  },
                }}
              />
            </LocalizationProvider>
            <VerticalFlex>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <PrimaryButton
                  disabled={loading}
                  onClick={async () => {
                    gtag('event', 'edit-start-date-save');
                    try {
                      const res = await addStartDay();
                      if (res && res.data && res.data.updateUserTrip) {
                        dispatch(setTrip(res.data.updateUserTrip));
                      }
                      if (
                        res &&
                        res.data &&
                        res.data.updateUserTrip &&
                        res.data.updateUserTrip.itinerary &&
                        res.data.updateUserTrip.itinerary.days
                      ) {
                        dispatch(
                          setDays(res.data.updateUserTrip.itinerary.days),
                        );
                      }
                      handleClose();
                    } catch (e) {
                      gtag('event', 'error-edit-start-date');
                      dispatch(setErrorTitle('Error saving start date'));
                      dispatch(setErrorModalOpen(true));
                    }
                  }}
                >
                  {loading ? <Loader /> : 'Save start date'}
                </PrimaryButton>
              </Box>
            </VerticalFlex>
          </Box>
        </ModalPaper>
      </CenteredModal>
    </Stack>
  );
}
