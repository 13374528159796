import { Instagram, YouTube } from '@mui/icons-material';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { Maybe, StepPlaceImage } from '../../generated/user_graphql';
import { ResponsiveChip } from '../../theme-components/Buttons';
import { TikTokIcon } from '../../theme-components/Icons';
import { CenteredModal, WrapModalPaper } from '../styling/modal';

import { ImageCard } from './StepImages';

interface InstagramEmbedProps {
  url: string; // Instagram post URL
}

const InstagramEmbedModal: React.FC<InstagramEmbedProps> = ({ url }) => {
  const embedContainer = useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [scriptLoaded, setScriptLoaded] = React.useState(false); // State to track script loading

  // Load Instagram embed script when the component mounts
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//www.instagram.com/embed.js';
    script.async = true;

    // Set script loaded state when script loads
    script.onload = () => {
      setScriptLoaded(true);
    };

    script.onerror = () => {
      console.error('Failed to load Instagram embed script');
    };

    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (embedContainer.current) {
      embedContainer.current.innerHTML = ''; // Clear the container on close
    }
  };

  const loadInstagramEmbed = () => {
    if (embedContainer.current) {
      // Clear previous content
      embedContainer.current.innerHTML = '';

      // Create Instagram blockquote element
      const blockquote = document.createElement('blockquote');
      blockquote.className = 'instagram-media';
      blockquote.setAttribute('data-instgrm-permalink', url);
      blockquote.setAttribute('data-instgrm-version', '14');
      blockquote.style.background = '#FFF';
      blockquote.style.border = '0px';
      blockquote.style.borderRadius = '10px';
      blockquote.style.boxShadow =
        '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)';
      // blockquote.style.margin = '1px';
      // blockquote.style.minWidth = '300px';
      // blockquote.style.maxWidth = '90%';
      // blockquote.style.width = 'calc(100% - 2px)';
      embedContainer.current.appendChild(blockquote);

      // Check if the Instagram embed is available
      if (scriptLoaded && window.instgrm && window.instgrm.Embeds) {
        window.instgrm.Embeds.process(); // Process the embed
      } else {
        console.error(
          'Instagram embed not available in window or script not loaded',
        );
      }
    } else {
      console.error('Embed container is not available');
    }
  };

  useEffect(() => {
    if (open) {
      // Delay loading until the modal has fully rendered
      setTimeout(loadInstagramEmbed, 1); // Small delay to ensure render
    }
  }, [open]);

  return (
    <>
      <Box onClick={handleOpen}>
        <ResponsiveChip
          mobileCopy="Instagram"
          desktopCopy="Instagram"
          icon={Instagram}
          small={true}
          borderOnly={true}
        />
      </Box>

      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <Box>
          {/* <LoaderFullScreen /> */}
          <Box
            ref={embedContainer}
            sx={{
              maxWidth: '90%',
              maxHeight: '70%',
            }}
            // style={{
            //   zIndex: 999, // Higher z-index to stay on top
            //   position: 'fixed', // Fixed position relative to the viewport
            //   top: '50%', // Center vertically
            //   left: '50%', // Center horizontally
            //   transform: 'translate(-50%, -50%)', // Corrects offset for perfect centering
            // }}
          />
        </Box>
        {/* </Fade> */}
      </CenteredModal>
    </>
  );
};

export default InstagramEmbedModal;

interface TikTokEmbedProps {
  url: string;
}

export const TikTokEmbedModal: React.FC<TikTokEmbedProps> = ({ url }) => {
  const [open, setOpen] = useState(false);
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);

  const handleOpen = () => {
    const videoIdMatch = url.match(/video\/(\d+)/);
    const videoId = videoIdMatch ? videoIdMatch[1] : null;

    if (videoId) {
      setEmbedUrl(`https://www.tiktok.com/embed/v2/${videoId}`);
      setOpen(true);
    } else {
      console.error('Invalid TikTok URL provided.');
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Box onClick={handleOpen}>
        <ResponsiveChip
          mobileCopy="TikTok"
          desktopCopy="TikTok"
          icon={TikTokIcon}
          small={true}
          borderOnly={true}
        />
      </Box>

      <CenteredModal
        aria-labelledby="tiktok-modal-title"
        aria-describedby="tiktok-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <Box>
          {embedUrl && (
            <iframe
              src={embedUrl}
              title="tikok"
              // width="325"
              // height="605"
              style={{
                maxWidth: '80%',
                minWidth: '325px',
                maxHeight: '70%',
                minHeight: '650px',
                border: 'none',
                borderRadius: '10px',
              }}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
              allowFullScreen
            />
          )}
        </Box>
      </CenteredModal>
    </>
  );
};

interface YouTubeEmbedProps {
  url: string;
}

export const YouTubeEmbedModal: React.FC<YouTubeEmbedProps> = ({ url }) => {
  const [open, setOpen] = useState(false);
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);

  const handleOpen = () => {
    // Extract the YouTube video ID from the URL
    const videoIdMatch = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/,
    );
    const videoId = videoIdMatch ? videoIdMatch[1] : null;

    if (videoId) {
      setEmbedUrl(`https://www.youtube.com/embed/${videoId}?rel=0`);
      setOpen(true);
    } else {
      console.error('Invalid YouTube URL provided.');
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Box onClick={handleOpen}>
        <ResponsiveChip
          mobileCopy="YouTube"
          desktopCopy="YouTube"
          icon={YouTube}
          small={true}
          borderOnly={true}
        />
      </Box>

      <CenteredModal
        aria-labelledby="youtube-modal-title"
        aria-describedby="youtube-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <Box
          sx={theme => ({
            border: 'none',
            borderRadius: '10px',
            width: '600px',
            height: '400px',

            [theme.breakpoints.down('sm')]: {
              width: '350px',
              height: '250px',
            },
          })}
        >
          {embedUrl && (
            <iframe
              src={embedUrl}
              // width="560"
              // height="315"
              style={{
                border: 'none',
                borderRadius: '10px',
                width: '100%',
                height: '100%',
              }}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </Box>
      </CenteredModal>
    </>
  );
};

interface PhotosProps {
  images: Maybe<StepPlaceImage>[];
}

export const PhotosModal: React.FC<PhotosProps> = ({ images }) => {
  const embedContainer = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    if (embedContainer.current) {
      embedContainer.current.innerHTML = ''; // Clear container on close
    }
  };

  return (
    <>
      <Box onClick={handleOpen}>
        <ResponsiveChip
          mobileCopy="Photos"
          desktopCopy="Photos"
          icon={PhotoSizeSelectActualIcon}
          small={true}
          borderOnly={true}
        />
      </Box>

      <CenteredModal
        aria-labelledby="tiktok-modal-title"
        aria-describedby="tiktok-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
            backdropFilter: 'blur(10px)', // Apply the blur effect
          },
        }}
      >
        <WrapModalPaper>
          <Grid container spacing={2}>
            {images.map((image, index) => (
              <Grid item xs={4} key={image!.path}>
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: '150px', // Set a fixed height for all images
                    overflow: 'hidden', // Hide anything outside the box
                    borderRadius: '4px', // Optional: to add rounded corners
                  }}
                >
                  <ImageCard
                    imageUrl={image && image.imageUrl ? image.imageUrl : ''}
                    path={image && image.path ? image.path : ''}
                    title={image && image.title ? image.title : ''}
                    index={index}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      position: 'absolute',
                      bottom: '8px', // Adjust to move away from the bottom
                      left: '8px', // Adjust to move away from the left
                      color: 'white', // Set text color to stand out on image
                      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background for better visibility
                      padding: '4px 8px', // Add padding for readability
                      borderRadius: '4px', // Optional: to round the text box
                    }}
                  >
                    {image!.title!}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </WrapModalPaper>
      </CenteredModal>
    </>
  );
};
