import InfoIcon from '@mui/icons-material/Info';
import TimelapseTwoToneIcon from '@mui/icons-material/TimelapseTwoTone';
import {
  Box,
  Typography,
  Stack,
  LinearProgress,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  RootState,
  selectTotalTransportTimeByDayId,
} from '../../store/TransportSlice';

interface TotalDayTransportTimeProps {
  dayId: string;
  tripType: string;
  numberOfStepsInDay: number;
}

const DayFullness: React.FC<TotalDayTransportTimeProps> = ({
  dayId,
  numberOfStepsInDay,
  tripType,
}) => {
  const totalTransportTime = useSelector((state: RootState) =>
    selectTotalTransportTimeByDayId(state, dayId),
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {}, [totalTransportTime, dayId]);
  const hours = totalTransportTime / 60 / 60;
  const displayHours = hours + numberOfStepsInDay * 0.75;
  const percentage = Math.min((displayHours / 12) * 100, 100); // Cap at 100%
  const barColor = getBarColor(displayHours);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (tripType === 'guide') {
    return null;
  }

  return (
    <Box sx={{ width: '100%' }}>
      {/* <Typography variant="h6">
        Total Transport Time for Day {dayId}: {displayHours.toFixed(2)} hours
      </Typography> */}
      {displayHours === 0 ? (
        <></>
      ) : (
        <Stack
          marginTop={0.5}
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'center', // Centers horizontally
            alignItems: 'center', // Centers vertically
          }}
        >
          <TimelapseTwoToneIcon
            sx={theme => ({
              marginRight: 1,
              width: 25,
              height: 25,
              alignSelf: 'center',
              color: '#F39C6A',
              [theme.breakpoints.down('sm')]: {
                // marginRight: 0.5,
                // width: 20,
                // height: 20,
              },
            })}
          />
          <Typography
            sx={theme => ({
              fontWeight: 600,
              fontSize: 16,
              color: '#2E3C44',
              marginRight: 1,
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
              },
            })}
          >
            Filled
          </Typography>
          <LinearProgress
            variant="determinate"
            value={percentage}
            sx={{
              borderRadius: '20px',
              height: 10,
              marginRight: 1,
              width: '100%',
              backgroundColor: '#E0E0E0', // Background color for the unfilled portion
              '& .MuiLinearProgress-bar': {
                backgroundColor: barColor, // Dynamically set based on hours
              },
            }}
          />
          <IconButton
            onClick={handleClickOpen}
            sx={theme => ({
              // marginRight: 1,
              width: 20,
              height: 20,
              alignSelf: 'center',
              color: '#F39C6A',
              [theme.breakpoints.down('sm')]: {
                width: 16,
                height: 16,
              },
            })}
          >
            <InfoIcon sx={theme => ({ color: getInfoColor(displayHours) })} />
          </IconButton>
        </Stack>
      )}
      <InfoModal open={open} onClose={handleClose} />
    </Box>
  );
};

export default DayFullness;

function getBarColor(hours) {
  if (hours <= 6) return '#A0D3E8'; // Soft Sky Blue
  if (hours <= 8) return '#B9E8B0'; // Light Mint Green
  if (hours <= 10) return '#F6E6A4'; // Soft Lemon Yellow
  if (hours <= 12) return '#F2B233'; // Soft Apricot Orange
  return '#FF6F61'; // Coral Red (above 12)
}

function getInfoColor(hours) {
  if (hours <= 6) return '#72B7D0'; // Brighter Sky Blue
  if (hours <= 8) return '#8FD8A5'; // Brighter Mint Green
  if (hours <= 10) return '#F1D68A'; // Brighter Lemon Yellow
  if (hours <= 12) return '#E5A92E'; // Brighter Apricot Orange
  return '#FF4C3B'; // Bright Coral Red (above 12)
}

const InfoModal: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 100000,
        '& .MuiPaper-root': {
          borderRadius: '20px', // Set border radius on the dialog box
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(8px)', // Apply blur to the backdrop
          backgroundColor: 'rgba(0, 0, 0, 0.3)', // Slight dark overlay
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: 20,
        }}
      >
        Indicator Explained
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            textAlign: 'center',
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Estimated day plan based on <br />
          scheduled activities and distances
        </Typography>
        <Box sx={{ mb: 2, mt: 2 }}>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            Blue (0-6 hours):
          </Typography>
          <LinearProgress
            variant="determinate"
            value={50} // Example bar
            sx={{
              borderRadius: '20px',
              height: 10,
              backgroundColor: '#E0E0E0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getBarColor(5),
              },
            }}
          />
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
            }}
          >
            Low activity level. Plenty of time available to add more.
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            Green (6-8 hours):
          </Typography>
          <LinearProgress
            variant="determinate"
            value={70} // Example bar
            sx={{
              borderRadius: '20px',
              height: 10,
              backgroundColor: '#E0E0E0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getBarColor(7),
              },
            }}
          />
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
            }}
          >
            Moderate activity level. You can still add a few more activities.
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            Yellow (8-10 hours):
          </Typography>
          <LinearProgress
            variant="determinate"
            value={85} // Example bar
            sx={{
              borderRadius: '20px',
              height: 10,
              backgroundColor: '#E0E0E0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getBarColor(9),
              },
            }}
          />
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
            }}
          >
            High activity level. Consider removing some activities to manage
            time.
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            Orange (10-12 hours):
          </Typography>
          <LinearProgress
            variant="determinate"
            value={95} // Example bar
            sx={{
              borderRadius: '20px',
              height: 10,
              backgroundColor: '#E0E0E0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getBarColor(11),
              },
            }}
          />
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
            }}
          >
            Very high activity level. You may need to remove some activities.
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            Red (Above 12 hours):
          </Typography>
          <LinearProgress
            variant="determinate"
            value={100} // Example bar
            sx={{
              borderRadius: '20px',
              height: 10,
              backgroundColor: '#E0E0E0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getBarColor(15),
              },
            }}
          />
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
            }}
          >
            Overloaded day. It's advisable to prioritize and remove some
            activities.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
