import { gql } from 'graphql-tag';
import { ITINERARY_FIELDS } from './schema';

export const MUTATION_ADD_DAY_TO_ITINERARY = gql`
  mutation createUserDay($itinerary_id: String!) {
    createUserDay(itinerary_id: $itinerary_id) {
      ...ItineraryFields
    }
  }
  ${ITINERARY_FIELDS}
`;
