import { gql } from 'graphql-tag';
import { ITINERARY_FIELDS } from './schema';

export const MUTATION_COPY_STEP_TO_DAY = gql`
  mutation copyStepToDay(
    $original_step_id: String!
    $new_day_id: String!
    $new_step_number: Int!
  ) {
    copyStepToDay(
      original_step_id: $original_step_id
      new_day_id: $new_day_id
      new_step_number: $new_step_number
    ) {
      ...ItineraryFields
    }
  }
  ${ITINERARY_FIELDS}
`;
