import { useMutation, useQuery } from '@apollo/client';
import HistoryToggleOffTwoToneIcon from '@mui/icons-material/HistoryToggleOffTwoTone';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import PersonIcon from '@mui/icons-material/PersonOutlineTwoTone';
import {
  Divider,
  Typography,
  Stack,
  IconButton,
  Avatar,
  Button,
} from '@mui/material';
import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';

import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LuggageTwoToneIcon from '@mui/icons-material/LuggageTwoTone';
import TourTwoToneIcon from '@mui/icons-material/TourTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { UserPublicInfo } from '../../generated/public_graphql';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { setUser, userSelector } from '../../store/UserSlice';
import { LoaderCentred } from '../../theme-components/Loader';
import {
  Body1,
  TitleH2,
  TitleH3,
  TitleH4,
} from '../../theme-components/Typography';
import { useAuth } from '../auth/firebase';
import SignUp from '../auth/SignUp';
import { QUERY_GET_SIDEBAR_COUNTRIES } from '../gql-public/sideBarQuery';
import { QUERY_GET_USER_SIDEBAR_COUNTRIES } from '../gql-user/sideBarQuery';
import { MUTATION_FOLLOW_USER } from '../gql-user/user';
import { RenderHtmlFromResponse } from '../RenderHtmlFromResponse';

import CopyTrip from './CopyTrip';
import { TripCountryBox } from './TripCountryBox';
import { TripUser } from './TripItinerary';
import UnauthorizedApolloProvider from '../../UnAuthorizedApolloProvider';
import { SideScrollRecommendations } from './Recommendation';
import { unauthorisedClient } from '../../utils/auth';
import Space from '../../theme-components/Spacing';
import { ActionsTrip } from '../create-trip/edit-trip/ActionsTrip';
import AddStartDateModal from '../create-trip/manage-trip-modals/AddStartDateModal';
import { TextButton } from '../../theme-components/Buttons';
import EditTripDescriptionModal from '../create-trip/manage-trip-modals/AddEditDescription';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';

type TripDetailsProps = {
  tripUser: TripUser;
};

export function TripDetails({ tripUser }: TripDetailsProps) {
  let isPreview = false;
  const location = useLocation(); // React Router hook (optional)
  const { publicTrip } = useSelector(publicTripsSelector);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [hasSelectedTrip, setHasSelectedTrip] = useState(false);

  const { trip: userTrip } = useSelector(tripsSelector);

  useEffect(() => {
    // Get the query parameters from the current URL
    const queryParams = new URLSearchParams(location.search); // or window.location.search
    const hasSelectedTripParam = queryParams.has('selected-trip'); // Check if 'selected-trip' exists

    // Set the boolean value based on the presence of 'selected-trip'
    setHasSelectedTrip(hasSelectedTripParam);
  }, [location]);

  const handleClose = () => {
    setIsDescriptionModalOpen(false);
  };

  const trip = tripUser === 'user' ? userTrip : publicTrip;

  if (window.location.href.indexOf('preview') > -1) {
    isPreview = true;
  }

  const {
    data: countriesData,
    error: countriesError,
    loading: countriesLoading,
  } = useQuery(
    isPreview ? QUERY_GET_USER_SIDEBAR_COUNTRIES : QUERY_GET_SIDEBAR_COUNTRIES,
    {
      client: unauthorisedClient,
      variables: { trip_id: trip && trip.id },
    },
  );

  if (!trip) {
    return <></>;
  }

  return (
    <Box
      sx={theme => ({
        width: '100%',
      })}
    >
      <Stack
        direction="column"
        width="100%"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '20px',
          border: '2px solid rgba(211, 211, 211, 0.4)', // Light gray with 50% opacity
          paddingY: 1.5,
          paddingX: 1,
          marginTop: 1.5,
          paddingLeft: 1.5,
          marginBottom: 1,
          backgroundColor: '#FFFEFD',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
        }}
      >
        <Stack
          // direction="row"
          // alignItems="left"
          // sx={theme => ({
          //   width: '100%',
          // })}
          direction="row"
          width="100%"
          justifyContent="space-between"
          sx={theme => ({
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
            // alignSelf: 'center',
            // marginRight: 1,
            [theme.breakpoints.down('sm')]: {
              // marginRight: 0.5,
            },
          })}
        >
          {/* <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              // marginRight: 1,
              [theme.breakpoints.down('sm')]: {
                // marginRight: 0.5,
              },
            })}
          > */}

          <Stack
            direction="row"
            width="100%"
            sx={theme => ({
              // display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center',
              // alignSelf: 'center',
              // marginRight: 1,
              [theme.breakpoints.down('sm')]: {
                // marginRight: 0.5,
              },
            })}
          >
            <LuggageTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                // marginLeft: 1,
                marginTop: 0.75,
                marginRight: 1,
                // color: theme.palette.icon.main,
                color: '#F39C6A',
                // alignSelf: 'center',
                [theme.breakpoints.down('sm')]: {
                  // marginRight: 0.5,
                  marginTop: 1,
                  // width: 20,
                  // height: 20,
                },
              })}
            />

            <TitleH3 color="#001B30">{trip.name}</TitleH3>
          </Stack>
          {trip &&
            tripUser !== 'user' &&
            (!trip.tripType || trip.tripType !== 'guide') && (
              <CopyTrip tripId={trip.id} />
            )}
          {trip && tripUser === 'user' && <ActionsTrip trip={trip} />}
          {/* </>
          </Box> */}
        </Stack>
        <Space size="xs" />
        {trip.userDetails && trip.userDetails.nickName && (
          <Stack
            direction="row"
            width="100%"
            sx={theme => ({
              // display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center',
              // alignSelf: 'center',
              // marginRight: 1,
              [theme.breakpoints.down('sm')]: {
                // marginRight: 0.5,
              },
            })}
          >
            {/* <PersonSearchTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                marginRight: 1,
                color: '#F39C6A',
                [theme.breakpoints.down('sm')]: {
                  marginRight: 0.5,
                  width: 20,
                  height: 20,
                },
              })}
            />
            <Typography
              sx={theme => ({
                fontWeight: 600,
                fontSize: 16,
                color: '#36454F',
                marginRight: 0.5,
                [theme.breakpoints.down('sm')]: {
                  fontSize: 16,
                },
              })}
            >
              {trip &&
                trip.itineraryCountries &&
                trip.itineraryCountries.length > 0 && <>Created by:</>}
            </Typography> */}
            <ContainedUserProfile tripUser={trip.userDetails} />
          </Stack>
        )}
        {trip.length && (!trip.tripType || trip.tripType === 'itinerary') && (
          <>
            <Stack
              direction="row"
              width="100%"
              sx={theme => ({
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
                // alignSelf: 'center',
                // marginRight: 1,
                [theme.breakpoints.down('sm')]: {
                  // marginRight: 0.5,
                },
              })}
            >
              <HistoryToggleOffTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 1,
                  color: '#F39C6A',
                  [theme.breakpoints.down('sm')]: {
                    // marginRight: 0.5,
                    // width: 20,
                    // height: 20,
                  },
                })}
              />
              <Typography
                sx={theme => ({
                  fontWeight: 600,
                  fontSize: 16,
                  color: '#2E3C44',
                  whiteSpace: 'nowrap', // Force text to one line
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 16,
                  },
                })}
              >
                {trip.length} day{trip.length > 1 ? 's' : ''} itinerary
              </Typography>
            </Stack>
            <Space size="xs" />
          </>
        )}

        {trip &&
          trip.itineraryCountries &&
          trip.itineraryCountries.length > 0 &&
          (!trip.tripType || trip.tripType !== 'guide') && (
            <>
              <Stack direction="row" width="100%">
                <TourTwoToneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 1,
                    color: '#F39C6A',
                  })}
                />
                <Typography
                  sx={theme => ({
                    fontWeight: 600,
                    fontSize: 16,
                    color: '#2E3C44',
                    marginRight: 0.5,
                    width: '100%',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: 16,
                    },
                  })}
                >
                  {trip.itineraryCountries.length > 1
                    ? 'Countries '
                    : 'Country '}
                  {trip.itineraryCountries.map((country, index) => (
                    <Typography
                      key={index}
                      component="span"
                      sx={theme => ({
                        fontWeight: 600,
                        fontSize: 16,
                        color: '#6E7191',
                        [theme.breakpoints.down('sm')]: {
                          fontSize: 16,
                        },
                      })}
                    >
                      {country && (
                        <>
                          {country.countryName}
                          {trip.itineraryCountries &&
                            index !== trip.itineraryCountries.length - 1 && (
                              <>{', '}</>
                            )}
                        </>
                      )}
                    </Typography>
                  ))}
                </Typography>
              </Stack>
              <Space size="xs" />
            </>
          )}

        {trip &&
          tripUser === 'user' &&
          (!trip.tripType || trip.tripType !== 'guide') && (
            <>
              {trip.startDate ? (
                <AddStartDateModal
                  tripId={trip.id}
                  date={trip.startDate}
                  add={false}
                />
              ) : (
                <AddStartDateModal
                  tripId={trip!.id}
                  date={new Date()}
                  add={true}
                />
              )}
            </>
          )}
      </Stack>

      {trip.description && trip.description !== '<p></p>' ? (
        <>
          <Box sx={{ paddingLeft: 1 }}>
            <TitleH3>Overview</TitleH3>
          </Box>
          <Box
            // direction="column"
            alignItems="left"
            sx={theme => ({
              width: '100%',
              marginTop: 1,
              marginBottom: 1,
              border: 2,
              borderColor: '#E4DBFF',
              paddingLeft: 1.5,
              paddingRight: 1.5,
              borderRadius: '20px',
              backgroundColor: '#f6f0ff',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
              },
            })}
          >
            <Body1>
              <RenderHtmlFromResponse content={trip.description} />
            </Body1>
          </Box>
        </>
      ) : (
        <>
          {trip && tripUser === 'user' && (
            <>
              <Box sx={{ paddingLeft: 1 }}>
                <TitleH3>Overview</TitleH3>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<AddCircleTwoToneIcon />}
                  onClick={e => {
                    gtag('event', 'add-trip-description');
                    setIsDescriptionModalOpen(true);
                  }}
                  sx={{
                    borderRadius: '20px',
                    border: '2px solid',
                    right: 0,
                    fontSize: 14,
                    fontWeight: 600,
                    marginY: 1,
                  }}
                >
                  {trip && trip.tripType && trip.tripType === 'guide' ? (
                    <>{'Add guide overview'}</>
                  ) : (
                    <>{'Add trip overview'}</>
                  )}
                </Button>
              </Box>
            </>
          )}
        </>
      )}
      {trip.recommendations &&
      trip.recommendations.length > 0 &&
      (trip.recommendations[0]?.place || trip.recommendations[0]?.activity) ? (
        <Box mb={2}>
          <SideScrollRecommendations
            recommentations={trip.recommendations}
            type="trip"
            tripUser="public"
          />
        </Box>
      ) : (
        <Box mb={2} />
      )}

      {/* FIXME move this to the right menu 
      {trip &&
        tripUser === 'user' &&
        (!trip.tripType || trip.tripType !== 'guide') && (
          <>
            {!countriesLoading && !countriesError && !isPreview && (
              <TripCountryBox
                trip={isPreview ? countriesData.userTrip : countriesData.trip}
              />
            )}
          </>
        )} */}
      {isDescriptionModalOpen && (
        <EditTripDescriptionModal
          description={trip.description}
          editTripId={trip.id}
          tripType={trip && trip.tripType ? trip.tripType : undefined}
          handleCloseModal={handleClose}
        />
      )}
    </Box>
  );
}

export function ContainedUserProfile(props: { tripUser: UserPublicInfo }) {
  const { isAuthenticated } = useAuth();
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const { myUser } = useSelector(userSelector);

  const [followUser, { data }] = useMutation(MUTATION_FOLLOW_USER, {
    variables: {
      following_user_id: props.tripUser.id,
      follow: !isFollowing,
    },
  });

  useEffect(() => {
    if (myUser) {
      const following =
        myUser.following &&
        myUser.following.some(follower => follower!.id === props.tripUser.id);
      if (following === true || following === false) {
        setIsFollowing(following);
      }
    }
    if (data && data.followUser) {
      dispatch(setUser(data.followUser)); // Dispatch the updated user info to the store
    }
  }, [myUser, data, dispatch]);

  return (
    <>
      {props.tripUser && props.tripUser.nickName && (
        <Stack direction="row" alignItems="center">
          <Avatar
            alt="Profile Picture"
            src={
              props.tripUser.profilePictureUrl
                ? props.tripUser.profilePictureUrl
                : ''
            }
            sx={theme => ({
              // width: props.tripUser.profilePictureUrl ? 25 : 25,
              // height: props.tripUser.profilePictureUrl ? 25 : 25,
              width: 25,
              height: 25,
              marginRight: 1,
              color: '#F39C6A',
              // [theme.breakpoints.down('sm')]: {
              //   marginRight: 1,
              //   width: 25,
              //   height: 25,
              // },
            })}
          >
            <PersonIcon />
          </Avatar>
          <Box
            sx={{
              display: 'inline-block', // Ensures the background applies only to the text
              borderRadius: '4px',
              // padding: 0.5, // Rounded corners
              '&:hover': {
                backgroundColor: 'rgba(255, 165, 0, 0.2)', // Light background color on hover
              },
            }}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              sx={{
                fontWeight: 600,
                textDecoration: 'none', // No underline for the entire text
                cursor: 'pointer', // Change cursor to pointer on hover
                // Split the "@" sign from the nickname for styling
              }}
              onClick={() => {
                gtag('event', 'app-bar-create-trip');
                navigate('/user-trips/' + props.tripUser.id);
              }}
            >
              <span style={{ textDecoration: 'none' }}>@</span>
              <span style={{ textDecoration: 'underline' }}>
                {props.tripUser.nickName}
              </span>
            </Typography>
          </Box>
          <IconButton
            size="small"
            color="error"
            onClick={async () => {
              if (isAuthenticated) {
                try {
                  gtag('event', 'follow-user');

                  await followUser();
                } catch (e) {
                  // TODO handle error
                }
              } else {
                gtag('event', 'follow-user-login');
                // loginWithRedirect({
                //   appState: { targetUrl: window.location.pathname },
                // });
                handleOpenSignUp();
              }
            }}
          >
            {isFollowing ? <Favorite /> : <FavoriteBorder />}
          </IconButton>
        </Stack>
      )}
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </>
  );
}

export function FollowUser(props: { publicUser: UserPublicInfo }) {
  const { isAuthenticated } = useAuth();
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useDispatch();
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const { myUser } = useSelector(userSelector);

  const [followUser, { data }] = useMutation(MUTATION_FOLLOW_USER, {
    variables: {
      following_user_id: props.publicUser.id,
      follow: !isFollowing,
    },
  });

  useEffect(() => {
    if (myUser) {
      const following =
        myUser.following &&
        myUser.following.some(follower => follower!.id === props.publicUser.id);
      if (following === true || following === false) {
        setIsFollowing(following);
      }
    }
    if (data && data.followUser) {
      dispatch(setUser(data.followUser)); // Dispatch the updated user info to the store
    }
  }, [myUser, data, dispatch]);

  return (
    <>
      {props.publicUser && props.publicUser.nickName && (
        <Stack direction="row" alignItems="center" mb={1}>
          <IconButton
            size="small"
            color="error"
            onClick={async () => {
              if (isAuthenticated) {
                try {
                  gtag('event', 'follow-user');

                  await followUser();
                } catch (e) {
                  // TODO handle error
                }
              } else {
                gtag('event', 'follow-user-login');
                // loginWithRedirect({
                //   appState: { targetUrl: window.location.pathname },
                // });
                handleOpenSignUp();
              }
            }}
          >
            {isFollowing ? <Favorite /> : <FavoriteBorder />}
          </IconButton>
        </Stack>
      )}
      <SignUp
        showInModal={true}
        loginDefault={true}
        open={isSignUpModalOpen}
        onClose={handleCloseSignUp}
      />
    </>
  );
}
