import { gql } from 'graphql-tag';
import { ITINERARY_FIELDS } from './schema';

export const MUTATION_MOVE_STEP = gql`
  mutation moveStep(
    $step_id: String!
    $new_day_id: String!
    $original_day_id: String!
    $new_step_number: Int!
  ) {
    moveStep(
      step_id: $step_id
      new_day_id: $new_day_id
      original_day_id: $original_day_id
      new_step_number: $new_step_number
    ) {
      ...ItineraryFields
    }
  }
  ${ITINERARY_FIELDS}
`;
