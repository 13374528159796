import { Box } from '@mui/material';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  setAddStepModalOpen,
  setStepDayId,
  stepsSelector,
  setStepId,
  setStepDescriptionAdded,
  setStepDescription,
} from '../../../../store/StepSlice';
import ModalHeader from '../../../../theme-components/ModalComponents';
import { CenteredModal, ModalPaper } from '../../../styling/modal';

import { AddStep } from './AddStep';
import { AddStepInfo } from './AddStepInfo';
import { AddPhotosAndAdditionalInfoToStep } from './AddStepPhotos';

export const AddStepToDay: React.FC<{
  dayId: string;
  tripType: string;
  stepNumber?: number;
  category:
    | 'CATEGORY_ACCOMMODATION'
    | 'CATEGORY_TRANSPORT'
    | 'CATEGORY_PLACE'
    | 'CATEGORY_ACTIVITY'
    | 'CATEGORY_FOOD_DRINK';
  handleCloseModal: () => void;
}> = ({ dayId, tripType, category, stepNumber, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [isManual, setIsManual] = useState(false);

  const { stepId, stepDescriptionAdded, stepDayId } =
    useSelector(stepsSelector);

  const closeModal = () => {
    gtag('event', 'close-add-new-step-button');
    setIsManual(false);
    dispatch(setAddStepModalOpen(false));
    dispatch(setStepDescriptionAdded(false));
    dispatch(setStepDescription(''));
    dispatch(setStepDayId(''));
    dispatch(setStepId(''));
    handleCloseModal();
  };

  const handleStepAdded = (id: string) => {
    console.log('Step added with ID:', id);
  };

  const handleManualSwitch = () => {
    gtag('event', 'add-manually');
    setIsManual(!isManual);
  };

  console.log('Add step to day modal');
  console.log('dayId', dayId);
  console.log('stepDayId', stepDayId);

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={dayId === stepDayId}
      onClose={closeModal}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      <ModalPaper>
        {stepId === '' && (
          <>
            {category === 'CATEGORY_ACCOMMODATION' && (
              <ModalHeader title={isManual ? '' : 'Add from Booking.com'} />
            )}
            {category !== 'CATEGORY_ACCOMMODATION' && (
              <ModalHeader title={isManual ? '' : 'Add from Google Maps'} />
            )}

            {category === 'CATEGORY_PLACE' && (
              <>
                <ModalHeader
                  title={isManual ? 'Add place' : ''}
                  description={
                    isManual ? (
                      'Enter the name of the place you are visiting'
                    ) : (
                      <>
                        Simply <strong>copy</strong> the shareable link from{' '}
                        <strong>Google Maps</strong> and paste it below to add
                        this spot to your itinerary!
                      </>
                    )
                  }
                />
              </>
            )}
            {category === 'CATEGORY_ACCOMMODATION' && (
              <>
                <ModalHeader
                  title={isManual ? 'Where are you staying?' : ''}
                  description={
                    isManual ? (
                      'Enter the name of your accommodation.'
                    ) : (
                      <>
                        Easily copy your hotel's link from{' '}
                        <strong>Booking.com</strong> or{' '}
                        <strong>Google Maps</strong> and paste below to add to
                        your itinerary
                      </>
                    )
                  }
                />
              </>
            )}
            {category === 'CATEGORY_FOOD_DRINK' && (
              <>
                <ModalHeader
                  title={isManual ? 'Add Food or Drink' : ''}
                  description={
                    isManual ? (
                      'Enter the name of the place you are visiting'
                    ) : (
                      <>
                        Simply <strong>copy</strong> the shareable link from{' '}
                        <strong>Google Maps</strong> and paste below to add to
                        your itinerary
                      </>
                    )
                  }
                />
              </>
            )}
            {category === 'CATEGORY_TRANSPORT' && (
              <>
                <ModalHeader
                  title={isManual ? 'Add Transport' : ''}
                  description={
                    isManual ? (
                      'Enter a title like "Drive 45 minutes"'
                    ) : (
                      <>
                        Simply <strong>copy</strong> the shareable link{' '}
                        <strong>e.g. bus stop, train station</strong> from{' '}
                        <strong>Google Maps </strong> and paste below to add to
                        your itinerary
                      </>
                    )
                  }
                />
              </>
            )}
            {category === 'CATEGORY_ACTIVITY' && (
              <>
                <ModalHeader
                  title={isManual ? 'Add Activity' : ''}
                  description={
                    isManual ? (
                      'Enter the activity name'
                    ) : (
                      <>
                        Simply <strong>copy</strong> the shareable link from{' '}
                        <strong>Google Maps</strong> and paste below to add to
                        your itinerary
                      </>
                    )
                  }
                />
              </>
            )}

            <Box width="100%" sx={{ position: 'relative' }}>
              <AddStep
                dayId={dayId}
                isManual={isManual}
                tripType={tripType}
                handleCloseModal={closeModal}
                stepType={category}
                handleManualSwitch={handleManualSwitch}
                handleStepAdded={handleStepAdded}
                stepNumber={stepNumber}
              />
            </Box>
          </>
        )}

        {stepId !== '' && (
          <Box>
            {category !== 'CATEGORY_ACCOMMODATION' &&
              category !== 'CATEGORY_TRANSPORT' && (
                <Box>
                  {!stepDescriptionAdded && (
                    <Box>
                      <ModalHeader
                        title="Add more details"
                        description="Share a quick preview of what travelers can expect to see or experience here"
                      />
                      <AddStepInfo handleCloseModal={closeModal} />
                    </Box>
                  )}
                  {stepDescriptionAdded && (
                    <Box>
                      <ModalHeader
                        title="Add photos"
                        description="Add photos to showcase what this is all about"
                      />
                      <AddPhotosAndAdditionalInfoToStep
                        handleCloseModal={closeModal}
                      />
                    </Box>
                  )}
                </Box>
              )}
            {category === 'CATEGORY_TRANSPORT' && (
              <Box>
                {!stepDescriptionAdded && (
                  <Box>
                    <ModalHeader
                      title="Add description"
                      description={
                        <>
                          Describe how to reach the destination, including any{' '}
                          <strong>
                            transport details like bus numbers, taxi companies,
                            or public transit directions
                          </strong>
                        </>
                      }
                    />
                    <AddStepInfo handleCloseModal={closeModal} />
                  </Box>
                )}
                {stepDescriptionAdded && (
                  <Box>
                    <ModalHeader
                      title="Add photos"
                      description="Add photos to help with navigation"
                    />
                    {/* <Space size="sm" /> */}
                    <AddPhotosAndAdditionalInfoToStep
                      handleCloseModal={closeModal}
                    />
                  </Box>
                )}
              </Box>
            )}
            {category === 'CATEGORY_ACCOMMODATION' && (
              <>
                {!stepDescriptionAdded && (
                  <Box>
                    <ModalHeader
                      title="More info about your stay"
                      description={
                        <>
                          Add details about your stay, like{' '}
                          <strong>
                            booking reference, check-in and check-out times
                          </strong>
                          , or any <strong>key info</strong> you'll need on the
                          go
                        </>
                      }
                    />
                    <AddStepInfo handleCloseModal={closeModal} />
                  </Box>
                )}

                {stepDescriptionAdded && (
                  <Box>
                    <ModalHeader
                      title="Add photos"
                      description="Add photo to this stay so it is easier to navigate"
                    />
                    <AddPhotosAndAdditionalInfoToStep
                      handleCloseModal={closeModal}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </ModalPaper>
    </CenteredModal>
  );
};
